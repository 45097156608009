import React, { useState } from "react";
import MovingArrows from "./MovingArrows";
import { useLanguage } from "../Translations/LenguageContext";

function TLL() {
    const { t } = useLanguage();
    const [selectedChoice, setSelectedChoice] = useState(null);

    const handleChoiceSelection = (choice) => {
        updateUserType(choice);
    };

    const getFrontPath = () => {
        if (selectedChoice === "tenant") {
            return "/welcome";
        } else if (selectedChoice === "owner") {
            return "/welcome";
        }else if (selectedChoice === "agency") {
            return "/welcome";
        }
        return "/";
    };

    const updateUserType = (userType) => {
        const userId = sessionStorage.getItem("user_id");
        if (!userId) {
            console.error("User ID not found in session storage");
            return;
        }

        const dataToUpdate = {
            user_id: userId,
            user_type: userType,
        };

        fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/user_type', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToUpdate),
        })
            .then(response => response.json())
            .then(data => {
                if (data.message === "OK") {
                    setSelectedChoice(userType);
                } else {
                    console.error("Error updating user type:", data.error);
                }
            })
            .catch(error => {
                console.error("Request failed:", error);
            });
    };

    return (
        <div className="main_tll">
            <p className="greetings">{t.tll.question}</p>
            <div className="main_tll_cont">
                <div
                    className={`choise_box demo_box ${selectedChoice === "tenant" ? "selected" : ""}`}
                    onClick={() => handleChoiceSelection("tenant")}
                >
                    <p className="choise_box_title">{t.tll.resp_t}</p>
                    <div className="img_container">
                        <div className="img_cont_boxes"></div>
                    </div>
                </div>
                <div
                    className={`choise_box ${selectedChoice === "owner" ? "selected" : ""}`}
                    onClick={() => handleChoiceSelection("owner")}
                >
                    <p className="choise_box_title">{t.tll.resp_t}</p>
                    <div className="img_container">
                    <div className="img_cont_house"></div>
                    </div>
                </div>
                <div
                    className={`choise_box ${selectedChoice === "agency" ? "selected" : ""}`}
                    onClick={() => handleChoiceSelection("agency")}
                >
                    <p className="choise_box_title">Agenzia immobiliare</p>
                    <div className="img_container">
                        <div className="img_cont_re_broker"></div>
                    </div>
                </div>
            </div>
            <MovingArrows front={getFrontPath()} back={"/register"} />
        </div>
    );
}

export default TLL;
