import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from "../../../components/Spinner";
import { useLanguage } from '../../../Translations/LenguageContext';
import { useNavigate } from 'react-router-dom';

const NewUserModal = ({ show, handleClose, }) => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);

    const handleNavigateNewUser = () => {
        navigate("new-user");
    }

    const handleNavigateNewUserrequest = () => {
        navigate("request-new-user");
    }

    return (
        <Modal show={show} onHide={handleClose} className="modal_new_user">
            <div className='header_modal_new_user'>
                <div className='modal_title_new_user'>
                    <p>{t.agency.create_users_modal.title}</p>
                </div>
            </div>
            <div className='body_modal_new_user'>
                <div className='choises_new_user_cont' onClick={handleNavigateNewUser}>
                    <i className='fa-solid fa-pen'></i>
                    <p className='choise_new_user_parag'>{t.agency.create_users_modal.manual}</p>
                    <p className='choise_new_user_exp'>({t.agency.create_users_modal.hint_manual})</p>
                </div>
                <div className='choises_new_user_cont blocked_choises_new_user_cont' onClick={handleNavigateNewUserrequest}>
                    <i className='fa-solid fa-list'></i>
                    <p className='choise_new_user_parag'>{t.agency.create_users_modal.massive}</p>
                    <p className='choise_new_user_exp'>({t.agency.create_users_modal.hint_massive})</p>

                </div>

            </div>
        </Modal>
    );
};

export default NewUserModal;
