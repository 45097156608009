import React from "react";
import NavbarMP from "./Navbar";
import MarketPlace from "./MarketPlace";
import Footer from "../Website/Footer"
import FooterMarketPlace from "./FooterMarketPlace";

const MainMarketPlace = () => {


    return(
        <div className="main_market_place">
            <NavbarMP/>
            <MarketPlace/>
            {/* <FooterMarketPlace/> */}
            <Footer/>
        </div>
    )
}

export default MainMarketPlace