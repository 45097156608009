import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { useNavigate } from "react-router-dom";
import { PiSparkleFill } from "react-icons/pi";
import { useLocation } from "react-router-dom";

import { BiCalendar } from "react-icons/bi";
import { TbLayoutDashboard } from "react-icons/tb";
import { BsHouses, BsPeople, BsFolder, BsShopWindow, BsDisplay } from "react-icons/bs";
import { TbUserScan } from "react-icons/tb";
import { LiaToolsSolid } from "react-icons/lia";
import { LuBarChart3 } from "react-icons/lu";
import { IoIosCloudOutline } from "react-icons/io";
import { HiOutlineChartPie } from "react-icons/hi2";
import { CgWebsite } from "react-icons/cg";
import { MdMarkEmailRead, MdBackup } from "react-icons/md";
import PaymentFormStripe from "../PaymentForm/PaymentFormStripe";
import { resolveMotionValue } from "framer-motion";
import Spinner from "../../../components/Spinner";
import { SiUnraid } from "react-icons/si";

const NewUser = ({ userData }) => {
    const { t } = useLanguage();
    const navigate = useNavigate();

    const [agencyName, setAgencyName] = useState("");
    const [description, setDescription] = useState("");
    const [userName, setUserName] = useState("");
    const [userSurname, setUserSurname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [prefix, setPrefix] = useState("+39");
    const [password, setPassword] = useState("");
    const [selectedType, setSelectedType] = useState(null);
    const [selectedPriceType, setSelectedPriceType] = useState(null);
    const [price, setPrice] = useState("")
    const [titlePlan, setTitlePlan] = useState("")
    const [admimCreator, setAdminCreator] = useState('')
    const [createdUser, setCreatedUser] = useState("")
    const [changePasswordReq, setChangePasswordReq] = useState(false);
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        userName: false,
        userSurname: false,
        email: false,
        password: false,
    });

    useEffect(() => {
        if (userData && userData._id) {
            setAdminCreator(userData._id);
        }
    }, [userData]);

    const [secondErrors, setSecondErrors] = useState({
        selectedPriceType: false,
    })
    const [generatePassword, setGeneratePassword] = useState(true);

    const [currentStep, setCurrentStep] = useState(0);

    const BackToPreviousPage = () => {
        navigate("/control_panel/users");
    };

    const handleCheckboxChange = () => {
        setGeneratePassword(!generatePassword);
    };

    const handleCheckboxChangePassword = (event) => {
        setChangePasswordReq(event.target.checked);
    };

    const validatePassword = (password) => {
        const minLength = 8;
        const maxLength = 256;

        if (password.length < minLength || password.length > maxLength) {
            return false;
        }

        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        const charTypes = [hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar].filter(Boolean).length;

        return charTypes >= 3;
    };

    const handleNext = async () => {
        if (currentStep === 0) {
            const newErrors = {
                admimCreator: !admimCreator.trim(),
                userName: !userName.trim(),
                userSurname: !userSurname.trim(),
                email: !email.trim(),
                phone: !phone.trim(),
                prefix: !prefix.trim(),
                password: !generatePassword && !validatePassword(password),
            };

            setErrors(newErrors);

            if (Object.values(newErrors).some((error) => error)) {
                setErr({ message: t.register_form.red_fields, display: true });
                return;
            }

            try {
                setLoading(true)
                const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/check_email", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email: email }),
                });

                const result = await response.json();
                if (response.ok) {
                    setLoading(false)
                    setErr({ message: "", display: false })
                    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
                } else {
                    setLoading(false)
                    if (result.message === "Email_already_present") {
                        setErr({ message: t.register_form.email_already_present, display: true });
                    } else if (result.message === "Invalid_email_address") {
                        setErr({ message: t.register_form.email_format, display: true });
                    } else {
                        console.error("Error saving user:", result.message);
                    }
                }
            } catch (error) {
                setLoading(false)
                console.error("Request failed:", error);
            }
        }

        if (currentStep === 1) {
            const newErrors = {
                selectedPriceType: !selectedPriceType,
            };

            setSecondErrors(newErrors);

            if (Object.values(newErrors).some((error) => error)) {
                setErr({ message: t.register_form.red_fields, display: true });
                return;
            }

            setErr({ message: "", display: false });

            const userData = {
                admimCreator,
                userName,
                userSurname,
                email,
                phone,
                prefix,
                password: generatePassword ? "" : password,
                passChange: changePasswordReq,
                type: selectedPriceType.split("_")[0],
                interval: selectedPriceType.split("_")[selectedPriceType.split("_").length - 1]
            };

            try {
                setLoading(true)
                const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/save_user_agency", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(userData),
                });

                const result = await response.json();
                if (response.ok) {
                    setLoading(false)
                    setErr({ message: "", display: false })
                    setCreatedUser(result.user_id)
                    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
                } else {
                    setLoading(false)
                    if (result.message === "Email_already_present") {
                        setErr({ message: t.register_form.email_already_present, display: true });
                    } else if (result.message === "Invalid_email_address") {
                        setErr({ message: t.register_form.email_format, display: true });
                    } else if (result.message === "All_fields_required") {
                        setErr({ message: t.register_form.red_fields, display: true });
                    } else {
                        console.error("Error saving user:", result.message);
                    }
                }
            } catch (error) {
                setLoading(false)
                console.error("Request failed:", error);
            }

        }
    };


    const handlePrev = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    };


    const handlePriceSelect = (priceType) => {
        setSelectedPriceType(priceType);
    };

    const formatAmount = (amount) => {
        const amountStr = amount.toString();
        const paddedAmount = amountStr.padStart(3, '0');
        return `${paddedAmount.slice(0, -2)},${paddedAmount.slice(-2)}`;
    };

    const steps = [
        (
            <div className="main_new_element">
                <p className="main_new_element_title">{t.agency.new_user}</p>
                <p className="main_new_element_desc">{t.agency.description}</p>
                <div className="main_new_element_input_desc_cont">
                    <div className="main_new_element_input_desc_cont_row">
                        <div className="new_element_input_desc_cont">
                            <label>{t.agency.user_name}</label>
                            <input
                                className="new_element_input_desc_cont_input_row"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                style={{ borderBottom: errors.userName ? "1px solid red" : "" }}
                            />
                        </div>
                        <div className="new_element_input_desc_cont">
                            <label>{t.agency.user_surname}</label>
                            <input
                                className="new_element_input_desc_cont_input_row"
                                value={userSurname}
                                onChange={(e) => setUserSurname(e.target.value)}
                                style={{ borderBottom: errors.userSurname ? "1px solid red" : "" }}
                            />
                        </div>
                    </div>
                    <div className="new_element_input_desc_cont">
                        <label>Email</label>
                        <input
                            className="new_element_input_desc_cont_input_row"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ borderBottom: errors.email ? "1px solid red" : "" }}
                        />
                    </div>
                    <div className="new_element_input_desc_cont">
                        <label>Telefono</label>
                        <input
                            className="new_element_input_desc_cont_input_row"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            style={{ borderBottom: errors.phone ? "1px solid red" : "" }}
                        />
                    </div>
                </div>
                <div className="main_new_element_input_desc_cont">
                    <div className="new_element_input_desc_cont_checkbox">
                        <input type="checkbox" checked={generatePassword} onChange={handleCheckboxChange} />
                        <label>{t.agency.gen_pass}</label>
                    </div>
                    {!generatePassword && (
                        <div className="new_element_input_desc_cont password_element">
                            <p className="password_element_desc">{t.agency.passw_desc}</p>
                            <label>Password</label>
                            <input
                                className="new_element_input_desc_cont_input_row"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{ borderBottom: errors.password ? "1px solid red" : "" }} // Password error style
                            />
                        </div>
                    )}
                    <div className="new_element_input_desc_cont_checkbox">
                        <input
                            type="checkbox"
                            onChange={handleCheckboxChangePassword}
                        />
                        <label>{t.agency.pass_change}</label>
                    </div>
                </div>
            </div>
        ),
        (
            <div className="main_new_element">
                <p className="main_new_element_title">{t.agency.licence}</p>
                <p className="main_new_element_desc">{t.agency.licence_desc}</p>
                <div className="main_new_element_input_desc_cont">
                    <div className="licence_element_container">
                        {[
                            { type: "basic", title: "Basic", month: 699, year: 7600, desc: t.agency.licence_types.basic },
                            { type: "business_standard", title: "Business standard", month: 999, year: 10900, desc: t.agency.licence_types.business_standard },
                            { type: "pro", title: "Pro", month: 1500, year: 16500, desc: t.agency.licence_types.pro },
                        ].map((license) => (
                            <div
                                key={license.type}
                                className={`licence_card ${selectedPriceType && selectedPriceType.startsWith(license.type) ? "selected_licence_card" : ""
                                    }`}
                            >
                                <p className="licence_title">
                                    {license.title} {license.type === "pro" && <PiSparkleFill />}
                                </p>
                                <p className="licence_title_desc">{license.desc}</p>
                                <div className="icons_price_cont">
                                    {license.type === "basic" && (
                                        <>
                                            <i><TbLayoutDashboard /></i>
                                            <i><BiCalendar /></i>
                                            <i><BsPeople /></i>
                                            <i><HiOutlineChartPie /></i>
                                            <i><LuBarChart3 /></i>
                                            <i><IoIosCloudOutline /></i>
                                        </>
                                    )}
                                    {license.type === "business_standard" && (
                                        <>
                                            <i><TbLayoutDashboard /></i>
                                            <i><BiCalendar /></i>
                                            <i><BsPeople /></i>
                                            <i><HiOutlineChartPie /></i>
                                            <i><BsShopWindow /></i>
                                            <i><BsHouses /></i>
                                            <i><TbUserScan /></i>
                                            <i><LiaToolsSolid /></i>
                                            <i><LuBarChart3 /></i>
                                            <i><IoIosCloudOutline /></i>
                                        </>
                                    )}
                                    {license.type === "pro" && (
                                        <>
                                            <i><TbLayoutDashboard /></i>
                                            <i><BiCalendar /></i>
                                            <i><BsPeople /></i>
                                            <i><HiOutlineChartPie /></i>
                                            <i><BsShopWindow /></i>
                                            <i><BsHouses /></i>
                                            <i><TbUserScan /></i>
                                            <i><LiaToolsSolid /></i>
                                            <i><LuBarChart3 /></i>
                                            <i><BsFolder /></i>
                                            <i><CgWebsite /></i>
                                            <i><MdMarkEmailRead /></i>
                                        </>
                                    )}
                                </div>
                                <div className="price_container">
                                    <p
                                        className={`licence_price ${selectedPriceType === `${license.type}_month` ? "selected_licence_price" : ""
                                            }`}
                                        onClick={() => {
                                            handlePriceSelect(`${license.type}_month`);
                                            setPrice(license.month);
                                            setTitlePlan(license.title)
                                        }}
                                        style={{ border: secondErrors.selectedPriceType ? "1px solid red" : "" }}
                                    >
                                        <span>{t.agency.month}</span>
                                        {formatAmount(license.month)}€
                                    </p>
                                    <p
                                        className={`licence_price ${selectedPriceType === `${license.type}_year` ? "selected_licence_price" : ""
                                            }`}
                                        onClick={() => {
                                            handlePriceSelect(`${license.type}_year`);
                                            setPrice(license.year);
                                            setTitlePlan(license.title)
                                        }}
                                        style={{ border: secondErrors.selectedPriceType ? "1px solid red" : "" }}
                                    >
                                        <span>{t.agency.year}</span>
                                        {formatAmount(license.year)}€ <span>{t.agency.free_month}</span>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        ),
        (
            <div className="main_new_element">
                <p className="main_new_element_title">{t.agency.payment_title}</p>
                <p className="main_new_element_desc">{t.agency.payment_subtitle}</p>
                <div className="main_new_element_input_desc_cont">
                    <PaymentFormStripe amount={price} sub_type={selectedPriceType} plan={titlePlan} user_id={createdUser} userName={userName} userSurname={userSurname} email={email} phone={phone} new_update={"new"} />
                </div>
            </div>
        )
    ];

    return (
        <div className="main_view_section">
            <p className="title_main_view">
                <i
                    onClick={BackToPreviousPage}
                    className="fa-solid fa-chevron-left back-btn_chevron"
                ></i>
                {t.agency.new_user}
            </p>
            <div className="new_agency_main_cont">
                <div className="new_agency_main_container">
                    <div className="steps_new_element">
                        {steps.map((_, index) => (
                            <React.Fragment key={index}>
                                <div className="step_cont_element">
                                    <i
                                        className={
                                            currentStep === index
                                                ? "fa-solid fa-circle active_step"
                                                : index < currentStep
                                                    ? "fa-regular fa-circle-check completed_step"
                                                    : "fa-regular fa-circle"
                                        }
                                    ></i>
                                    <p>{[t.agency.base_info, t.agency.licence, t.agency.finalize][index]}</p>
                                </div>

                                {index < steps.length - 1 && <div className="separator_steps_cont"></div>}
                            </React.Fragment>
                        ))}
                    </div>
                    {steps[currentStep]}
                </div>
                <div className="next_element_cont">
                    <div className="next_element_cont">
                        <button className={`next_element_cont_btn ${currentStep === 0 ? "disabled_btn_naviga" : ""}`} onClick={handlePrev} disabled={currentStep === 0}>
                            <i className="fa-solid fa-chevron-left"></i> {t.agency.prev}
                        </button>
                        <Spinner loading={loading} err={err}></Spinner>
                        <button
                            className={`next_element_cont_btn ${currentStep === steps.length - 1 ? "disabled_btn_naviga" : ""}`}
                            onClick={handleNext}
                            disabled={currentStep === steps.length - 1}
                        >
                            {t.agency.next} <i className="fa-solid fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewUser;
