// Navbar.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBoxOpen } from "react-icons/fa";
import { useLanguage } from "../Translations/LenguageContext";
import { BsStack } from "react-icons/bs";

function NavbarMP() {
    const navigate = useNavigate();
    const [scrolled, setScrolled] = useState(false);
    const { t } = useLanguage();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`main_navbar ${scrolled ? "scrolled" : ""}`}>
            <div className="navbar">
                <div className="nav_cont_1">
                    <a href="/">
                        <p id="title_navbar">
                            {/* <FaBoxOpen /> */}
                            <i className="fa-solid fa-layer-group"></i>
                            FlatJourney
                        </p>
                    </a>
                    {/* <span className="ia_span">AI</span> */}
                </div>
                <div className="nav_cont_1">
                    <div>
                        {/* <button className="waitin_list_btn" onClick={() => navigate("/waiting-list")}>
                            {t.waiting_list} <i className="fa-solid fa-login"></i>
                        </button> */}
                        {/* <button className="register_btn" onClick={() => navigate("/register")}>
                            {t.start_now} <i className="fa-solid fa-login"></i>
                        </button>
                        <button className="login_btn" onClick={() => navigate("/login")}>
                            {t.login} <i className="fa-solid fa-login"></i>
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavbarMP;
