import React, { useState, useEffect } from "react";
import { IoSparkles } from "react-icons/io5";
import { useLanguage } from "../Translations/LenguageContext";
import RentalContent from "./RentalContent";



const MainPM = () => {
    const [selectedRentalType, setSelectedRentalType] = useState("Short");
    const [isFading, setIsFading] = useState(false);
    const { t } = useLanguage()

    const RENTAL_TYPES = [
        {
            key: "Short",
            title: "Boost",
            deposit: "948,23€",
            revenueTitle: t.flatjourney_pm.boost.title,
            revenueSubtitle: t.flatjourney_pm.boost.subtitle,
            chartType: "m",
            images: {
                left: "IMG/kitchen.jpg",
                center: "IMG/bathroom.webp",
                right: "IMG/bedroom.webp",
            },
        },
        {
            key: "Long",
            title: "Stable",
            deposit: "1.572,23€",
            revenueTitle: t.flatjourney_pm.stable.title,
            revenueSubtitle: t.flatjourney_pm.stable.subtitle,
            chartType: "l",
            images: {
                left: "IMG/bedroom.webp",
                center: "IMG/kitchen.jpg",
                right: "IMG/bathroom.webp",
            },
        },
        {
            key: "Fixed",
            title: "Fixed",
            deposit: "12.000,00€",
            revenueTitle: t.flatjourney_pm.fixed.title,
            revenueSubtitle: t.flatjourney_pm.fixed.subtitle,
            chartType: "f",
            images: {
                left: "",
                center: "IMG/zen.jpg",
                right: "",
            },
        },
    ];


    // Automatically cycle through rental types every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setIsFading(true); // Trigger fade-out
            setTimeout(() => {
                setSelectedRentalType((prevType) => {
                    const currentIndex = RENTAL_TYPES.findIndex((type) => type.key === prevType);
                    const nextIndex = (currentIndex + 1) % RENTAL_TYPES.length;
                    return RENTAL_TYPES[nextIndex].key;
                });
                setIsFading(false); // Trigger fade-in
            }, 500); // Match fade-out duration
        }, 4000);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    const selectedRental = RENTAL_TYPES.find((type) => type.key === selectedRentalType);

    return (
        <div className="main_pm_cont">
            <div className="main_pm_container">
                <p className="main_title_pm">
                    {t.flatjourney_pm.title} <span className="ia_span_pm">{t.flatjourney_pm.re_title}</span> {t.flatjourney_pm.business_title}.
                </p>
                <p className="main_subtite_pm">
                    {t.flatjourney_pm.subtitle}
                </p>
                <div className="action_btn_cont">
                    {/* <button className="action_btns_pm_no_pulse">
                        <i className="pulse_pm">
                            <IoSparkles />
                        </i> {t.flatjourney_pm.start_btn}
                    </button> */}
                </div>
            </div>

            <div className="main_pm_container">
                <div className="rent_type_phone_pm">
                    {RENTAL_TYPES.map(({ key, title }) => (
                        <p
                            key={key}
                            className={`rent_type_phone_inner_pm ${selectedRentalType === key ? "selected_rental_type" : ""}`}
                        >
                            {title}
                        </p>
                    ))}
                </div>
                {selectedRental && (
                    <div className={`fade-container ${isFading ? "fading" : ""}`}>
                        <RentalContent
                            deposit={selectedRental.deposit}
                            revenueTitle={selectedRental.revenueTitle}
                            revenueSubtitle={selectedRental.revenueSubtitle}
                            chartType={selectedRental.chartType}
                            images={selectedRental.images}
                        />

                    </div>
                )}
            </div>
        </div>
    );
};

export default MainPM;
