import React from "react";

const NewSales = () => {
    return(
        <div className="new_deal_container">
               
        </div>
    )
}

export default NewSales