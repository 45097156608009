import React, { useState, useRef } from "react";
import { useLanguage } from "../../../../Translations/LenguageContext";
import { Dropdown } from "react-bootstrap";
import DeleteFileModal from "./DeleteResourceModal";

const FileResourceOpenAgency = ({ setSelectedFiles, handleUploadFile, files, handleDownload, agency_id, user_id, fetchFiles, uploadingFile, noFile, isLoadingfile }) => {
    const { t } = useLanguage();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const fileInputRef = useRef(null);

    const handleOpenDeleteModal = (file) => {
        setSelectedFile(file);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedFile(null);
        fetchFiles();
    };

    const formatDate = (uploadedAt) => {
        const date = new Date(uploadedAt);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });
    };

    const handleFileUpload = () => {
        handleUploadFile();
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <div className="middle_second_open_agency">
            <p className="middle_second_open_agency_title">{t.agency.resources.title}</p>
            <p className="middle_second_open_agency_subtitle">
                {t.agency.resources.subtitle}
            </p>
            <div className="shared_space_agency_cont_input">
                <input
                    ref={fileInputRef}
                    className={`form-control ${noFile ? "not_allowed_input" : ""}`}
                    type="file"
                    id="formFile"
                    multiple
                    onChange={(e) => setSelectedFiles(e.target.files)}
                />
                {uploadingFile ? (
                    <button className="upload_file_btn_agency">
                        <i className="fa-solid fa-rotate rotating"></i>
                    </button>
                ) : noFile ? (
                    <button className="upload_file_btn_agency not_allowed" onClick={handleFileUpload}>
                        <i className="fa-solid fa-circle-xmark"></i>
                    </button>
                ) : (
                    <button className="upload_file_btn_agency" onClick={handleFileUpload}>
                        <i className="fa-solid fa-upload"></i>
                    </button>
                )}
            </div>

            <div className="shared_space_agency_cont">
                {files.length > 0 ? (
                    files.map((file) => (
                        <div className="shared_file_agency" key={file.file_name}>
                            <i
                                className="fa-solid fa-download download_icon"
                                onClick={() => handleDownload(file.file_url)}
                            ></i>
                            <p className="file_name_agency">{file.file_name}</p>
                            <p className="file_loaded_by">{file.uploaded_by.email}</p>
                            <p className="file_loaded_date">{formatDate(file.uploaded_at)}</p>

                            <Dropdown>
                                <Dropdown.Toggle className="custom-dropdown-toggle" as="div">
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="custom-dropdown-menu">
                                    <Dropdown.Item
                                        className="drop_down_item delete"
                                        onClick={() => handleOpenDeleteModal(file)}
                                    >
                                        {t.agency.resources.delete} {file.file_name}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    ))
                ) : (
                    <div className="no_data_open_agency">
                        <p>{t.agency.resources.no_shared}</p>
                    </div>
                )}
            </div>
            {files.length > 0 > 0 && (
                <>
                    {isLoadingfile ? (
                        <button onClick={fetchFiles} className="refresh_chat_btn"><i className="fa-solid fa-rotate rotating"></i></button>
                    ) : (
                        <button onClick={fetchFiles} className="refresh_chat_btn"><i className="fa-solid fa-rotate"></i></button>
                    )}
                </>
            )}
            {selectedFile && (
                <DeleteFileModal
                    show={showDeleteModal}
                    handleClose={handleCloseDeleteModal}
                    selectedFile={selectedFile.file_url}
                    fileName={selectedFile.file_name}
                    agency_id={agency_id}
                    user_id={user_id}
                />
            )}
        </div>
    );
};

export default FileResourceOpenAgency;
