import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

const data = [
  { value: 5, label: 'A' },
  { value: 10, label: 'B' },
  { value: 15, label: 'C' },
  { value: 20, label: 'D' },
];

const size = {
  height: 250,
};

// Custom colors for each slice
const colors = ['#FF5733', '#33FF57', '#3357FF', '#F39C12'];

// Center label styling
const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
}));

function PieCenterLabel({ value, label }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <>
      <StyledText
        x={left + width / 2}
        y={top + height / 2 - 10} // Position for the main data value
        fontSize={24}
        fontWeight="bold"
      >
        {value}
      </StyledText>
      <StyledText
        x={left + width / 2}
        y={top + height / 2 + 15} // Position for the subtext
        fontSize={16}
        opacity={0.7}
      >
        {label}
      </StyledText>
    </>
  );
}

export default function OpenAgencyChart() {
  return (
    <div className="middle_second_open_agency">
      <p className="middle_second_open_agency_title">Panoramica agenzia</p>
      <p className="middle_second_open_agency_subtitle">Ottieni una panoramica completa delle performance della tua agenzia</p>
      <PieChart
        series={[{
          data,
          innerRadius: 80,
          colors, // Pass the custom colors to the PieChart
        }]}
        {...size}
      >
        <PieCenterLabel value="+14%" label="Performance" />
      </PieChart>
    </div>
  );
}
