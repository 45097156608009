// Footer.js
import React, { useEffect, useState } from "react";
import { SiMinutemailer } from "react-icons/si";
import { useLanguage } from "../Translations/LenguageContext";
import { MdMarkEmailRead, MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function FooterMarketPlace() {

   
    return (
        <div className="footer_marketplace">
            
        </div>
    );
}

export default FooterMarketPlace;
