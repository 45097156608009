import React from "react";
import { Modal } from "react-bootstrap";
import { useLanguage } from "../../../Translations/LenguageContext";

const RentDetailsModal = ({ show, handleClose, details }) => {
    const { t } = useLanguage();

    const handleModalClose = () => {
        handleClose();
    };

    const { rentedPropActions, docInformation, propInfo } = details || {};



    // Render monthly details table
    const renderMonthlyDetailsTable = () => {
        if (!propInfo?.monthlyDetails?.monthly_ammount) return null;
        const monthlyAmounts = propInfo.monthlyDetails.monthly_ammount;

        return (
            <table className="monthly_det_table">

                <thead>
                    <tr>
                        <th>Mese</th>
                        <th>Importo</th>
                        <th>Comm. SEPA</th>
                        <th>Totale dovuto</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(monthlyAmounts).map((month) => (
                        <tr key={month}>
                            <td>{month.charAt(0).toUpperCase() + month.slice(1)}</td>
                            <td>{`${monthlyAmounts[month]} ${propInfo.monthlyDetails.currency}`}</td>
                            <td>{`1,90 ${propInfo.monthlyDetails.currency}`}</td>
                            <td>{`${(monthlyAmounts[month] + 1.9).toFixed(2)} ${propInfo.monthlyDetails.currency}`}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const handleCompletePayment = async () => {
        try {
            const response = await fetch("http://127.0.0.1:5000/create-checkout-session", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    tenantId: "675bed29011e8a634324264c", // Replace with dynamic tenant ID
                    agencyId: "675beb6f011e8a6343242649"  // Replace with dynamic agency ID
                })
            });

            const data = await response.json();
            if (data.url) {
                window.location.href = data.url; // Redirect to Stripe Checkout
            } else {
                console.error("Error:", data.error);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };


    return (
        <Modal show={show} onHide={handleModalClose} className="modal_contacts_details">
            <div className="header_modal">
                <div className="modal_title_cont_prop">
                </div>
                <button className="close_x_modale" onClick={handleModalClose}>
                    <i className="fa-solid fa-x"></i>
                </button>
            </div>
            <div className="body_modal_delete">
                <div className="table_monthly_det">
                    <p className="monthly_details_rent_title">Dettagli affitto</p>
                    {renderMonthlyDetailsTable()}
                </div>

                <div className="table_monthly_det">

                    <p className="monthly_details_rent_title">Altri dettagli</p>
                    <div className="details-container">
                        <div className="details_cont_label">
                            <label>ID Proprietà:</label>
                            <p>{propInfo?.property_id || "N/A"}</p>

                        </div>

                        <div className="details_cont_label">
                            <label>Metodo di pagamento:</label>
                            <p>{t.tenants[propInfo?.monthlyDetails?.payment_method] || "N/A"}</p>
                        </div>

                        {/* <div className="details_cont_label">
                            <label>Commissione inclusa:</label>
                            <p>{propInfo?.monthlyDetails?.commission_included ? "Sì" : "No"}</p>
                        </div> */}

                        <div className="details_cont_label">
                            {console.log(propInfo)}
                            <label>Deposito incluso:</label>
                            <p>{propInfo?.monthlyDetails?.deposit_included ? "Sì" : "No"}</p>
                        </div>

                        <div className="details_cont_label">
                            <label>Data inizio:</label>
                            <p>{propInfo?.monthlyDetails?.from_date || "N/A"}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer_modal">
                <div className="footer_btn_cont">
                    <button className="close_modal_btn" onClick={handleCompletePayment}>
                        Complete Payment
                    </button>
                </div>
                <div className="footer_btn_cont">
                    <button className="close_modal_btn" onClick={handleModalClose}>
                        {t.agency.modal_reactivate.back_btn}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default RentDetailsModal;
