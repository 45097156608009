import React from "react";

const LoadingTable = () => {
    return (
        <div className="loading_table_cont">
            {[...Array(10)].map((_, rowIndex) => (
                <div className="row_loading_table" key={rowIndex}>
                    {[...Array(6)].map((_, cellIndex) => (
                        <div className="cell_loading_table" key={cellIndex}>
                            <div className="inner_cell_tabl"></div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default LoadingTable;
