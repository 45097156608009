import React from "react";
import SalesCharts from "./SalesCharts";
import { useLanguage } from "../../../Translations/LenguageContext";
import { useNavigate } from "react-router-dom";

const Sales = () => {
    const {t} = useLanguage()
    const navigate = useNavigate()

    const HandleNewDeal = () => {
        navigate("new-deal")
    }

    return (
        <div className="main_view_section">
            <p className="title_main_view">Sales</p>
            <div className="sales_cont_header">
                <SalesCharts/>
            </div>
            <div className='property_func_bar'>
                <button className='add_property_btn' onClick={HandleNewDeal}>
                    <i className='fa-solid fa-plus'></i> New deal
                </button>
                <div className='filter_input_cont'>
                    <i className='fa-solid fa-magnifying-glass'></i>
                    <input
                        className='search_bar_input'
                        placeholder={`search...`}
                    // value={searchQuery}
                    // onChange={handleSearchChange}
                    />
                </div>
            </div>
            <div className="sales_container">

            </div>
        </div>
    )
}

export default Sales