import React, { useState } from 'react';
import { useLanguage } from '../Translations/LenguageContext';
import AskInfoModal from './AskInfoModal';

const AskBtn = () => {
    const { t } = useLanguage();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);

    const closeModal = () => setIsModalOpen(false);

    return (
        <div className="ask_btn_cont">
            <p className="steps_pm_cont_title">{t.flatjourney_pm.qa.more_questions}</p>
            <button
                className="contact_us_pm_btn"
                onClick={openModal}
            >
                {t.flatjourney_pm.qa.ask_btn}
            </button>

            <AskInfoModal show={isModalOpen} handleClose={closeModal} />
        </div>
    );
};

export default AskBtn;
