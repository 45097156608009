import React from "react";
import { useLanguage } from "../Translations/LenguageContext";
import TinyLineChart from "./GraphPM";

const RentalContent = ({ deposit, revenueTitle, revenueSubtitle, chartType, images }) => {
    const { t } = useLanguage();

    return (
        <div className="phone_container_pm">
            <div className="first_mex_phone">
                <div className="header_first_mex_phone_pm">
                    <i className="fa-solid fa-bell"></i>
                    <p className="first_mex_phone_title">Paypermate</p>
                </div>
                <p className="first_mex_phone_subtitle">
                    {t.flatjourney_pm.deposit_one} <strong>{deposit}</strong> {t.flatjourney_pm.deposit_two}
                </p>
            </div>
            <div className="second_mex_phone">
                <p className="second_mex_phone_title">{revenueTitle}</p>
                <p className="second_mex_phone_subtitle">{revenueSubtitle}</p>
                <div className="tiny_line_cont">
                    <TinyLineChart type={chartType} />
                </div>
            </div>
            <div className="phone_pm">
                <div className="inner_phone_pm">
                    <div className="phone_mic_pm"></div>
                    <div className="phone_cont_main_pm">
                        <div className="title_phone_pm_cont">
                            <p className="title_phone_pm">{t.flatjourney_pm.listing_setup_title}</p>
                            <p className="subtitle_phone_pm">{t.flatjourney_pm.listing_setup_subtitle}</p>
                        </div>
                        <div className="houses_cards_deck_pm">
                            <div
                                className="left_right_deck_pm"
                                style={{ backgroundImage: `url(${images.left})` }}
                            ></div>
                            <div
                                className="house_card_pm"
                                style={{ backgroundImage: `url(${images.center})` }}
                            ></div>
                            <div
                                className="left_right_deck_pm"
                                style={{ backgroundImage: `url(${images.right})` }}
                            ></div>
                        </div>
                        <div className="last_card_pm">
                            <span className="pin_cont">📌</span>
                            <p className="last_card_pm_title">{t.flatjourney_pm.connect_title}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RentalContent