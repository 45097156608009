import React, { useEffect, useRef } from "react";
import Spinner from "../../../../components/Spinner";
import { useLanguage } from "../../../../Translations/LenguageContext";
import { useNavigate } from "react-router-dom";

const MessagesOpenAgency = ({ isRefreshing, isLoading, message, messages, setMessage, handleSendMessage, user_id, fetchMessages, agency_id }) => {
    const { t } = useLanguage();
    const navigate = useNavigate()
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const formatTimestamp = (timestamp) => {
        const messageDate = new Date(timestamp);
        const today = new Date();

        if (
            messageDate.getDate() === today.getDate() &&
            messageDate.getMonth() === today.getMonth() &&
            messageDate.getFullYear() === today.getFullYear()
        ) {
            return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else {
            return messageDate.toLocaleDateString('en-GB');
        }
    };

    const DisancoreChat = () => {
        window.open(`/control_panel/agencies/view/${agency_id}/agency_chat`, '_blank');
    };
    

    return (
        <div className="middle_first_open_agency">
            <p className="middle_second_open_agency_title">{t.agency.messages.title} <span className="disancore_chat" onClick={DisancoreChat}><i className="fa-solid fa-arrow-up-right-from-square"></i></span></p>
            <p className="middle_second_open_agency_subtitle">{t.agency.messages.subtitle}</p>

            {messages.length > 0 && (
                <>
                    {isRefreshing ? (
                        <button onClick={fetchMessages} className="refresh_chat_btn"><i className="fa-solid fa-rotate rotating"></i></button>
                    ) : (
                        <button onClick={fetchMessages} className="refresh_chat_btn"><i className="fa-solid fa-rotate"></i></button>
                    )}
                </>
            )}

            <div className="dashboard_agency_chat_cont">
                {isLoading ? (
                    <Spinner loading={true} err={{ message: "", display: false }} />
                ) : messages.length > 0 ? (
                    <div className="dashboard_agency_mex_cont">
                        {messages.map((msg, index) => (
                            <div
                                className="dashboard_agency_mex"
                                key={index}
                            >
                                {msg.sender_id === user_id ? (
                                    <p className={`dashboard_agency_sender ${msg.sender_id === user_id ? "my_messege_agency_name" : ""}`}>
                                        {t.agency.you} - {formatTimestamp(msg.timestamp)}
                                    </p>
                                ) : (
                                    <p className={`dashboard_agency_sender ${msg.sender_id === user_id ? "my_messege_agency_name" : ""}`}>
                                        {msg.username} {msg.usersurname} - {formatTimestamp(msg.timestamp)}
                                    </p>
                                )}
                                <p className={`dashboard_agency_message ${msg.sender_id === user_id ? "my_messege_agency" : ""}`}>
                                    {msg.message.split(" ").map((word, i) =>
                                        word.startsWith("@") ? (
                                            <span className="tag_agency" key={i}>
                                                {word}
                                            </span>
                                        ) : (
                                            word + " "
                                        )
                                    )}
                                </p>
                            </div>
                        ))}
                        <div ref={messagesEndRef}></div>
                    </div>
                ) : (
                    <div className="no_data_open_agency">
                        <p>{t.agency.messages.first_mex}</p>
                    </div>
                )}
            </div>
            <div className="dashboard_agency_chat_input_cont">
                <input
                    className="dashboard_agency_chat_input"
                    placeholder={t.agency.messages.placeholder}
                    value={message}
                    onInput={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault(); // Prevent newline if Enter is pressed without Shift
                            handleSendMessage();
                        }
                    }}
                />
                <button
                    className="dashboard_agency_chat_btn"
                    onClick={handleSendMessage}
                >
                    <i className="fa-solid fa-paper-plane"></i>
                </button>
            </div>
        </div>
    );
};

export default MessagesOpenAgency;
