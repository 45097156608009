import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import ThirdSlide from "./ThirdSlide";
import FirstSlide from "./FirstSlide";
import SecondSlide from "./SecondSlide";
import FourthSlide from "./FourthSlide";

const PitchDeck = () => {
    const { _to } = useParams();
    const [pitchData, setPitchData] = useState(null);
    const [error, setError] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`http://127.0.0.1:5000/get_pitch_deck/${_to}`);
                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }
                const data = await response.json();
                setPitchData(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchData();
    }, [_to]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!pitchData) {
        return <div>Loading...</div>;
    }

    const slides = [
        <FirstSlide data={pitchData} />,
        <SecondSlide data={pitchData} />,
        <ThirdSlide data={pitchData} />,
        <FourthSlide data={pitchData} />,
    ];

    const handlePrevious = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? slides.length - 1 : prevSlide - 1
        );
    };

    const handleNext = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === slides.length - 1 ? 0 : prevSlide + 1
        );
    };

    const progressPercentage = ((currentSlide + 1) / slides.length) * 100;

    return (
        <div className="pitch_deck">
            <div className="loading_bar">
                <div
                    className="inner_loading_bar"
                    style={{ width: `${progressPercentage}%` }}
                ></div>
            </div>
            <div className="pitch_deck_container">
                <button className="nav_button" onClick={handlePrevious}>
                    <i className="fa-solid fa-chevron-left"></i>
                </button>
                <div className="slide">{slides[currentSlide]}</div>
                <button className="nav_button" onClick={handleNext}>
                    <i className="fa-solid fa-chevron-right"></i>
                </button>
            </div>
        </div>
    );
};

export default PitchDeck;
