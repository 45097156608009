import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home"
import Bookings from "./LLSections/Booking/Bookings";
import Properties from "./LLSections/Property/Properties";
import Contacts from "./LLSections/Contacts/Contacts";
import Messages from "./LLSections/Messages/Messages";
import Transactions from "./LLSections/Transactions";
import Balance from "./LLSections/Balance";
import Report from "./LLSections/Report";
import TenantAnalysis from "./LLSections/TenantAnalysis";
import MarketPricing from "./LLSections/MarketPricing";
import ExpensesForecast from "./LLSections/ExpensesForecast";
import Profile from "./LLSections/Profile/Profile";
import Settings from "./LLSections/Settings/Settings";
import Notifications from "./LLSections/Notifications";
import WBManager from "./LLSections/WebsiteBuilder/WB_Manager";
import TenantPayments from "./TenantSection/Payments/Payments";
import LLPayments from "./LLSections/Payments/Payments";
import Tenants from "./LLSections/Tenants/Tenants";
import NewTenant from "./LLSections/Tenants/AddTenants/NewTenant";
import UpdateTenant from "./LLSections/Tenants/UpdateTenants/UpdateTenant";
import MyRentals from "./TenantSection/MyRentals/MyRentals";
import Screening from "./LLSections/Screening/Screening";
import NewScreen from "./LLSections/Screening/NewScreen";
import SingleProp from "./TenantSection/MyRentals/SingleProperty";
import NewProperty from "./LLSections/Property/NewProperty";
import OpenProperty from "./LLSections/Property/OpenProperty";
import Agencies from "./AgencySection/Agencies/Agencies";
import NewAgency from "./AgencySection/Agencies/NewAgencies";
import OpenAgency from "./AgencySection/Agencies/OpenAgency";
import AgencyUsers from "./AgencySection/Users/AgencyUsers";
import NewUser from "./AgencySection/Users/NewUser";
import RequestAdminUsers from "./AgencySection/Users/RequestAdminUsers";
import AgencyTeams from "./AgencySection/Teams/AgencyTeams";
import Sales from "./AgencySection/Sales/Sales";
import NewSales from "./AgencySection/Sales/NewSales";

function MainView({ userData, no_session, refreshSidebar }) {
  return (
    <div className="main_view_container">
      <Routes>
        <Route path="home" element={<Home userData={userData} no_session={no_session} />} />
        <Route path="bookings" element={<Bookings />} />

        <Route path="properties" element={<Properties refreshSidebar={refreshSidebar}/>} />
        <Route path="properties/new-property" element={<NewProperty />} />
        <Route path="properties/:property_id" element={<NewProperty />} />
        <Route path="properties/view/:prop_id" element={<OpenProperty />} />

        <Route path="agencies" element={<Agencies userData={userData}/>} />
        <Route path="agencies/new-agency" element={<NewAgency userData={userData} />} />
        <Route path="agencies/:agency_id" element={<NewAgency userData={userData} />} />
        <Route path="agencies/view/:agency_id" element={<OpenAgency userData={userData}/>} />

        <Route path="sales" element={<Sales userData={userData}/>} />
        <Route path="sales/new-deal" element={<NewSales userData={userData}/>} />


        <Route path="users" element={<AgencyUsers userData={userData}/>} />
        <Route path="users/new-user" element={<NewUser userData={userData}/>} />
        <Route path="users/request-new-user" element={<RequestAdminUsers userData={userData}/>} />


        <Route path="teams" element={<AgencyTeams />} />

        <Route path="contacts" element={<Contacts />} />
        <Route path="messages" element={<Messages />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="balance" element={<Balance />} />
        <Route path="report" element={<Report />} />
        <Route path="tenant-analysis" element={<TenantAnalysis />} />
        <Route path="market-pricing" element={<MarketPricing />} />
        <Route path="expenses-forecast" element={<ExpensesForecast />} />
        <Route path="profile" element={<Profile />} />
        <Route path="settings" element={<Settings />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="website-builder" element={<WBManager />} />
        <Route path="owner-payments" element={<LLPayments />} />
        <Route path="tenants" element={<Tenants />} />
        <Route path="tenants/new-tenant" element={<NewTenant userData ={userData}/>} />
        <Route path="tenants/update-tenant/:tenant_id" element={<UpdateTenant userData ={userData}/>} />
        <Route path="screening" element={<Screening />} userData ={userData}/>
        <Route path="screening/new-screen" element={<NewScreen />} userData ={userData}/>


        {/* Tenant */}
        <Route path="tenant-payment" element={<TenantPayments />} />
        <Route path="my-rentals" element={<MyRentals userData ={userData}/>} />
        <Route path="my-rentals/:prop_id" element={<SingleProp userData ={userData}/>} />
      </Routes>
    </div>
  );
}

export default MainView;
