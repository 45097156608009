import React, { useState } from "react";
import RentDetailsModal from "./RentDetailsModal";

const ActionsData = ({ tenantRentedProperties }) => {
    const [showModalDetails, setShowModalDetails] = useState(false);
    const [modalDetails, setModalDetails] = useState(null);

    const handleDownload = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = ""; // Specify a default filename if desired.
        link.target = "_blank"; // Optional: opens the download in a new tab.
        link.click();
    };

    const handleOpenModal = (details) => {
        setModalDetails(details); // Set the details to be displayed in the modal
        setShowModalDetails(true);
    };

    const handleCloseModal = () => {
        setShowModalDetails(false);
        setModalDetails(null); // Clear details on modal close
    };

    return (
        <div className="request_cont">
            {Object.keys(tenantRentedProperties).map((key) => {
                const rentedPropActions = tenantRentedProperties[key];
                console.log(rentedPropActions)
                const docInformation = rentedPropActions.tenant_document_information;
                const propInfo = rentedPropActions.tenant_property_information;

                const timestamp = docInformation.timestamp;
                const date = new Date(timestamp);
                const today = new Date();

                const isToday =
                    date.getDate() === today.getDate() &&
                    date.getMonth() === today.getMonth() &&
                    date.getFullYear() === today.getFullYear();

                const formattedDate = date.toLocaleDateString("it-IT");

                let result;
                if (isToday) {
                    const time =
                        date.getHours().toString().padStart(2, "0") +
                        "." +
                        date.getMinutes().toString().padStart(2, "0");
                    result = `${formattedDate} - ${time}`;
                } else {
                    result = formattedDate;
                }

                return (
                    <>
                        <p className="request_cont_title">Azioni richieste</p>
                        <p className="request_cont_subtitle">
                            Per poter accedere all'immobile completa le azioni richieste dal
                            proprietario
                        </p>
                        <div className="documents_request_cont">
                            <label>
                                <i className="fa-solid fa-info"></i> Dettagli affitto
                            </label>
                            <p className="months_payment_cont_toggle_title"  onClick={() => handleOpenModal({ rentedPropActions, docInformation, propInfo })}>Visualizza dettagli</p>
                        </div>
                        <div className="documents_request_cont">
                            {console.log(docInformation)}
                            {docInformation.uploaded_files.length > 0 && (
                                <>
                                    <label>
                                        <i className="fa-solid fa-folder"></i> Scarica e firma i seguenti documenti
                                    </label>
                                    <div className="doc_cont">
                                        {docInformation.uploaded_files.map((file, index) => (
                                            <div className="download_doc" key={index} onClick={() => handleDownload(file.url)}>
                                                {file.mandatory_signing && (
                                                    <div className="to_sign_marker">
                                                        <i className="fa-solid fa-pen"></i>
                                                    </div>
                                                )}
                                                <i className="fa-solid fa-download"></i>
                                                <p>{`Documento ${index + 1}.pdf`}</p>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}



                            <p className="requested_docs_cont_spec_title">Invia documenti</p>
                            <div className="requested_docs_cont_spec">
                                <div className="doc_single">
                                    <p>Allega tutti i documenti qui</p>
                                </div>
                            </div>
                        </div>
                        <div className="documents_request_cont">
                            <label>
                                <i className="fa-solid fa-upload"></i> Carica i documenti
                                richiesti dal proprietario
                            </label>
                            <div className="requested_docs_cont">
                                {docInformation.ci_required === "true" && (
                                    <>
                                        <p className="requested_docs_cont_spec_title">
                                            Documenti personali <span className="to_load_files">0/2</span>
                                        </p>
                                        <div className="requested_docs_cont_spec">
                                            <div className="doc_single">
                                                <p>Fronte / Retro</p>
                                                <input className="document_file_upload_tenant" type="file"></input>
                                            </div>
                                            <i className="fa-regular fa-circle"></i>
                                        </div>
                                    </>
                                )}

                                {docInformation.job_contract_required === "true" && (
                                    <>
                                        <p className="requested_docs_cont_spec_title">
                                            Contratto di lavoro
                                        </p>
                                        <div className="requested_docs_cont_spec">
                                            <div className="doc_single">
                                                <p>Contratto di lavoro</p>
                                                <input className="document_file_upload_tenant" type="file"></input>
                                            </div>
                                            <i className="fa-regular fa-circle"></i>
                                        </div>
                                    </>
                                )}


                                {docInformation.income_statement_required === "true" && (
                                    <>
                                        <p className="requested_docs_cont_spec_title">
                                            Dichiarazione dei redditi
                                        </p>
                                        <div className="requested_docs_cont_spec">
                                            <div className="doc_single">
                                                <p>Dichiarazione dei redditis</p>
                                                <input className="document_file_upload_tenant" type="file"></input>
                                            </div>
                                            <i className="fa-regular fa-circle"></i>
                                        </div>
                                    </>
                                )}

                                {docInformation.guarant_documents_required === "true" && (
                                    <>
                                        <p className="requested_docs_cont_spec_title">
                                            Documenti garante <span className="to_load_files">0/2</span>
                                        </p>
                                        <div className="requested_docs_cont_spec">
                                            <div className="doc_single">
                                                <p>Fronte / Retro</p>
                                                <input className="document_file_upload_tenant" type="file"></input>
                                            </div>
                                            <i className="fa-regular fa-circle"></i>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <button className="confirm_btn_tenant_upload">Conferma</button>
                    </>
                );
            })}

            {modalDetails && (
                <RentDetailsModal
                    show={showModalDetails}
                    handleClose={handleCloseModal}
                    details={modalDetails}
                />
            )}
        </div>
    );
};

export default ActionsData;
