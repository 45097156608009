import React, { useState } from "react";
import { useLanguage } from "../Translations/LenguageContext";
import AskBtn from "./AskBtn";

const QAPM = () => {
    const { t } = useLanguage()
    const [openSections, setOpenSections] = useState({
        faq: false,
        guide: false,
        policies: false,
        payments: false,
    });

    const toggleSection = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    return (
        <div className="qa_pm_cont">
            <p className="steps_pm_cont_title">
                {t.flatjourney_pm.qa.title}
            </p>
            <div className="qa_cont_pm">
                <div className="qa_toggle_cont" onClick={() => toggleSection("faq")}>
                    <p className="qa_toggle_cont_title">
                        {t.flatjourney_pm.qa.faq_title} <i className="fa-solid fa-chevron-down"></i>
                    </p>
                    <div
                        className={`qa_cont ${openSections.faq ? "open" : ""}`}
                    >
                        <p className="qa_cont_qestion">{t.flatjourney_pm.qa.faq.title}</p>
                        <p className="qa_cont_answer">
                            {t.flatjourney_pm.qa.faq.answer}
                        </p>
                        <p className="qa_cont_qestion">{t.flatjourney_pm.qa.faq.title_1}</p>
                        <p className="qa_cont_answer">
                            {t.flatjourney_pm.qa.faq.answer_1}
                        </p>
                        <p className="qa_cont_qestion">{t.flatjourney_pm.qa.faq.title_2}</p>
                        <p className="qa_cont_answer">
                            {t.flatjourney_pm.qa.faq.answer_2}
                        </p>
                    </div>
                </div>

                <div className="qa_toggle_cont" onClick={() => toggleSection("guide")}>
                    <p className="qa_toggle_cont_title">
                        {t.flatjourney_pm.qa.step_by_step_title} <i className="fa-solid fa-chevron-down"></i>
                    </p>
                    <div
                        className={`qa_cont ${openSections.guide ? "open" : ""}`}
                    >
                        <p className="qa_cont_qestion">{t.flatjourney_pm.qa.step_by_step.title}</p>
                        <p className="qa_cont_answer">
                            {t.flatjourney_pm.qa.step_by_step.answer}
                        </p>
                        <p className="qa_cont_qestion">{t.flatjourney_pm.qa.step_by_step.title_1}</p>
                        <p className="qa_cont_answer">
                            {t.flatjourney_pm.qa.step_by_step.answer_1}
                        </p>
                        <p className="qa_cont_qestion">{t.flatjourney_pm.qa.step_by_step.title_2}</p>
                        <p className="qa_cont_answer">
                            {t.flatjourney_pm.qa.step_by_step.answer_2}
                        </p>
                    </div>
                </div>

                <div className="qa_toggle_cont" onClick={() => toggleSection("policies")}>
                    <p className="qa_toggle_cont_title">
                    {t.flatjourney_pm.qa.pr_title} <i className="fa-solid fa-chevron-down"></i>
                    </p>
                    <div
                        className={`qa_cont ${openSections.policies ? "open" : ""}`}
                    >
                        <p className="qa_cont_qestion">{t.flatjourney_pm.qa.pr.title}</p>
                        <p className="qa_cont_answer">
                            {t.flatjourney_pm.qa.pr.answer}
                        </p>
                        <p className="qa_cont_qestion">{t.flatjourney_pm.qa.pr.title_1}</p>
                        <p className="qa_cont_answer">
                            {t.flatjourney_pm.qa.pr.answer_1}
                        </p>
                    </div>
                </div>

                <div className="qa_toggle_cont" onClick={() => toggleSection("payments")}>
                    <p className="qa_toggle_cont_title">
                        {t.flatjourney_pm.qa.payment_title} <i className="fa-solid fa-chevron-down"></i>
                    </p>
                    <div
                        className={`qa_cont ${openSections.payments ? "open" : ""}`}
                    >
                        <p className="qa_cont_qestion">{t.flatjourney_pm.qa.payment.title}</p>
                        <p className="qa_cont_answer">
                            {t.flatjourney_pm.qa.payment.answer}
                        </p>
                    </div>
                </div>
            </div>
            <AskBtn/>
        </div>
    );
};

export default QAPM;
