import React from "react";
import { Modal, Button } from "react-bootstrap";

const OpenModalImages = ({ images, show, handleClose }) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            fullscreen
            centered
            backdrop="static"
        >
            <Modal.Body>
                <div className="modal_image_cont">
                    {images.map((img, index) => (
                        <div
                            key={index}
                            className="modal_image_body"
                        >
                            <img
                                src={img.url}
                                alt={`Image ${index + 1}`}
                                className="image_open"
                            />
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <button className="close_img_galery_btn" onClick={handleClose}><i className="fa-solid fa-x"></i></button>
        </Modal>
    );
};

export default OpenModalImages;
