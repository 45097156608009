import React from "react";
import JourneyNavbar from "../JourneyNavbar";
import MainStartJourney from "./MainStartJourney";
import FooterJourney from "../FooterJourney";

const StartJourney = () => {
    return(
        <div>
            <JourneyNavbar page_to_navigate={"/PM"}/>
            <MainStartJourney/>
            <FooterJourney page_to_navigate={"complete-info"} isFormValid={true}/>
        </div>
    )
}

export default StartJourney