import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../Translations/LenguageContext";

import DeleteUserModal from "./DeleteUser";
import NewUserModal from "./NewUserModal";
import { resolveMotionValue } from "framer-motion";
import CompletePaymentModal from "./CompletePaymentModal";
import UsersTable from "./UsersTable";
import ReactivateSubscriptionModal from "./ReactivateSubscriptionModal";
import LoadingTable from "../../../components/LoadingTable";

const AgencyUsers = ({ userData }) => {
    const { t } = useLanguage();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showModalNewUser, setShowModalNewUser] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalReactivate, setShowModalReactivate] = useState(false)
    const [highlightedCell, setHighlightedCell] = useState(null);
    const [loadingCell, setLoadingCell] = useState(false)
    const [completePayment, showCompletePayment] = useState(false)
    const [loadingUserStatus, setLoadingUserStatus] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({
        user_id: '',
        amount: '',
        interval: '',
        plan: '',
        userName: '',
        userSurname: '',
        email: '',
        phone: '',
    });


    useEffect(() => {
        if (userData && userData._id) {
            fetchAgencyUsers(userData._id);
        }
    }, [userData]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const fetchAgencyUsers = (user_id) => {
        setLoadingUserStatus(true)
        fetch(`https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_agency_users?user_id=${user_id}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to load data');
                }
            })
            .then(data => {
                setUsers(data);
                setLoading(false);
                setLoadingUserStatus(false)
            })
            .catch(err => {
                setError(err.message || 'Error fetching users');
                setLoading(false);
                setLoadingUserStatus(false)
            });
    };


    const filteredUsers = users.filter((user) => {
        const userString = JSON.stringify(user).toLowerCase();
        return userString.includes(searchQuery);
    });
    

    const formatAmount = (amount) => {
        const amountStr = amount.toString();
        const paddedAmount = amountStr.padStart(3, '0');
        return `${paddedAmount.slice(0, -2)},${paddedAmount.slice(-2)}`;
    };

    const handleClose = () => {
        setShowModalDelete(false);
        setTimeout(() => {
            if (userData && userData._id) {
                fetchAgencyUsers(userData._id);
            }
        }, 1000);
    };

    const handleShowShowDelete = (user) => {
        setShowModalDelete(true);
        const lastPayment = user.payments?.sort(
            (a, b) => new Date(b.created_at.$date) - new Date(a.created_at.$date)
        )[0];

        const calculateAmount = () => {
            const type = user.license?.type?.toLowerCase();
            const interval = user.license?.interval?.toLowerCase();

            if (type === "basic" && interval === "month") return 699;
            if (type === "business" && interval === "month") return 999;
            if (type === "pro" && interval === "month") return 1500;
            if (type === "basic" && interval === "year") return 7600;
            if (type === "business" && interval === "year") return 10900;
            if (type === "pro" && interval === "year") return 16500;

            return '';
        };

        setSelectedRecord({
            user_id: user._id?.$oid || '',
            amount: lastPayment?.amount || calculateAmount(),
            interval: user.license?.interval || '',
            plan: user.license?.type === 'business' ? 'Business Standard' : user.license?.type || '',
            userName: user.personal_info?.name || '',
            userSurname: user.personal_info?.surname || '',
            email: user.personal_info?.email || '',
            phone: user.personal_info?.phone || '',
        });
    };

    const handleShowModalReactivate = (user) => {
        setShowModalReactivate(true);
        const lastPayment = user.payments?.sort(
            (a, b) => new Date(b.created_at.$date) - new Date(a.created_at.$date)
        )[0];

        const calculateAmount = () => {
            const type = user.license?.type?.toLowerCase();
            const interval = user.license?.interval?.toLowerCase();

            if (type === "basic" && interval === "month") return 699;
            if (type === "business" && interval === "month") return 999;
            if (type === "pro" && interval === "month") return 1500;
            if (type === "basic" && interval === "year") return 7600;
            if (type === "business" && interval === "year") return 10900;
            if (type === "pro" && interval === "year") return 16500;

            return '';
        };

        setSelectedRecord({
            user_id: user._id?.$oid || '',
            amount: lastPayment?.amount || calculateAmount(),
            interval: user.license?.interval || '',
            plan: user.license?.type === 'business' ? 'Business Standard' : user.license?.type || '',
            userName: user.personal_info?.name || '',
            userSurname: user.personal_info?.surname || '',
            email: user.personal_info?.email || '',
            phone: user.personal_info?.phone || '',
        });
    };

    const handleCloseModalReactivate = () => {
        setShowModalReactivate(false);
        setTimeout(() => {
            if (userData && userData._id) {
                fetchAgencyUsers(userData._id);
            }
        }, 1000);
    };

    const handleCloseModalNewUser = () => {
        setShowModalNewUser(false);
    };

    const handleShowModalNewUser = () => {
        setShowModalNewUser(true);
    };

    const handleCompletePayment = (user) => {
        showCompletePayment(true);

        const lastPayment = user.payments?.sort(
            (a, b) => new Date(b.created_at.$date) - new Date(a.created_at.$date)
        )[0];

        const calculateAmount = () => {
            const type = user.license?.type?.toLowerCase();
            const interval = user.license?.interval?.toLowerCase();

            if (type === "basic" && interval === "month") return 699;
            if (type === "business" && interval === "month") return 999;
            if (type === "pro" && interval === "month") return 1500;
            if (type === "basic" && interval === "year") return 7600;
            if (type === "business" && interval === "year") return 10900;
            if (type === "pro" && interval === "year") return 16500;

            return '';
        };

        setSelectedRecord({
            user_id: user._id?.$oid || '',
            amount: lastPayment?.amount || calculateAmount(),
            interval: user.license?.interval || '',
            plan: user.license?.type === 'business' ? 'Business Standard' : user.license?.type || '',
            userName: user.personal_info?.name || '',
            userSurname: user.personal_info?.surname || '',
            email: user.personal_info?.email || '',
            phone: user.personal_info?.phone || '',
        });
    };

    const handleCloseCompletePayment = () => {
        showCompletePayment(false)
    }

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };


    const handleCopy = (text) => {
        if (!text) return;

        navigator.clipboard.writeText(text).then(() => {
            setHighlightedCell(text);

            setTimeout(() => {
                setHighlightedCell(null);
            }, 2000);
        }).catch((err) => {
            console.error("Failed to copy: ", err);
        });
    };




    if (error) return (
        <div className='loading_mex_cont_err'>
            <p>{t.error}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );

    return (
        <div className="main_view_section">
            <p className="title_main_view">{t.sidebar.manage_licence}</p>
            <div className='property_bar_table_cont'>
                <div className='property_func_bar'>
                    <button className='add_property_btn' onClick={handleShowModalNewUser}>
                        <i className='fa-solid fa-plus'></i> {t.agency.new_user}
                    </button>
                    <div className='filter_input_cont'>
                        <i className='fa-solid fa-magnifying-glass'></i>
                        <input
                            className='search_bar_input'
                            placeholder={`${t.agency.search_user}...`}
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>

                {loading ? (
                    <LoadingTable />
                ) : (
                    <UsersTable
                        filteredUsers={filteredUsers}
                        handleCompletePayment={handleCompletePayment}
                        handleShowShowDelete={handleShowShowDelete}
                        handleShowModalReactivate={handleShowModalReactivate}
                        highlightedCell={highlightedCell}
                        handleCopy={handleCopy}
                        formatAmount={formatAmount}
                        formatDate={formatDate}
                        loadingUserStatus={loadingUserStatus}

                    />
                )}

            </div>
            <NewUserModal
                show={showModalNewUser}
                handleClose={handleCloseModalNewUser}
            />
            <DeleteUserModal
                show={showModalDelete}
                handleClose={handleClose}
                selectedUser={selectedRecord.user_id || ""}
                amount={selectedRecord.amount || ""}
                sub_type={selectedRecord.interval || ""}
                plan={selectedRecord.plan || ""}
                userName={selectedRecord.userName || ""}
                userSurname={selectedRecord.userSurname || ""}
                email={selectedRecord.email || ""}
                phone={selectedRecord.phone || ""}

            />
            <ReactivateSubscriptionModal
                show={showModalReactivate}
                handleClose={handleCloseModalReactivate}
                selectedUser={selectedRecord.user_id || ""}
                amount={selectedRecord.amount || ""}
                sub_type={selectedRecord.interval || ""}
                plan={selectedRecord.plan || ""}
                userName={selectedRecord.userName || ""}
                userSurname={selectedRecord.userSurname || ""}
                email={selectedRecord.email || ""}
                phone={selectedRecord.phone || ""}

            />
            <CompletePaymentModal
                show={completePayment}
                handleClose={handleCloseCompletePayment}
                selectedUser={selectedRecord.user_id || ""}
                amount={selectedRecord.amount || ""}
                sub_type={selectedRecord.interval || ""}
                plan={selectedRecord.plan || ""}
                userName={selectedRecord.userName || ""}
                userSurname={selectedRecord.userSurname || ""}
                email={selectedRecord.email || ""}
                phone={selectedRecord.phone || ""}
            />

        </div>
    );
};

export default AgencyUsers;
