import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLanguage } from "../../../Translations/LenguageContext";
import Spinner from "../../../components/Spinner";

const stripePromise = loadStripe("pk_test_51QErkW4KWSVFZ8bvIIZiGKrOqWjmIZ2QIodxJb9tAdZlF7a7kqou51NgseW3w1ROQq1NACED5r7OBkhxSAhwt1kZ00FkMueeTY");

const PaymentFormStripe = ({ amount, sub_type, plan, user_id, userName, userSurname, email, phone, new_update }) => {
    const { t } = useLanguage()
    const [loading, setLoading] = useState(false)

    const getIntervalFromSubType = (subType) => {
        if (subType.endsWith("_month")) return "month";
        if (subType.endsWith("_year")) return "year";
        if (subType.endsWith("month")) return "year";
        if (subType.endsWith("year")) return "year";
        return null;
    };

    const interval = getIntervalFromSubType(sub_type);

    const handleCompletePayment = async () => {
        setLoading(true)
        const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/create_checkout_session", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                user_id: user_id,
                amount: amount,
                currency: "eur",
                interval: interval,
            }),
        });

        const sessionData = await response.json();
        if (sessionData.error) {
            setLoading(false)
            console.error("Backend error:", sessionData.error);
            alert("Backend error: " + sessionData.error);
            return;
        }

        const { sessionId } = sessionData;

        if (!sessionId) {
            setLoading(false)
            console.error("No sessionId returned from backend");
            alert("No sessionId returned from backend");
            return;
        }

        const stripe = await stripePromise;
        stripe.redirectToCheckout({ sessionId });
    };

    const formatAmount = (amount) => {
        const amountStr = amount.toString();
        const paddedAmount = amountStr.padStart(3, '0');
        return `${paddedAmount.slice(0, -2)},${paddedAmount.slice(-2)}`;
    };

    return (
        <div className="payment_container">
            <p className="payment_container_title">{t.agency.subscription_title} {plan}</p>

            <div className="payment_bill_cont">
                <label>{t.agency.agency_users_table.username}</label>
                <p>{userName} {userSurname}</p>
            </div>

            <div className="payment_bill_cont">
                <label>Email</label>
                <p>{email}</p>
            </div>

            <div className="payment_bill_cont">
                <label>{t.register_form.phone}</label>
                <p>{phone}</p>
            </div>

            <div className="payment_bill_cont total_final">
                <label>{t.agency.total}</label>
                <p>{formatAmount(amount)}€ / <span className="total_final_span">{t.agency[interval]}</span></p>
            </div>

            <button
                className="pay_now_btn"
                onClick={handleCompletePayment}
            >
                {loading ? (
                    <Spinner loading={true} err={{ message: "", display: false }} />
                ) : (
                    <>
                        {t.agency.complete_payment}
                    </>
                )}
            </button>
        </div>
    );
};

export default PaymentFormStripe;
