import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../Translations/LenguageContext";
import { BiBell } from "react-icons/bi";
import { BsGear } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import { Dropdown } from 'react-bootstrap';
import { HiLogout } from "react-icons/hi";
import Spinner from "../components/Spinner";

function NavBar({ onNavigate, userData }) {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [notificationLoading, setNotificationLoading] = useState(true);
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [visibleSubtitles, setVisibleSubtitles] = useState({});
    const [notificationToRead, setNotificationToRead] = useState(false)

    useEffect(() => {
        if (userData) {
            setLoading(false);
        }
    }, [userData]);

    const handleLogout = () => {
        setLoading(true);
        sessionStorage.removeItem("user_id");
        setTimeout(() => {
            navigate(`/login`);
        }, 700);
    };

    const toggleSwitch = () => {
        setIsSwitchOn(!isSwitchOn);
    };

    let isMounted = true;
    const fetchNotifications = async () => {
        setNotificationLoading(true)
        const user_id = sessionStorage.getItem("user_id");

        try {
            const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_notifications", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ user_id }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (isMounted) {
                setNotificationLoading(false)
                const notificationsArray = Object.entries(data.notifications).map(([id, notification]) => ({
                    id,
                    ...notification
                }));
                setNotifications(notificationsArray);
                const hasNotificationToRead = notificationsArray.some(notification => notification.status === "to_read");
                setNotificationToRead(hasNotificationToRead);
            }
        } catch (error) {
            setNotificationLoading(false)
            console.error("Error fetching notifications:", error);
        }
    };


    useEffect(() => {
        let isMounted = true;

        fetchNotifications();

        return () => {
            isMounted = false;
        };
    }, []);

    const Clear_notifications = async () => {
        const user_id = sessionStorage.getItem("user_id");

        try {
            const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/clear_notifications", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ user_id }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setNotificationToRead(false);
            fetchNotifications()
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };

    const formatNotificationDate = (timestamp) => {
        const notificationDate = new Date(timestamp);
        const today = new Date();
        const isToday =
            notificationDate.getDate() === today.getDate() &&
            notificationDate.getMonth() === today.getMonth() &&
            notificationDate.getFullYear() === today.getFullYear();

        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const dateString = notificationDate.toLocaleDateString('en-GB', options);
        const timeString = notificationDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

        return isToday ? timeString : dateString;
    };

    const toggleSubtitleVisibility = (id) => {
        setVisibleSubtitles((prev) => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    return (
        <div className="control_navbar">
            <div className="control_nav_cont">
                {/* Other controls */}
                <p className="sidebar_logo"><i className="fa-solid fa-layer-group"></i> Paypermate</p>
                {/* <span>Demo version</span> */}
            </div>
            <div className="control_nav_cont">
                <div className="icon_cont_nav">
                    <Dropdown>
                        <Dropdown.Toggle className="bell">
                            <BsGear />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="custom-dropdown-menu-settings">
                            <div className="settings_center">
                                <p className="settings_link" onClick={() => onNavigate('settings')}><i><BsGear /></i> {t.settings.settings_title}</p>
                                <p className="settings_link" onClick={() => onNavigate('profile')}><i><BiUser /></i> {t.profile.profile_title}</p>
                                <div className="settings_link last_link" onClick={handleLogout}>
                                    <p><i><HiLogout /></i> Logout</p>
                                </div>
                                {loading ? (
                                    <div className="settings_link_info">
                                        <Spinner loading={true} err={({ message: "", display: "none" })} />
                                    </div>
                                ) : (
                                    userData && (
                                        <div className="settings_link_info" onClick={() => onNavigate('profile')}>
                                            <p className="logout_from_account">{userData.name} {userData.surname}</p>
                                            <p className="logout_from_account">
                                                {userData.user_type.charAt(0).toUpperCase() + userData.user_type.slice(1)}
                                            </p>
                                        </div>
                                    )
                                )}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle className="bell">
                            <BiBell />
                            {notificationToRead && <div className="bell_to_read"></div>}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="custom-dropdown-menu-notification">
                            <div className="notifications_center">
                                <p className="notifications_title" onClick={fetchNotifications}>
                                    Notifiche
                                    <i className={`fa-solid fa-refresh ${notificationLoading ? "loading_notification" : ""}`}></i>
                                </p>
                                    {notifications.map(notification => (
                                        <div key={notification.id} className="notification">
                                            <div className="notification_only" onClick={() => toggleSubtitleVisibility(notification.id)}>
                                                {notification.status === "to_read" ? (<div className="to_read"></div>)
                                                    : (<i className={`chevron_notification fa-solid fa-chevron-${visibleSubtitles[notification.id] ? "up" : "down"}`}></i>)
                                                }
                                                <div className="notification_cont" >
                                                    <span className="notification_title">{notification.title}
                                                    </span>
                                                    {visibleSubtitles[notification.id] && (
                                                        <span className="notification_desc">{notification.subtitle}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <span className="notification_timestamp">{formatNotificationDate(notification.timestamp)}</span>
                                        </div>
                                    ))
                                }
                                {/* ) : (
                                    <p className="no_notifications">Non hai notifiche...</p>
                                )} */}
                                <div className="clear_notifications_cont">
                                    {notificationToRead && <p className="clear_notification" onClick={Clear_notifications}>Clear all notifications</p>}

                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}

export default NavBar;
