export const translations = {
    en: {
        brand_logo: "Paypermate",
        // main_title: "Streamline your Real Estate Business with",
        main_title: "Turn rent management into a stress-free experience.",
        // ai: " AI",
        main_description: "From Onboarding to Payments, all in one place.",
        property_management: "Property Management",
        tenant_oversight: "Tenant Oversight",
        secure_transactions: "Secure Transactions",
        instant_messaging: "Instant Messaging",
        email_notifications: "Email Notifications",
        calendar_events: "Calendar Events",
        ai_driven_analytics: "AI-Driven Analytics",
        waiting_list: "JOIN THE WAITLIST",
        start_now: "START NOW",
        login: "Login",
        edit: "Edit",
        delete: "Delete",
        yes: "Yes",
        no: "No",
        copy_clipboard: "Copy to clipboard",
        register_form: {
            name: "Name",
            surname: "Surname",
            phone: "Phone",
            repeat_password: "Repeat password",
            button_register: "Sign Up",
            already_registered: "Already registered?",
            go_login: "Login",
            email_format: "The email format is incorrect...",
            password_err: "Password must be at least 10 characters long and contain uppercase letters, lowercase letters, special characters, and numbers..",
            password_match: "Passowrds don't match...",
            red_fields: "Check the required fields...",
            email_already_present: "Email already present...",
            errors_back: "An error was detected...retry",
            problem_back: "There is a problem with the access...rety in a few minutes",
            terms_conditions_desc: "I have read and accept the",
            t_c: "Terms and conditions",
            privacy_policy_desc: "I have read and accept the",
            p_p: "Privacy Policy",
            investors_code: "Investors code"
        },
        login_form: {
            button_login: "Login",
            dont_have_account: "Don't have an account?",
            go_register: "Register",
            invalid_email_pass: "invalid email or password...retry"
        },
        onboarding: {
            title: "Master every stage of your journey",
            desc: "Seamlessly manage and oversee every aspect from start to finish.",
            titles: [
                "Onboarding",
                "AI Validation",
                "Document Filling",
                "Automatic Payments"
            ],
            descriptions: [
                "Onboarding ensures tenants get started fast with a seamless and hassle-free registration process.",
                "AI Validation ensures that all the data is accurate and meets all the standards.",
                "Document filling simplifies the process of completing and submitting necessary paperwork.",
                "Automatic payments make transactions easy and hassle-free with automatic billing."
            ],
            icons: [
                "FaRegUser",
                "IoSparkles",
                "FaFileAlt",
                "FaCreditCard"
            ]
        },
        property_marketing: {
            title: "Manage and Rent all your properties easily",
            subtitle: "Streamline tenant management and rent collection with ease for any property type.",
            titles: {
                house: "House",
                luxury_villa: "Pool Villa",
                rooms_beds: "Rooms or Beds",
                shop: "Shop or commercial activity",
                offices: "Offices",
                land: "Land"
            },
            descriptions_prop: {
                house_desc: "Easily manage and rent out entire houses with streamlined processes for finding tenants and collecting rent effortlessly.",
                luxury_villa_desc: "Offer your pool villa for rent with minimal hassle, ensuring a seamless experience for both you and your guests.",
                rooms_beds_desc: "Renting out rooms or beds is made simple, with user-friendly platforms to help you connect with potential tenants and collect payments easily.",
                shop_desc: "Renting out shops or commercial spaces is straightforward, with effective tools to advertise and collect rent efficiently.",
                offices_desc: "Lease modern office spaces effortlessly, providing businesses with a professional environment while simplifying rent collection.",
                land_desc: "Leverage your land for rental income with straightforward agreements and flexible options for various uses, making rent collection a breeze.",
            }
        },
        property_globe: {
            title: "Manage your properties from anywhere",
            desc: "Onboard tenants and manage payments blazingly fast within the Paypermate platform. Build a fully integrated website with seamless booking and payment systems, allowing your users to connect, book and pay directly on your site all in one place."
        },
        pricing: {
            title: "Prices designed for businesses of all sizes",
            subtitle: "Enjoy all the features out of the box with built-in fees for all your transactions.",
            sepa_title: "SEPA transactions",
            flat_fee: "Flat fee",
            less_sepa: "Less than 400€",
            more_sepa: "More than 400€",
            card_title: "Credit/Debit cards",
            prices_services: "Pricing and services",
            standard_functionallities: "Standard functionallities",
            platform_title: "Platform",
            dash: "Intuitive, clean dashboard",
            calendar: "Easy-to-use calendar for booking and scheduling",
            properties: "Seamless property creation and management",
            tenants: "Efficient tenant management with digital contract signing",
            contacts: "Contact management to keep track of every potential client",
            messages: "Instant messaging with your",
            ai_assistant: "AI assistant",
            quick: "for quick communication",
            wb_builder: "Website builder to promote your real estate business effortlessly",
            free: "FREE",
            finance_title: "Finance",
            accounting: "Accounting and Cash Flow",
            payments: "Payments - Manage and track incoming rent and other payments efficiently.",
            transaction: "Transactions - Monitor and log every financial transaction for complete visibility.",
            balance: "Balance - Keep an eye on your current cash flow and account balances at a glance.",
            report: "Report - Generate detailed financial reports to analyze performance and growth.",
            standard_fees: "Standard payment fees",
            analytics_title: "Machine Learning and AI for Real Estate",
            tenant_analysis: "Tenant analysis – Leverage AI to evaluate tenant behavior and preferences for better management.",
            market_pricing: "Market pricing – Utilize machine learning algorithms to determine optimal pricing strategies based on market trends.",
            expenses: "Market pricing – Utilize machine learning algorithms to determine optimal pricing strategies based on market trends."
        },
        now: "now",
        loading: "Loading...",
        error: "Something went wrong...",
        payment_process: {
            title: "Optimize and Automate payment processes",
            desc: "Ensure smooth and hassle-free payment transactions."
        },
        payment_notifications: {
            notification1: "Aisha Thompson paid her rent!",
            notification2: "John Doe paid his rent!",
            notification3: "Jack Miller paid his rent!",
        },
        secure_payments: {
            title: "Secure Payments",
            description: "Paypermate is securely backed by Stripe, ensuring safe and reliable transactions for all your rental payments."
        },
        manage_calendar: {
            title: "Long and short-term rentals",
            desc: "Effortlessly handle bookings and scheduling.",
            cal_desc_title: "Comprehensive Property Management Platform",
            cal_desc: "Efficiently manage both long-term and short-term rental bookings from a single platform. Whether your tenants are on vacation or residing in your city, streamline scheduling, management, and service delivery with ease."
        },
        transactions: {
            title: "What is it and how does it work?",
            subtitle: "Created to innovate the real estate business...",
            first_title: "Analysis and onboarding of potential tenants",
            first_desc: "Evaluate potential tenants even before showing the property, avoiding wasting time. Thanks to artificial intelligence, receive suggestions on which tenant best meets your selection criteria.",
            second_title: "Document exchange and validation",
            second_desc: "Manage documents, contracts, and signatures directly on Paypermate, simplifying the entire bureaucratic process on a single platform.",
            third_title: "One-time or recurring payments",
            third_desc: "Receive payments promptly via SEPA or credit/debit cards for short, medium, and long-term rentals. Monitor due dates, cash flows, and much more in an intuitive and detailed way.",

            phone: {
                tenant: "Tenant",
                property: "Property",
                payment: "Payment",
                find_tenant: "Find the perfect tenant...",
                combine: "...match them with a property...",
                recieve_payments: "...start receiving recurring payments every month!"
            },
        },
        payments: {
            pay_title: "Innovate your real estate business",
            pay_subtitle: "Change the game with instant and secure transactions.",
        },
        waitlist: {
            title: "Join the waitlist for Paypermate and get ready to be part of a revolution!",
            prop_owner: "Property Owner",
            re_agent: "Real Estate Agent",
            tenant: "Tenant",
            other: "Other",
            ten_1: "Difficulty finding available housing",
            ten_2: "Communication issues with the landlord",
            ten_3: "Inefficient maintenance",
            ten_4: "Unclear contracts",
            ten_5: "Unreturned deposits",
            owner_1: "Difficulty finding reliable tenants",
            owner_2: "Non-payment of rent",
            owner_3: "Damage caused by tenants",
            owner_4: "Managing properties remotely",
            owner_5: "Legal issues",
            owner_6: "Property maintenance",
            re_agent_1: "Complexity in managing documentation",
            re_agent_2: "Long timelines for rentals",
            re_agent_3: "Legal issues with involved parties",
            re_agent_4: "Ineffective communication",
            other_1: "Complexity in the rental process",
            other_2: "Lack of transparency about costs",
            other_3: "Confusing documentation",
            other_4: "Long timelines to resolve disputes",

            im_a: "I am a:",
            name_surname: "Name and Surname",
            problems: "What daily issues are you encountering?"
        },
        waitlist_partner: {
            congrats: "Congratulations!",
            thanks: "Thank you for joining the waitlist",
            free_title: "We're giving you Paypermate!",
            free_subtitle: "You heard that right. FREE FOR 1 MONTH!",
            explain_1: "We greatly appreciate you joining the waitlist and for that, we want you as an official partner...",
            explain_2: "...join the Payper-LAUNCH program and fully customize your Paypermate suite.",
            become_partner_btn: "BECOME A PARTNER",
            no_btn: "No, thanks"
        },
        check_email: {
            title: "Keep an eye on your email!",
            subtitle: "You can close this page or return to the"
        },
        footer: {
            about_us: "About Us",
            who: "Company",
            jobs: "Open positions",
            get_in_touch: "Get in touch",
            terms_conditions: "Terms & Conditions",
            legal_notes: "Legal notes",
            brand_rights: "© 2025 Paypermate",
            address: "Via EG 5, 20145, Milano MI",
            vat: "VAT: 123456789",
            payments_transactions: "Payments and Transactions",
            tenant_validation: "Tenant validations",
            ai_analytics: "AI Analytics",
            prices: "Prices"
        },
        tll: {
            question: "Are you the property owner or the person renting the space?",
            resp_t: "I'm renting the space",
            resp_pm: "I'm the property owner",
        },
        sidebar: {
            home: "Dashboard",
            bookings: "Calendar",
            contacts: "Contacts",
            agencies: "Agency",
            properties: "Properties",
            manage_properties: "Manage property",
            manage_agencies: "Manage agencies",
            manage_teams: "Teams",
            manage_users: "Users",
            manage_licence: "Billing and users",
            deals: "Deals",
            sales: "Sales",
            acquisitions: "Acquisitions",
            activity: "Activity",
            candidates: "Candidates",
            tenants: "Tenants",
            documents: "Documents",
            maintenance: "Maintenance",
            maintainers: "Maintainers",
            maint_request: "Requests",
            messages: "Messages",
            website_builder: "Website builder",
            transactions: "Transactions",
            balance: "Balance",
            report: "Report",
            tenant_analysis: "Tenant analysis",
            market_pricing: "Market pricing",
            expenses_forecast: "Expenses forecast",
            coming_soon_mex: "Coming up!",
            payments: "Payment account",
        },
        complete_account: {
            title: "Complete configuration",
            description: "Complete all necessary setup steps to finalize the configuration",
            completed_steps: "Completed steps",
            complete_btn: "Complete",
            not_now_btn: "Do it later",
            personal_info: "Personal information",
            business_info: "Business information",
            deposit_account: "Deposit account",
            birthday: "Birthday",
            sex: "Sex",
            country: "Country",
            city: "City",
            province: "Province",
            street: "Street",
            n: "No.",
            zip: "Zip",
            business_name: "Business Name",
            vat: "VAT",
            stripe_title: "Paypermate Partners with Stripe for Seamless Payments!",
            stripe_desc: "Effortlessly create and connect your Stripe account to start receiving payments without any hassle.",
            stripe_button: "Setup Stripe account",
            process_loading: "Processing...",
            sepa: "SEPA allows for automatic payments, like recurring rent or subscriptions, directly from a bank account. It simplifies cross-border transfers to be as fast, reliable, and cost-effective as local transactions, ensuring a secure and seamless experience for both businesses and customers.",
            card_payment: "Accepting credit and debit card payments ensures fast, reliable rent collection with instant transactions and seamless integration into your management system. It's ideal for landlords who prioritize speed and convenience.",
            sepa_fee_1: "€1.50 for payments under €400",
            sepa_fee_2: "€2.50 for payments under €400",
            card_fee_1: "2.5% + €0.25 for standard EU cards",
            card_fee_2: "2.9% + €0.25 for premium EU cards"
        },
        popup: {
            success: "Saved successfully",
            error: "An error occurred"
        },
        booking: {
            date_leng: "en-GB",
            no_bookings: "No bookings available...",
            appointments: "Appointments",
            date_order: "Start date can't be grater than End date...",
            add_booking: "Add booking",
            add_title: "Add title...",
            booking_type: "Booking type",
            date_time: "Date and hour",
            all_day: "All day",
            partecipants: "Partecipants",
            location: "Location",
            description: "Description",
            add_description: "Add a brief description...",
            short_term: "Short Term",
            long_term: "Long Term",
            personal: "Personal",
            meeting: "Meeting",
            visit: "Visit",
            second_visit: "Second visit",
            contract_sign: "Contract sign",
            work: "Work",
            other: "Other",
            save_btn: "Save booking",
            close_btn: "Close",
        },
        properties: {
            search_property: "Search for a property",
            my_properties: "My properties",
            no_property: "No properties available...",
            add_property: "Add property",
            apply_filters: "Apply filters",
            filter_btn: "Filter",
            title_main: "Title",
            title_prop: "Add a name",
            unit_type: "Unit type",
            nation: "Nation",
            city: "City",
            street_num: "Street and number",
            zip: "CAP",
            prop_type: "Property type",
            mt2: "Square meters - mt2",
            floor: "Floor",
            building_floors: "Total building floors",
            energy_class: "Energy class",
            exposure: "Exposure",
            heating: "Heating",
            external_fixtures: "External fixtures",
            prop_state: "Property state",
            construction_year: "Construction year",
            living: "Living space",
            bedroom: "Bedroom",
            kitchen: "Kitchen",
            bathroom: "Bathroom",
            other_included_details: "Other included details",
            price: "Price",
            property_rent: "Property monthly rent",
            currency: "Currency",
            expenses: "Expenses",
            deposit: "Deposit",
            commissions: "Commissions",
            comm_type: "Type",

            elevator: "Elevator",
            disabled_bathroom: "Disabled bathroom",
            full_furnishing: "Fully furnished",
            partial_furnishing: "Partially furnished",
            kitchen_furnishing: "Kitchen furnishing",
            communal_garden: "Communal garden",
            private_garden: "Private garden",
            sauna: "Sauna",
            disabled_elevator: "Disabled elevator",
            reception: "Reception",
            external_parking: "External parking",
            kitchen_island: "Kitchen island",
            pantry: "Pantry",
            washing_machine: "Washing machine",
            dryer: "Dryer",
            closets: "Closets",
            central_heating_ac: "Central heating/air conditioning",
            ceiling_fans: "Ceiling fans",
            fireplace: "Fireplace",
            skylights: "Skylights",
            thermostats: "Thermostats",
            security_systems: "Security systems",
            smart_lighting: "Smart lighting",
            garage: "Garage",
            driveway: "Driveway",
            terrace: "Terrace",
            balcony: "Balcony",
            patio: "Patio",
            swimming_pool: "Swimming pool",
            outdoor_kitchen: "Outdoor kitchen or BBQ area",
            fence: "Fence",
            landscaping: "Landscaping",
            clubhouse: "Clubhouse",
            fitness_center: "Fitness center",
            playground: "Playground",
            tennis_court: "Tennis court",
            basketball_court: "Basketball court",
            walking_trails: "Walking trails",
            gated_community: "Gated community",
            security_personnel: "Security personnel",
            furniture: "Furniture",
            water_heater: "Water heater",
            internet_cable_ready: "Internet/Wi-Fi",
            phone_lines: "Phone lines",
            pet_friendly_features: "Pet-friendly features",
            accessibility_features: "Accessibility features",
            solar_panels: "Solar panels",
            home_warranty: "Home warranty",


            save_btn: "Save property",
            close_btn: "Close",
            full_ownership: "Full ownership",
            bare_ownership: "Bare ownership",
            partial_ownership: "Partial ownership",
            usufruct: "Usufruct",
            timeshare: "Timeshare",
            right_superficies: "Right of superficies",
            pending_certification: "Pending certification",
            exempt_property: "Exempt property",
            not_classifiable: "Not classifiable",
            north: "North",
            south: "South",
            est: "Est",
            west: "West",
            autonomous: "Autonomus",
            centralized: "Centralized",
            absent: "Absent",
            glass_wood: "Glass - Wood",
            double_glass_wood: "Double glass - Wood",
            triple_glass_wood: "Triple glass - Wood",
            glass_metal: "Glass  - Metal",
            double_glass_metal: "Double glass - Metal",
            triple_glass_metal: "Triple glass - Metal",
            glass_pvc: "Glass - PVC",
            double_glass_pvc: "Double glass - PVC",
            triple_glass_pvc: "Triple glass - PVC",

            single_family: "Single-family home",
            multi_family: "Multi-family home",
            apartment: "Apartment",
            condominium: "Condominium",
            townhouse: "Townhouse",
            cooperative: "Cooperative",
            vacation_home: "Vacation home",
            mobile_home: "Mobile home",
            tiny_home: "Tiny home",
            office_building: "Office building",
            retail_property: "Retail property",
            industrial_property: "Industrial property",
            mixed_use: "Mixed-use property",
            hotel: "Hotel",
            vacant_land: "Vacant land",
            agricultural_land: "Agricultural land",
            commercial_land: "Commercial land",
            residential_land: "Residential land",
            warehouse: "Warehouse",
            parking_lot: "Parking lot",
            storage_facility: "Storage facility",
            healthcare_facility: "Healthcare facility",
            educational_facility: "Educational facility",
            religious_facility: "Religious facility",
            deposit_storage_area: "Deposit/storage area",
            garage: "Garage",
            marina: "Marina",
            resort: "Resort",
            ranch: "Ranch",
            room: "Room",

            new: "New",
            like_new: "Like new",
            excellent_condition: "Excellent condition",
            good_condition: "Good condition",
            fair_condition: "Fair condition",
            needs_renovation: "Needs renovation",
            to_renovate: "To renovate",
            under_construction: "Under construction",
            partially_renovated: "Partially renovated",
            unfinished: "Unfinished",
            move_in_ready: "Move-in ready",

            buy_rent: "Buy or rent?",
            sell_rent: "Sell or rent?",
            rent: "Renting",
            selling: "Selling",
            buying: "Buying",

            available_notAvailable: "Available/Not Available",
            available_rent: "Available for rent",
            rented: "Rented",
            available_buy: "Available to buy",
            sold: "Sold"

        },
        agency: {
            title: "Agencies and locations",
            add_agency: "New agency",
            search_agency: "Search agency",

            //New agency
            new_agency_title: "New agency",
            base_info: "Basic information",
            owner: "Owners",
            members: "Members",
            end: "Finish",
            finalize: "Finalize",
            main_new_element_title: "Configure basic settings",
            main_new_element_desc: "An agency can be a physical or digital structure dedicated to managing teams, users, performance, and many other activities.",
            agency_name: "Agency Name",
            description: "Description",
            agency_type: "Agency type",
            physical_agency: "Physical Agency",
            remote_agency: "Digital/Remote Agency",
            prev: "Back",
            next: "Next",
            new_owner_title: "New owner",
            new_owner_subtitle: "The owners can modify and manage the agency",
            select_owner: "Select owners",
            owners: "Owners",
            you: "You",
            new_members_title: "Agency members",
            new_members_subtitle: "Add the members of the agency",
            select_members: "Select members",
            members: "Members",
            finalize_agency_title: "Finalize agency",
            finalize_agency_subtitle: "Review and finalize your agency settings",


            //New user
            title_user: "Users",
            search_user: "Find user",
            new_user: "New user",
            licence: "Licence",
            licence_desc: "Assign the license you want to this user.",
            description: "Description",
            user_name: "Name",
            user_surname: "Surname",
            gen_pass: "Generate password automatically",
            passw_desc: "The password must be between 8 and 256 characters long and use a combination of at least three of the following character types: uppercase letters, lowercase letters, numbers, and symbols.",
            pass_change: "Ask the user to change their password on the first login.",
            year: "Year",
            month: "Month",
            free_month: "(1 month for free)",

            payment_title: "Payment",
            payment_subtitle: "Verify the data and complete the payment",
            subscription_title: "Subscription for ",
            total: "Total",
            interval: "Subscribtion interval",
            complete_payment: "COMPLETE PAYMENT",
            paid: "Paid",
            payment_loading: "Loading",
            active: "Active",
            error: "Awaiting payment",
            not_available: "Not available",
            incomplete: "Incomplete",
            suspended: "Suspended",
            delete_subscription: "Delete subscription",
            cancel_subscription: "Cancel subscription",
            activate_subscription: "Reactivate subscription",

            licence_types: {
                basic: "Basic use of the software (Dashboard, Calendar, Contacts, Personal Portfolio, Personal Reports).",
                business_standard: "All the basic functions and also create and manage Properties, Agencies, Tenants, Maintenance, Finance, and Reports.",
                pro: "All the basic and business standard functions plus Website Builder and Marketing Tools (Email Marketing, Ads, etc.)."
            },

            agency_users_table: {
                user_id: "User ID",
                username: "Username",
                lcense: "License",
                subscription_id: "Subscription ID",
                price: "Price",
                interval: "Interval",
                created_at: "Created at",
                pay_status: "Payment status",
                sub_status: "Subscription status",
                account_status: "Account status"
            },

            user_account: {
                active: "Active",
                pending: "Pending",
                to_be_suspended: "To be suspended",
                error: "No active",
            },

            modal: {
                title: "Save the latest changes?",
                save_btn: "Save Changes",
                dont_save_btn: "Discard Changes",
                title_delete: "Remove user from the organization?",
                action_title: "This action can be undone. <ul> <li>Future payments will be canceled.</li> <li>All data related to this user will be blocked.</li> <li>Users portfolio will be frozen.</li></ul>",
                type_delete: "Type the name of the user to remove it permanently.",
                delete_btn: "Remove permanently",
                back_btn: "Back",
            },

            create_users_modal: {
                title: "User creation mode",
                manual: "Immediate Manual",
                hint_manual: "Recommended for adding a few users",
                massive: "Massive",
                hint_massive: "Recommended for larger organizations"
            },

            modal_reactivate: {
                title: "Save the latest changes?",
                save_btn: "Save Changes",
                dont_save_btn: "Discard Changes",
                title_reactivate: "Reactivate the selected account?",
                action_title: "This action can be undone anytime. <ul> <li>Future payments will be restored.</li> <li>Users portfolio  will be unfrozen.</li></ul>",
                restore_btn: "Reactivate account",
                back_btn: "Back",
            },

            messages: {
                title: "Chat with the organization",
                subtitle: "Send messages e release comunications to your organization",
                first_mex: "Send the first message to the organization",
                placeholder: "Write something..."
            },

            resources: {
                title: "Files and resources",
                subtitle: "Share files and useful resources with the organization",
                no_shared: "Share the first file...",
                delete: "Delete",
            },

            modal_delete_resource: {
                title: "Delete file from the shared point?",
                action_title: "This action <strong>CANNOT</strong> be undone. <ul> <li>The file will be removed permanently.</li></ul>",
                type_delete: "Type the name of the file to delete it permanently.",
                delete_btn: "Delete permanently",
                back_btn: "Back",
            },

            members_owners: {
                title: "Owners and members",
                subtitle: "All the partecipants of this organization",
                add_title: "Add partecipants in your organization",
                add_btn: "Add partecipants",
            }

        },
        new_property: {
            update_prop: "Edit Property",
            new_prop: "New Property",
            verify_save_btn: "Verify and Save",
            err_fields: "Check the required fields...",
            ai: {
                analysis: "Analysis in progress...",
                use_this_desc: "Use this description",
                desc_prev: "Stunning apartment in the heart of...",
                placeholder_ai: "Description of a charming one-bedroom in the center..."
            },
            font: {
                system: "System",
            },
            name: "Name",
            country: "Country",
            city: "City",
            ZIP: "ZIP Code",
            street: "Street",
            street_num: "No.",
            floor: "Floor",
            mq: "Square Meters",
            exposure: "Exposure",
            energy_class: "Energy Class",
            heating: "Heating",
            windows: "Windows",
            conditions: "Property Condition",
            year_built: "Year Built",
            drag_img_text: "Drag an image or upload it from your device by clicking",
            here: "here",
            sell_rent: "Rent or Sale?",
            rent: "Rent",
            sell: "Sale",
            people: "How many people can cohabitate?",
            adults: "Adults",
            children: "Children",
            available_date: "Availability Date",
            immediate: "Immediate",
            programmed: "Scheduled",
            request_amount: "Requested Amount",
            rent_amount: "Rent",
            condominium_fees: "Condominium Fees",
            security_deposit: "Security Deposit",
            sell_amount: "Sale Amount",
            spaces: "Spaces",
            living_space: "Living Space",
            bedrooms: "Bedrooms",
            kitchens: "Kitchen",
            bathrooms: "Bathrooms",
            included_comforts: "Included Comforts",
            pet_allowed: "Are pets allowed?",
            smoker_allowed: "Are smokers allowed?",
            placeholders: {
                name: "Stunning house...",
                country: "Australia",
                city: "Sydney",
                street: "Kangaroo street...",
                street_num: "No.",
                north: "South",
                centralized: "Centralized",
                glass_pvc: "Glass PVC",
                new_prop: "New"
            },
            unit_types: {
                villa: "Villa",
                house: "House",
                appartment: "Apartment",
                room: "Room",
                business: "Business",
                land: "Land",
                other: "Other"
            },
            modal: {
                title: "Save the latest changes?",
                save_btn: "Save Changes",
                dont_save_btn: "Discard Changes",
                title_delete: "Delete the selected property?",
                action_title: "This action <strong>CANNOT</strong> be undone. <ul> <li>Future payments will be canceled.</li> <li>All data related to this property will be deleted.</li> <li>Listings for this property will be removed from all channels.</li></ul>",
                type_delete: "Type the name of the property to delete it permanently.",
                delete_btn: "Permanently Delete",
                back_btn: "Back",
            }
        },
        contacts: {
            my_contacts: "My contacts",
            no_contacts: "No contacts available...",
            add_contact: "Add contact",
            save_contact: "Save contact",
            update_contact: "Update contact",
            save_contact_changes: "Save changes",
            confirmation_delete: "Delete the selected contact?",
            why_look_prop: "Why are you looking for a property?",
            transfer_date: "When would you like to move in?",
            max_bgt: "Maximum budget",
            min_bgt: "Minimum budget",
            mortage: "Do you need a mortage?",
            sell_home: "Are you going to sell a property?",
            first_house: "Is this your first house?",
            notes: "Notes",

        },
        tenants: {
            tenants: "Tenants",
            new_tenant_title: "New tenant",
            update_tenant_title: "Update tenant",
            available_prop_rent_title: "Available properties for rent",
            available_prop_rent_desc: "Choose between the available properties",
            personalized: "Personalized",
            monthly: "Monthly",
            payment_meth_month_label: "How would you prefer to receive payments?",
            sepa_payment: "Monthly automatic debit via SEPA",
            card_payment: "Monthly payment via credit/debit card",
            available_properties_title: "Available properties",
            manage_properties: "Manage properties",
            monthly_ammount: "Monthly ammount",
            yearly_cashflow: "Yearly cashflow",
            personalized_payments_title: "Personalized payments",
            personalized_payments_desc: "Select the booking dates",
            from: "From",
            to: "To",
            payment_fractioning: "Payment fractioning",
            selected_period: "Selected period",
            daily: "Daily",
            weekly: "Weekly",
            total: "Total",
            tenant_personal_info_title: "Personal or Business information",
            tenant_personal_info_desc: "Insert the tenants personal or business information and contacts",
            tenant_account_title: "Tenant Paypermate account setup",
            tenant_account_desc: "Automatically enable a Paypermate account for your tenant or send the pairing code.",
            back: "Back",
            next: "Next",
            individual: "Individual",
            business: "Business",
            search_contacts: "Search contacts",
            save_btn: "Save",
            document_request_title: "Document and contracts",
            document_request_desc: "Upload contracts and request tenant documents",
            guarantor: "Request a guarantor for this tenant?",
            complete_reg: "Complete registration",
        },
        profile: {
            profile_title: "Profile",
            access: "Access",
            main_credentials_subtitle: "The main credentials to access your environment",
            pb_information: "Personal & Business information",
            pb_subtitle: "All the information regarding your persona and your business",
            uid_title: "Your user ID",
            uid_subtitle: "Personal user ID code",
            activity_log: "Activity log",
            activity_log_subtitle: "Your recent activity log",
            no_activity: "No recent activity found",
            update_btn: "Update",
        },
        settings: {
            settings_title: "Settings"
        },
        months: {
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December",
        },
        flatjourney_pm: {
            title: "The only platform where you can scale your",
            re_title: "Real Estate",
            business_title: "business",
            subtitle: "With Paypermate, you can decide how to profit from your properties, while we handle the rest.",
            start_btn: "TRY NOW",

            listing_setup_title: "We prepare your place",
            listing_setup_subtitle: "Take photos, renovate, find prices and create leads",
            connect_title: "We. Connect. People. Globally.",
            deposit_one: "We have deposited ",
            deposit_two: "in your bank account.",

            boost: {
                title: "Increase monthly revenue",
                subtitle: "With Paypermate-Boost, enjoy steady monthly income while increasing your earnings up to 3x with flexible rental periods.",
            },

            stable: {
                title: "Increase long-term revenue",
                subtitle: "With Paypermate-Stable, secure long-term tenants and grow your income by up to 2x, with minimal effort and guaranteed payments.",
            },

            fixed: {
                title: "Ensure immediate liquidity",
                subtitle: "With Paypermate-Fixed, receive upfront rental payments for a minimum of 6 months, ensuring guaranteed income and liquidity, with zero management hassle.",
            },

            how_does_it_work: "How does it work?",
            step_1: {
                title: "Register your place in just a few clicks",
                subtitle: "Insert the necessary information of the property such as location, rooms etc..."
            },

            step_2: {
                title: "Decide how you want to earn",
                subtitle: "Choose between the different property managment types (PMT)."
            },

            step_3: {
                title: "We take care of the rest",
                subtitle: "Lean back and let us do the rest for you."
            },

            qa: {
                title: "Do you have more questions?",
                faq_title: "Frequently asked questions",
                faq: {
                    title: "Why choose Paypermate?",
                    answer: "Paypermate is a truly AI-driven PropTech company. Using data and advanced machine learning models, we analyze rental markets and platform performance to help maximize your property’s ROI.",
                    title_1: "How do i manage the bookings?",
                    answer_1: "During the onboarding process, you’ll receive free software that lets you easily manage bookings. All you need to do is welcome the tenants—leave the rest to us, including finding the right people for you!",
                    title_2: "What are the Paypermate fees?",
                    answer_2: "Fees depend on the Paypermate PMT (Property Managment Type) you choose. You can explore all the EBs by here."
                },
                step_by_step_title: "Step by step guide",
                step_by_step: {
                    title: "How do I get started?",
                    answer: "Register your property in just a few clicks through the Start Now page. Select your preferred property managment type (PMT), and sit back—we’ll take care of the rest.",
                    title_1: "What happens next?",
                    answer_1: "After registering, we’ll schedule a meeting to inspect your property, take professional photos, make upgrades if necessary, and use our AI tools to list it effectively.",
                    title_2: "What do I need to do after that?",
                    answer_2: "You are ready to start maximizing the ROI on your property!"
                },

                pr_title: "Policies and Regulations",
                pr: {
                    title: "Can I terminate the contract?",
                    answer: "Yes, you can. Simply contact us, and we will promptly handle the termination process.",
                    title_1: "Privacy Policies",
                    answer_1: " You can read our Privacy Policy by clicking here",
                },
                payment_title: "Payment Methods",
                payment: {
                    title: "How do I receive my payments?",
                    answer: " You can choose your preferred payment schedule at the start— weekly, or monthly. We ensure a seamless payout experience.",
                },

                more_questions: "If you have more questions...",
                ask_btn: "Ask us anything",
                ask_save_btn: "Send your question",
                ask_title: "Your question",
            },

            comm: "Our Network is known for high ratings, minimal insolvency rates, and extensive payment experience. Tenants reviews form the basis of our ratings, ensuring quality and reliability for all listings. Powered by Paypermate SRL, an innovative startup, our services are currently available in select locations. Discover more about what we offer."

        },

        pm_steps: {
            continue: "Continue",
            back: "Back",
            title_first: "Scale. <br /> Maximize.<br /> Repeat.<br />",
            title_1: "Introduce Yourself",
            subtitle_1: "We'd love to know more about you and how you came across us.",
            title_2: "Property Overview",
            subtitle_2: "Provide key details about the property, like its location, features, and more.",
            title_3: "Get Started",
            subtitle_3: "Select your preferred property managment type (PMT) and leen back.",

            title_second: "Let's start...",
            name: "Name",
            surname: "Surname",
            phone: "Phone",
            job: "Current position",
            employee: "Employee",
            freelancer: "Freelancer",
            entrepreneur: "Entrepreneur",
            project_based_collaborator: "Project-Based Collaborator",
            apprentice: "Apprentice",
            eb_choise_title: "Choose how to manage your property",

            how_about_us: "How did you came across us?",
            word_of_mouth: "Word of Mouth",
            social_media: "Social Media",
            search_engine: "Search Engine (Google, Bing, etc.)",
            email: "Email",
            flyer_or_poster: "Flyer or Poster",
            other: "Other",

            title_third: "What about <br/> your properties?",
            title_prop: "How many properties do you have?",
            i_have: "I have",
            property: "property",
            properties: "properties",
            location: "Location",
            rooms: "Rooms",

            boost: {
                desc: "Tailored property management for short/medium-term rentals. From property upgrades and tenant managment to marketing, professional photos, tenant onboarding, and managing documents, we take care of it all for your real estate business."
            },
            stable: {
                desc: "Long-term property management for rentals lasting six months or more. We offer full support for tenants and property owners, ensuring smooth, hassle-free rentals for extended periods."
            },
            fixed: {
                desc: "Get 6 months of rent upfront in a single payment for immediate liquidity."
            },

            finsh_pm_reg_title: "Check your email!"

        },
        ROI: {
            title: "Maximizing property roi",
            subtitle: "Choose which property management type you prefer",
            services: "Included services",
            requisites: "Requirements",
            price: "Price",
        
            tenant_screen: "Tenant validation",
            onboarding: "Streamlined onboarding process",
            burocracy: "Complete bureaucratic management",
            support: "24/7 customer support",
            software: "Dedicated software",
            payments: "Recurring payment automation",
            bookings: "Efficient booking management",
            tenant_support: "Comprehensive tenant management support",
            marketing: "Advanced market positioning strategies",
            insurance: "Property insurance coverage",
            liquidity: "Immediate access to required liquidity",
        
            req1: "Property ownership or sublease agreement",
            req2: "Issuance of a sublease contract to Paypermate",
        
            price1: "€25 per month",
            price2: "3% of generated revenue",
            price3: "No cost"
        }              
    },
    it: {
        brand_logo: "Paypermate",
        // main_title: "Semplifica la tua attività immobiliare con l'",
        main_title: "Trasforma la gestione dell’affitto in un’esperienza senza pensieri.",
        // ai: "IA",
        main_description: "Dall'onboarding ai pagamenti, tutto in un unico posto.",
        property_management: "Gestione proprietà",
        tenant_oversight: "Supervisione inquilini",
        secure_transactions: "Transazioni sicure",
        instant_messaging: "Messaggi istantanei",
        email_notifications: "Notifiche email",
        calendar_events: "Eventi del calendario",
        ai_driven_analytics: "Analisi AI",
        waiting_list: "ENTRA IN LISTA D'ATTESA",
        start_now: "INIZIA ORA",
        login: "Accedi",
        edit: "Modifica",
        delete: "Elimina",
        yes: "Sì",
        no: "No",
        copy_clipboard: "Copia negli appunti",
        register_form: {
            name: "Nome",
            surname: "Cognome",
            phone: "Telefono",
            repeat_password: "Ripeti password",
            button_register: "Registrati",
            already_registered: "Già registrato?",
            go_login: "Accedi",
            email_format: "Il formato dell'email è errato...",
            password_err: "La password deve essere lunga almeno 10 caratteri e contenere lettere maiuscole, lettere minuscole, caratteri speciali e numeri...",
            password_match: "Le password non corrispondono...",
            red_fields: "Controlla i campi rossi...",
            email_already_present: "Email già presente...",
            errors_back: "È stato rilevato un errore...riprovare",
            problem_back: "C'è un problema con l'accesso...riprovare tra qualche minuto",
            terms_conditions_desc: "Ho letto e accetto i",
            t_c: "Termini e condizioni",
            privacy_policy_desc: "Ho letto e accetto la",
            p_p: "Privacy policy",
            investors_code: "Codice investitore"
        },
        login_form: {
            button_login: "Accedi",
            dont_have_account: "Non hai ancora un account?",
            go_register: "Registrati",
            invalid_email_pass: "Email o password non validi...riprovare"
        },
        onboarding: {
            title: "Facile in ogni fase del percorso",
            desc: "Gestisci e controlla senza sforzo ogni aspetto, dall'inizio alla fine.",
            titles: [
                "Onboarding",
                "Validazione IA",
                "Compilazione Documenti",
                "Pagamenti Automatici"
            ],
            descriptions: [
                "Processo onboarding garantisce ai candidati un inizio rapido con una registrazione semplice e senza intoppi.",
                "Validazione IA garantisce che tutti i dati siano accurati e soddisfino i tutti gli standard.",
                "Riempimento documenti semplifica il processo di completamento e invio della documentazione necessaria.",
                "Pagamenti automatici rendono le transazioni facili e senza problemi con la fatturazione automatica."
            ],
            icons: [
                "FaRegUser",
                "IoSparkles",
                "FaFileAlt",
                "FaCreditCard"
            ]
        },
        property_marketing: {
            title: "Gestisci e affitta facilmente tutte le tue proprietà",
            subtitle: "Semplifica la gestione degli inquilini e la riscossione degli affitti per qualsiasi tipo di proprietà.",
            titles: {
                house: "Casa",
                luxury_villa: "Villa con Piscina",
                rooms_beds: "Camere o Letti",
                shop: "Negozio o Attività Commerciale",
                offices: "Uffici",
                land: "Terreno"
            },
            descriptions_prop: {
                house_desc: "Gestisci e affitta facilmente intere case con processi semplificati per trovare inquilini e riscuotere gli affitti senza sforzo.",
                luxury_villa_desc: "Affitta la tua villa con piscina senza problemi, garantendo un'esperienza fluida sia per te che per i tuoi ospiti.",
                rooms_beds_desc: "Affittare camere o letti è semplice, con piattaforme intuitive che ti aiutano a connetterti con potenziali inquilini e a riscuotere i pagamenti facilmente.",
                shop_desc: "Affittare negozi o spazi commerciali è semplice, con strumenti efficaci per pubblicizzare e riscuotere gli affitti in modo efficiente.",
                offices_desc: "Affitta moderni spazi ufficio senza complicazioni, offrendo alle imprese un ambiente professionale e semplificando la riscossione degli affitti.",
                land_desc: "Sfrutta il tuo terreno per generare reddito da affitto con accordi semplici e opzioni flessibili per vari utilizzi, rendendo la riscossione degli affitti un gioco da ragazzi."
            }
        },
        property_globe: {
            title: "Gestisci le tue proprietà da qualsiasi luogo",
            desc: "Onboarding di inquilini e gestione dei pagamenti super veloci sulla piattaforma Paypermate. Crea un sito web completamente integrato con sistemi di prenotazione e pagamento che consente ai tuoi utenti di connettersi, prenotare e pagare direttamente sul tuo sito."
        },
        pricing: {
            title: "Tariffe pensate per attività di tutte le dimensioni",
            subtitle: "Usufruisci di tutte le funzionalità predefinite con le tariffe integrate per tutte le tue transazioni.",
            sepa_title: "Transazioni SEPA",
            flat_fee: "Tariffa fissa",
            less_sepa: "Meno di 400€",
            more_sepa: "Più di 400€",
            card_title: "Carte di credito/debito",
            prices_services: "Prezzi e servizi",
            standard_functionallities: "Funzionalità standard",
            platform_title: "Piattaforma",
            dash: "Dashboard intuitiva e pulita",
            calendar: "Calendario facile da usare per prenotazioni e programmazioni",
            properties: "Creazione e gestione delle proprietà senza soluzione di continuità",
            tenants: "Gestione efficiente degli inquilini con firma digitale del contratto",
            contacts: "Gestione dei contatti per tenere traccia di ogni potenziale cliente",
            messages: "Messaggistica istantanea con il tuo",
            ai_assistant: "assistente AI",
            quick: "per una comunicazione rapida",
            wb_builder: "Costruttore di siti web per promuovere facilmente la tua attività immobiliare",
            free: "GRATIS",
            finance_title: "Contabilità",
            accounting: "Contabilità e flusso di cassa",
            payments: "Pagamenti - Gestisci e monitora l'affitto e altri pagamenti in modo efficiente.",
            transaction: "Transazioni - Monitora e registra ogni transazione finanziaria per una visibilità completa.",
            balance: "Saldo - Tieni d'occhio il tuo attuale flusso di cassa e i saldi dei conti.",
            report: "Report - Genera report finanziari dettagliati per analizzare le performance e la crescita.",
            standard_fees: "Commissioni standard sui pagamenti",
            analytics_title: "Machine Learning e AI per il settore immobiliare",
            tenant_analysis: "Analisi degli inquilini – Sfrutta l'AI per valutare il comportamento e le preferenze degli inquilini per una migliore gestione.",
            market_pricing: "Prezzi di mercato – Utilizza algoritmi di machine learning per determinare strategie di prezzo ottimali basate sulle tendenze di mercato.",
            expenses: "Spese – Utilizza algoritmi di machine learning per determinare strategie di prezzo ottimali basate sulle tendenze di mercato."
        },
        now: "ora",
        loading: "Caricamento...",
        error: "Qualcosa è andato storto...",
        payment_process: {
            title: "Ottimizza e automatizza i processi di pagamento",
            desc: "Assicura transazioni di pagamento fluide e senza intoppi."
        },
        payment_notifications: {
            notification1: "Aisha Thompson ha pagato l'affitto!",
            notification2: "John Doe ha pagato l'affitto!",
            notification3: "Jack Miller ha pagato l'affitto!"
        },
        secure_payments: {
            title: "Pagamenti Sicuri",
            description: "Le transazioni su Paypermate sono gestite in modo sicuro e affidabile da Stripe, garantendo il massimo della protezione per i tuoi pagamenti.",
        },
        manage_calendar: {
            title: "Programma Affitti a breve e lungo termine",
            desc: "Gestisci prenotazioni e programmazioni con facilità.",
            cal_desc_title: "Piattaforma completa per la gestione delle proprietà",
            cal_desc: "Gestisci in modo efficiente sia le prenotazioni a lungo termine che quelle a breve termine da un'unica piattaforma. Che i tuoi inquilini siano in vacanza o risiedano nella tua città, semplifica la programmazione, la gestione e la consegna dei servizi con facilità."
        },
        transactions: {
            title: "Cos'è e come funziona?",
            subtitle: "Creato per innovare il settore immobiliare...",
            first_title: "Analisi e onboarding dei potenziali inquilini",
            first_desc: "Valuta i potenziali inquilini prima ancora di mostrare l'immobile, evitando perdite di tempo. Grazie all'intelligenza artificiale, ricevi suggerimenti su quale inquilino soddisfa meglio i tuoi criteri di selezione.",
            second_title: "Scambio e validazione dei documenti",
            second_desc: "Gestisci documenti, contratti e firme direttamente su Paypermate, semplificando tutto il processo burocratico in un'unica piattaforma.",
            third_title: "Pagamenti singoli o ricorrenti ",
            third_desc: "Ricevi puntualmente pagamenti tramite SEPA o carte di credito/debito per affitti a breve, medio e lungo termine. Monitora scadenze, flussi di cassa e molto altro in modo intuitivo e dettagliato.",

            phone: {
                tenant: "Inquilino",
                property: "Proprietà",
                payment: "Pagamento",
                find_tenant: "Trova l'inquilino perfetto...",
                combine: "...abbinalo a una proprietà...",
                recieve_payments: "...inizia a ricevere i pagamenti ricorrenti ogni mese!"

            },
        },
        payments: {
            pay_title: "Innova la tua impresa immobiliare",
            pay_subtitle: "Cambia le regole del gioco con transazioni istantanee, sicure e puntuali.",
        },
        waitlist: {
            title: "Unisciti alla lista d'attesa per Paypermate e preparati a essere parte di una rivoluzione!",
            prop_owner: "Proprietario immobiliare",
            re_agent: "Agente immobiliare",
            tenant: "Inquilino",
            other: "Altro",
            ten_1: "Difficoltà a trovare una casa disponibile",
            ten_2: "Problemi di comunicazione con il proprietario",
            ten_3: "Manutenzione inefficiente",
            ten_4: "Contratti poco chiari",
            ten_5: "Cauzioni non rimborsate",
            owner_1: "Difficoltà a trovare inquilini affidabili",
            owner_2: "Mancato pagamento dell'affitto",
            owner_3: "Danni causati dagli inquilini",
            owner_4: "Gestione delle proprietà a distanza",
            owner_5: "Problematiche legali",
            owner_6: "Manutenzione dell'immobile",
            re_agent_1: "Complessità nella gestione della documentazione",
            re_agent_2: "Lunghe tempistiche per affitti",
            re_agent_3: "Problematiche legali con le parti coinvolte",
            re_agent_4: "Comunicazione inefficace",
            other_1: "Complessità nel processo di affitto",
            other_2: "Poca trasparenza sui costi",
            other_3: "Documentazione confusa",
            other_4: "Tempistiche lunghe per risolvere controversie",

            im_a: "Sono un:",
            name_surname: "Nome e Cognome",
            problems: "Che problematiche quotidiane stai riscontrando?"
        },
        waitlist_partner: {
            congrats: "Congratulazioni!",
            thanks: "Grazie per essere entrato in lista d'attesa",
            free_title: "Ti regaliamo Paypermate!",
            free_subtitle: "Hai capito bene. GRATIS PER 1 MESE!.",
            explain_1: "Ti ringraziamo molto per essere entrato in lista d'attesa e per questo vogliamo averti come partner ufficiale...",
            explain_2: "...partecipa al programma Payper-LAUNCH e personalizza al massimo la tua suite Paypermate.",
            become_partner_btn: "DIVENTA PARTNER",
            no_btn: "No, grazie"
        },
        check_email: {
            title: "Tieni d'occhio la tua email!",
            subtitle: "Puoi chiudere questa pagina o tornare nella"
        },
        footer: {
            about_us: "Chi siamo",
            who: "Azienda",
            jobs: "Lavora con noi",
            get_in_touch: "Contatti",
            terms_conditions: "Termini & Condizioni",
            legal_notes: "Note legali",
            brand_rights: "© 2025 Paypermate",
            address: "Via EG 5, 20145, Milano MI",
            vat: "VAT: 123456789",
            payments_transactions: "Pagamenti e Transazioni",
            tenant_validation: "Validazione inquilini",
            ai_analytics: "Analisi IA",
            prices: "Prezzi"
        },
        tll: {
            question: "Sei il proprietario dell'immobile o la persona che affitta lo spazio?",
            resp_t: "Sto affittando lo spazio",
            resp_pm: "Sono il proprietario",
        },
        sidebar: {
            home: "Dashboard",
            bookings: "Calendario",
            contacts: "Contatti",
            properties: "Proprietà",
            agencies: "Agenzia",
            manage_properties: "Gestisci proprietà",
            manage_teams: "Teams",
            manage_users: "Utenti",
            manage_licence: "Fatturazione e utenze",
            manage_agencies: "Agenzie e sedi",
            deals: "Deals",
            sales: "Vendite",
            acquisitions: "Acquisizioni",
            activity: "Attività",
            candidates: "Candidati",
            tenants: "Inquilini",
            documents: "Documenti",
            maintenance: "Manutenzione",
            maintainers: "Manutentori",
            maint_request: "Richieste",
            messages: "Messaggi",
            website_builder: "Costruttore di siti web",
            transactions: "Transazioni",
            balance: "Saldo",
            report: "Rapporto",
            tenant_analysis: "Analisi inquilini",
            market_pricing: "Prezzi di mercato",
            expenses_forecast: "Previsione spese",
            coming_soon_mex: "Prossimamente!",
            payments: "Conto pagamenti"
        },
        complete_account: {
            title: "Completa la configurazione",
            description: "Completa tutti i passaggi necessari per finalizzare la configurazione",
            completed_steps: "Passaggi completati",
            complete_btn: "Completa",
            not_now_btn: "Non ora",
            personal_info: "Informazioni personali",
            business_info: "Informazioni aziendali",
            deposit_account: "Conto deposito",
            birthday: "Compleanno",
            sex: "Sesso",
            country: "Nazione",
            city: "Città",
            province: "Provincia",
            street: "Strada",
            n: "N.",
            zip: "CAP",
            business_name: "Ragione Sociale",
            vat: "P.IVA",
            stripe_title: "Paypermate collabora con Stripe per pagamenti senza interruzioni!",
            stripe_desc: "Crea e collega facilmente il tuo account Stripe per iniziare a ricevere pagamenti senza alcuna difficoltà.",
            stripe_button: "Configura account Stripe",
            process_loading: "Caricamento...",
            sepa: "SEPA consente pagamenti automatici, come affitti mensili e ricorrenti, direttamente dal conto bancario. Semplifica i trasferimenti, rendendoli veloci, affidabili e convenienti, garantendo un'esperienza sicura e senza problemi sia per le aziende che per i clienti.",
            card_payment: "Accettare pagamenti con carta di credito e debito garantisce l'accredito dell'affitto veloce e affidabile, con transazioni istantanee e integrazione senza problemi nel tuo sistema di gestione. È ideale per i proprietari che privilegiano velocità e comodità.",
            sepa_fee_1: "1.50€ per pagamenti inferiori a 400€",
            sepa_fee_2: "2.50€ per pagamenti inferiori a 400€",
            card_fee_1: "2,5% + 0,25€ per le carte standard EU",
            card_fee_2: "2,9% + 0,25€ per le carte premium EU"
        },
        popup: {
            success: "Salvato con successo",
            error: "Si è verificato un errore"
        },
        booking: {
            date_leng: "it-IT",
            no_bookings: "Nessuna appuntamento disponibile...",
            appointments: "Appuntamenti",
            date_order: "La data di inizio non può essere maggiore della data di fine...",
            add_booking: "Aggiungi appuntamento",
            add_title: "Aggiungi titolo...",
            booking_type: "Tipo di appuntamento",
            date_time: "Data e ora",
            all_day: "Tutto il giorno",
            partecipants: "Partecipanti",
            location: "Luogo",
            description: "Descrizione",
            add_description: "Aggiungi una breve descrizione...",
            short_term: "Breve Termine",
            long_term: "Lungo Termine",
            personal: "Personale",
            meeting: "Riunione",
            visit: "Visita",
            second_visit: "Seconda visita",
            contract_sign: "Firma del contratto",
            work: "Lavoro",
            other: "Altro",
            save_btn: "Salva prenotazione",
            close_btn: "Chiudi",
        },
        properties: {
            search_property: "Cerca immobile",
            my_properties: "Le mie proprietà",
            no_property: "Nessuna proprietà disponibile...",
            add_property: "Aggiungi proprietà",
            apply_filters: "Applica filtri",
            filter_btn: "Filtra",
            title_main: "Titolo",
            title_prop: "Aggiungi un nome",
            unit_type: "Tipo di unità",
            nation: "Nazione",
            city: "Città",
            street_num: "Via e numero",
            zip: "CAP",
            prop_type: "Tipo di proprietà",
            mt2: "Metri quadrati - mt2",
            floor: "Piano",
            building_floors: "Piani totali dell'edificio",
            energy_class: "Classe energetica",
            exposure: "Esposizione",
            heating: "Riscaldamento",
            external_fixtures: "Infissi esterni",
            prop_state: "Stato della proprietà",
            construction_year: "Anno di costruzione",
            living: "Spazio abitativo",
            bedroom: "Camera da letto",
            kitchen: "Cucina",
            bathroom: "Bagno",
            other_included_details: "Altri dettagli inclusi",
            price: "Prezzo",
            property_rent: "Affitto mensile della proprietà",
            currency: "Valuta",
            expenses: "Spese",
            deposit: "Deposito",
            commissions: "Commissioni",
            comm_type: "Tipo",

            elevator: "Ascensore",
            disabled_bathroom: "Bagno per disabili",
            full_furnishing: "Arredamento completo",
            partial_furnishing: "Arredamento parziale",
            kitchen_furnishing: "Arredamento cucina",
            communal_garden: "Giardino comune",
            private_garden: "Giardino privato",
            sauna: "Sauna",
            disabled_elevator: "Ascensore per disabili",
            reception: "Reception",
            external_parking: "Parcheggio esterno",
            kitchen_island: "Isola della cucina",
            pantry: "Dispensa",
            washing_machine: "Lavatrice",
            dryer: "Asciugatrice",
            closets: "Armadi",
            central_heating_ac: "Riscaldamento/aria condizionata centralizzati",
            ceiling_fans: "Ventilatori a soffitto",
            fireplace: "Caminetto",
            skylights: "Lucernari",
            thermostats: "Termostati",
            security_systems: "Sistemi di sicurezza",
            smart_lighting: "Illuminazione intelligente",
            garage: "Garage",
            driveway: "Viale",
            terrace: "Terrazza",
            balcony: "Balcone",
            patio: "Patio",
            swimming_pool: "Piscina",
            outdoor_kitchen: "Cucina all'aperto o area BBQ",
            fence: "Recinzione",
            landscaping: "Paesaggistica",
            clubhouse: "Clubhouse",
            fitness_center: "Centro fitness",
            playground: "Parco giochi",
            tennis_court: "Campo da tennis",
            basketball_court: "Campo da basket",
            walking_trails: "Sentieri pedonali",
            gated_community: "Comunità recintata",
            security_personnel: "Personale di sicurezza",
            furniture: "Mobili",
            water_heater: "Scaldabagno",
            internet_cable_ready: "WIFI",
            phone_lines: "Linee telefoniche",
            pet_friendly_features: "Pet-friendly",
            accessibility_features: "Accessibilità disabili",
            solar_panels: "Pannelli solari",
            home_warranty: "Garanzia sulla casa",

            save_btn: "Salva proprietà",
            close_btn: "Chiudi",
            full_ownership: "Intera proprietà",
            bare_ownership: "Nuda proprietà",
            partial_ownership: "Parziale proprietà",
            usufruct: "Usufrutto",
            timeshare: "Multiproprietà",
            right_superficies: "Diritto di superficie",
            pending_certification: "In attesa di certificazione",
            exempt_property: "Proprietà esente",
            not_classifiable: "Non classificabile",
            north: "Nord",
            south: "Sud",
            est: "Est",
            west: "Ovest",
            autonomous: "Autonomo",
            centralized: "Centralizzato",
            absent: "Assente",
            glass_wood: "Vetro - Legno",
            double_glass_wood: "Doppio vetro - Legno",
            triple_glass_wood: "Triplo vetro - Legno",
            glass_metal: "Vetro - Metallo",
            double_glass_metal: "Doppio vetro - Metallo",
            triple_glass_metal: "Triplo vetro - Metallo",
            glass_pvc: "Vetro - PVC",
            double_glass_pvc: "Doppio vetro - PVC",
            triple_glass_pvc: "Triplo vetro - PVC",
            single_family: "Casa unifamiliare",
            multi_family: "Casa plurifamiliare",
            apartment: "Appartamento",
            condominium: "Condominio",
            townhouse: "Villetta a schiera",
            cooperative: "Cooperativa",
            vacation_home: "Casa vacanza",
            mobile_home: "Casa mobile",
            tiny_home: "Mini casa",
            office_building: "Edificio per uffici",
            retail_property: "Proprietà commerciale",
            industrial_property: "Proprietà industriale",
            mixed_use: "Proprietà a uso misto",
            hotel: "Hotel",
            vacant_land: "Terreno non edificato",
            agricultural_land: "Terreno agricolo",
            commercial_land: "Terreno commerciale",
            residential_land: "Terreno residenziale",
            warehouse: "Magazzino",
            parking_lot: "Parcheggio",
            storage_facility: "Deposito",
            healthcare_facility: "Struttura sanitaria",
            educational_facility: "Struttura educativa",
            religious_facility: "Struttura religiosa",
            deposit_storage_area: "Area deposito",
            garage: "Garage",
            marina: "Marina",
            resort: "Resort",
            ranch: "Ranch",
            room: "Stanza",

            new: "Nuovo",
            like_new: "Come nuovo",
            excellent_condition: "In ottime condizioni",
            good_condition: "In buone condizioni",
            fair_condition: "In condizioni discrete",
            needs_renovation: "Da ristrutturare",
            to_renovate: "Da rinnovare",
            under_construction: "In costruzione",
            partially_renovated: "Parzialmente ristrutturato",
            unfinished: "Non finito",
            move_in_ready: "Pronto per essere abitato",
            buy_rent: "Comprare o affittare?",
            sell_rent: "Vendere o affittare?",
            rent: "Affittare",
            selling: "Vendere",
            buying: "Comprare",
            available_notAvailable: "Disponibile/Non disponibile",
            available_rent: "Disponibile per affitto",
            rented: "Affittato",
            available_buy: "Disponibile per l'acquisto",
            sold: "Venduto",


        },
        agency: {
            title: "Agenzie e sedi",
            add_agency: "Nuova agenzia",
            search_agency: "Cerca agenzia",

            //New agency
            new_agency_title: "Nuova agenzia",
            base_info: "Informazioni di base",
            owner: "Proprietari",
            members: "Membri",
            end: "Fine",
            finalize: "Finalizza",
            main_new_element_title: "Configura Impostazioni di base",
            main_new_element_desc: "Un'agenzia può essere una struttura fisica o digitale dedicata alla gestione di team, utenti, performance e molte altre attività.",
            agency_name: "Nome dell'agenzia",
            description: "Descrizione",
            agency_type: "Tipo di Agenzia",
            physical_agency: "Agenzia fisica",
            remote_agency: "Agenzia digitale/remota",
            prev: "Indietro",
            next: "Avanti",
            new_owner_title: "Nuovo proprietario",
            new_owner_subtitle: "I proprietari possono modificare e gestire l'agenzia",
            select_owner: "Seleziona proprietari",
            owners: "Proprietari",
            you: "Tu",
            new_members_title: "Membri dell'agenzia",
            new_members_subtitle: "Aggiungi i membri dell'agenzia",
            select_members: "Seleziona membri",
            members: "Membri",
            finalize_agency_title: "Finalizza agenzia",
            finalize_agency_subtitle: "Rivedi e finalizza le impostazioni della tua agenzia",


            //New user
            title_user: "Utenti",
            search_user: "Cerca utente",
            new_user: "Nuovo utente",
            licence: "Licenza",
            licence_desc: "Assegna le licenze che desideri a questo utente.",
            description: "Per iniziare, completa alcune informazioni di base",
            user_name: "Nome",
            user_surname: "Cognome",
            gen_pass: "Genera automaticamente la password",
            passw_desc: "La password deve essere lunga tra 8 e 256 caratteri e utilizzare una combinazione di almeno tre dei seguenti tipi di caratteri: lettere maiuscole, lettere minuscole, numeri e simboli.",
            pass_change: "Chiedi all'utente di cambiare la propria password al primo accesso.",
            year: "Anno",
            month: "Mese",
            free_month: "(1 mese gratis)",

            payment_title: "Pagamento",
            payment_subtitle: "Verifica i dati e completa il pagamento.",
            subscription_title: "Abbonamento a ",
            total: "Totale",
            interval: "Intervallo ",
            complete_payment: "COMPLETA PAGAMENTO",
            paid: "Pagato",
            payment_loading: "In corso",
            active: "Attivo",
            error: "In attesa di pagamento",
            not_available: "Non disponibile",
            incomplete: "Incompleto",
            suspended: "Sospeso",
            delete_subscription: "Elimina abbonamento",
            cancel_subscription: "Annulla abbonamento",
            activate_subscription: "Riattiva abbonamento",

            licence_types: {
                basic: "Utilizzo base del software (Dashboard, Calendario, Contatti, Portfolio personale, Report personali).",
                business_standard: "Tutte le funzioni base e la possibilità di creare e gestire Proprietà, Agenzie, Inquilini, Manutenzioni, Finanze e Report.",
                pro: "Tutte le funzioni base e standard business più il Costruttore di siti web e Strumenti di Marketing (Email Marketing, Pubblicità, ecc.)."
            },

            agency_users_table: {
                user_id: "ID Utente",
                username: "Utente",
                lcense: "Licenza",
                subscription_id: "ID Abbonamento",
                price: "Prezzo",
                interval: "Intervallo",
                created_at: "Creato il",
                pay_status: "Stato pagamento",
                sub_status: "Stato abbonamento",
                account_status: "Stato utenza"
            },

            user_account: {
                active: "Attivo",
                pending: "In attesa",
                to_be_suspended: "In sospensione",
                error: "Non attivo",
            },

            modal: {
                title: "Salvare le ultime modifiche?",
                save_btn: "Salva modifiche",
                dont_save_btn: "Scarta modifiche",
                title_delete: "Rimuovere l'utente dall'organizzazione?",
                action_title: "Questa azione può essere ripristinata. <ul> <li>I futuri pagamenti verranno dismessi.</li> <li>Tutti i dati relativi all'utenza verranno bloccati.</li> <li>Il portafoglio di questo utente verrà congelato.</li></ul>",
                type_delete: "Digita il nome dell'utente per poterlo rimuovere definitivamente.",
                delete_btn: "Rimuovi definitivamente",
                back_btn: "Indietro",
            },

            create_users_modal: {
                title: "Modalità di creazione utenze",
                manual: "Manuela immediata",
                hint_manual: "Consigliato per l'aggiunta di pochi utenti",
                massive: "Massiva",
                hint_massive: "Consigliato per organizzazioni più grandi",
            },

            modal_reactivate: {
                title: "Salvare le ultime modifiche?",
                save_btn: "Salva modifiches",
                dont_save_btn: "Scarta modifiche",
                title_reactivate: "Riattivare l'utenza selezionata?",
                action_title: "Questa azione può essere ripristinata. <ul> <li>I futuri pagamenti verranno riattivati.</li> <li>Il portafoglio di questo utente verrà sbloccato.</li></ul>",
                restore_btn: "Riattiva utenza",
                back_btn: "Indietro",
            },
            messages: {
                title: "Chatta con l'organizzazione",
                subtitle: "Invia messaggi e comunica con la tua organizzazione",
                first_mex: "Invia il primo messaggio all'organizzazione",
                placeholder: "Scrivi qualcosa..."
            },

            resources: {
                title: "File e risorse",
                subtitle: "Condividi con l'organizzazione file e risorse utili",
                no_shared: "Condividi il primo file...",
                delete: "Elimina"
            },

            modal_delete_resource: {
                title: "Eliminare il file?",
                action_title: "Questa azione <strong>NON PUÒ</strong> essere annullata. <ul> <li>Il file verrà rimosso in modo permanente.</li></ul>",
                type_delete: "Digita il nome del file per eliminarlo definitivamente.",
                delete_btn: "Elimina definitivamente",
                back_btn: "Indietro",
            },

            members_owners: {
                title: "Proprietari e membri",
                subtitle: "Tutti i partecipanti di questa organizzazione",
                add_title: "Aggiungi partecipanti alla tua organizzazione",
                add_btn: "Aggiungi partecipanti",
            }
        },
        new_property: {
            update_prop: "Modifica proprietà",
            err_fields: "Controlla che tutti i campi siano popolati...",
            new_prop: "Nuova proprietà",
            verify_save_btn: "Verifica e salva",
            ai: {
                analysis: "Analisi in corso...",
                use_this_desc: "Utilizza questa descrizione",
                desc_prev: "Stupendo appartamento nel cuore di...",
                placeholder_ai: "Descrizione su un grazioso bilocale in centro..."
            },
            font: {
                system: "Sistema",
            },
            name: "Nome",
            country: "Nazione",
            city: "Città",
            ZIP: "CAP",
            street: "Strada",
            street_num: "N.",
            floor: "Piano",
            mq: "Metri quadri",
            exposure: "Esposizione",
            energy_class: "Classe energetica",
            heating: "Riscaldamento",
            windows: "Infissi",
            conditions: "Stato della proprietà",
            year_built: "Anno di costruzione",
            drag_img_text: "Trascina un'immagine o caricala dal tuo dispositivo cliccando",
            here: "qui",
            sell_rent: "Affitto o vendita?",
            rent: "Affitto",
            sell: "Vendita",
            people: "Quante persone possono co-abitare?",
            adults: "Adulti",
            children: "Bambini",
            available_date: "Data disponibilità",
            immediate: "Immediata",
            programmed: "Programmata",
            request_amount: "Importo desiderato",
            rent_amount: "Canone",
            condominium_fees: "Spese condominiali",
            security_deposit: "Deposito cauzionale",
            sell_amount: "Importo di vendita",
            spaces: "Spazi",
            living_space: "Spazio abitativo",
            bedrooms: "Camere da letto",
            kitchens: "Cucina",
            bathrooms: "Bagni",
            included_comforts: "Comfort inclusi",
            pet_allowed: "Sono ammessi animali?",
            smoker_allowed: "Sono ammessi fumatori?",
            placeholders: {
                name: "Casa stupenda...",
                country: "Italia",
                city: "Milano",
                street: "Via Roma...",
                street_num: "N.",
                north: "Nord",
                centralized: "Centralizzato",
                glass_pvc: "Vetro PVC",
                new_prop: "Nuovo"
            },
            unit_types: {
                villa: "Villa",
                house: "Casa",
                appartment: "Appartamento",
                room: "Stanza",
                business: "Attività",
                land: "Terreno",
                other: "Altro"
            },
            modal: {
                title: "Salvare le ultime modifiche?",
                save_btn: "Salva modifiche",
                dont_save_btn: "Scarta modifiche",
                title_delete: "Eliminare l'immobile selezionato?",
                action_title: "Questa azione <strong>NON</strong> può essere ripristinata. <ul> <li>I futuri pagamenti verranno dismessi.</li> <li>Tutti i dati relativi all'immobile verranno eliminati.</li> <li>Gli annunci per questo immobile verranno rimossi da tutti i canali.</li></ul>",
                type_delete: "Digita il nome della proprietà per poterla eliminare definitivamente.",
                delete_btn: "Elimina definitivamente",
                back_btn: "Indietro",
            }
        },
        contacts: {
            my_contacts: "I miei contatti",
            no_contacts: "Nessun contatto disponibile...",
            add_contact: "Aggiungi contatto",
            save_contact: "Salva contatto",
            update_contact: "Aggiorna contatto",
            save_contact_changes: "Salva modifiche",
            confirmation_delete: "Eliminare il contatto selezionato?",
            why_look_prop: "Perché stai cercando una proprietà?",
            transfer_date: "Quando vorresti trasferirti?",
            max_bgt: "Budget massimo",
            min_bgt: "Budget minimo",
            mortage: "Hai bisogno di un mutuo?",
            sell_home: "Stai vendendo una proprietà?",
            first_house: "È la tua prima casa?",
            notes: "Note",
        },
        tenants: {
            tenants: "Inquilini",
            new_tenant_title: "Nuovo inquilino",
            update_tenant_title: "Aggiorna inquilino",
            available_prop_rent_title: "Proprietà disponibili per affitto",
            available_prop_rent_desc: "Scegli tra le proprietà disponibili",
            personalized: "Personalizzato",
            monthly: "Mensile",
            payment_meth_month_label: "Come preferisci ricevere i pagamenti?",
            sepa_payment: "Addebito automatico mensile tramite SEPA",
            card_payment: "Pagamento mensile con carta di credito/debito",
            available_properties_title: "Proprietà disponibili",
            manage_properties: "Gestisci proprietà",
            monthly_ammount: "Importo mensile",
            yearly_cashflow: "Flusso di cassa annuale",
            personalized_payments_title: "Pagamenti personalizzati",
            personalized_payments_desc: "Seleziona le date di prenotazione",
            from: "Da",
            to: "A",
            payment_fractioning: "Frazionamento del pagamento",
            selected_period: "Periodo selezionato",
            daily: "Giornaliero",
            weekly: "Settimanale",
            total: "Totale",
            tenant_personal_info_title: "Informazioni personali o aziendali",
            tenant_personal_info_desc: "Inserisci le informazioni personali o aziendali e i contatti dell'inquilino",
            tenant_account_title: "Configurazione dell'account Paypermate per l'inquilino",
            tenant_account_desc: "Abilita automaticamente un account Paypermate per il tuo inquilino o invia il codice abbinamento.",
            back: "Indietro",
            next: "Avanti",
            individual: "Persona fisica",
            business: "Impresa",
            search_contacts: "Cerca tra i contatti",
            save_btn: "Salva",
            document_request_title: "Documenti e contratti",
            document_request_desc: "Carica contratti e richiedi documenti dell'inquilino",
            guarantor: "Richiedere un garante per questo inquilino?",
            complete_reg: "Completa registrazione",
        },
        profile: {
            profile_title: "Profilo",
            access: "Accesso",
            main_credentials_subtitle: "Le credenziali principali per accedere al tuo ambiente",
            pb_information: "Informazioni personali e aziendali",
            pb_subtitle: "Tutte le informazioni riguardanti la tua persona e la tua attività",
            uid_title: "Il tuo ID utente",
            uid_subtitle: "Codice ID personale",
            activity_log: "Registro attività",
            activity_log_subtitle: "Il tuo registro delle attività recenti",
            no_activity: "Nessuna attività recente trovata",
            update_btn: "Aggiorna"
        },
        settings: {
            settings_title: "Impostazioni"
        },
        months: {
            january: "Gennaio",
            february: "Febbraio",
            march: "Marzo",
            april: "Aprile",
            may: "Maggio",
            june: "Giugno",
            july: "Luglio",
            august: "Agosto",
            september: "Settembre",
            october: "Ottobre",
            november: "Novembre",
            december: "Dicembre",
        },
        flatjourney_pm: {
            title: "L'unica piattaforma dove puoi scalare la tua impresa",
            re_title: "Immobiliare",
            business_title: "",
            subtitle: "Con Paypermate, puoi decidere come guadagnare dalle tue proprietà, mentre noi ci occupiamo del resto.",
            start_btn: "PROVA ORA",

            listing_setup_title: "Prepariamo la tua proprietà",
            listing_setup_subtitle: "Fai foto, ristruttura, trova i prezzi e crea contatti",
            connect_title: "Noi. Connettiamo. Persone. Globalmente.",
            deposit_one: "Abbiamo depositato ",
            deposit_two: "nel tuo conto bancario.",

            boost: {
                title: "Aumenta il guadagno mensile",
                subtitle: "Con Paypermate-Boost, goditi un reddito mensile stabile mentre aumenti i guadagni fino a 2 volte con periodi di locazione flessibili.",
            },

            stable: {
                title: "Aumenta il guadagno a lungo termine",
                subtitle: "Con Paypermate-Stable, ottieni inquilini a lungo termine, con uno sforzo minimo e pagamenti ricorrenti mensili.",
            },

            fixed: {
                title: "Assicura liquidità immediata",
                subtitle: "Con Paypermate-Fixed, ricevi pagamenti anticipati per l'affitto di 6 mesi minimo, garantendo liquidità, senza stress.",
            },

            how_does_it_work: "Come funziona?",
            step_1: {
                title: "Registra la tua proprietà in pochi click",
                subtitle: "Inserisci le informazioni necessarie della proprietà come posizione, stanze, ecc..."
            },

            step_2: {
                title: "Decidi come vuoi guadagnare",
                subtitle: "Scegli tra le diverse modalità di gestione (PMT) di Paypermate."
            },

            step_3: {
                title: "Noi ci occupiamo del resto",
                subtitle: "Rilassati e lascia che ci occupiamo di tutto noi."
            },

            qa: {
                title: "Hai altre domande?",
                faq_title: "Domande frequenti",
                faq: {
                    title: "Perché scegliere Paypermate?",
                    answer: "Paypermate è una società PropTech guidata dall'IA. Utilizzando dati e modelli avanzati di machine learning, analizziamo i mercati degli affitti e le performance delle piattaforme per aiutarti a massimizzare il ROI della tua proprietà.",
                    title_1: "Come gestisco le prenotazioni?",
                    answer_1: "Durante l'onboarding riceverai un software gratuito che ti permetterà di gestire facilmente le prenotazioni. Dovrai solo occuparti di accogliere gli inquilini: al resto pensiamo noi, aumentando il tasso di occupazione!",
                    title_2: "Quali sono le commissioni di Paypermate?",
                    answer_2: "Le commissioni dipendono dalla modalità di gestione (PMT) che scegli. Puoi esplorare tutti gli EB cliccando qui."
                },
                step_by_step_title: "Guida passo dopo passo",
                step_by_step: {
                    title: "Come posso iniziare?",
                    answer: "Registra la tua proprietà in pochi click tramite la pagina Start Now. Seleziona la tua modalità di gestione preferita, scegli il metodo di pagamento e rilassati—ci occuperemo noi di tutto il resto.",
                    title_1: "Cosa succede dopo?",
                    answer_1: "Dopo la registrazione, pianificheremo un incontro per ispezionare la tua proprietà, scattare foto professionali, effettuare miglioramenti se necessario e utilizzare i nostri strumenti di IA per pubblicare l’annuncio in modo efficace.",
                    title_2: "Cosa devo fare dopo?",
                    answer_2: "Sei pronto! Inizia a ricevere inquilini e aumenta il ROI del tuo immobile."
                },

                pr_title: "Politiche e regolamenti",
                pr: {
                    title: "Posso terminare il contratto?",
                    answer: "Sì, puoi farlo. Contattaci e ci occuperemo immediatamente della procedura di terminazione.",
                    title_1: "Politiche sulla privacy",
                    answer_1: "Puoi leggere la nostra Politica sulla Privacy cliccando qui."
                },
                payment_title: "Metodi di pagamento",
                payment: {
                    title: "Come riceverò i miei pagamenti?",
                    answer: "Puoi scegliere il tuo programma di pagamento preferito all’inizio—settimanale o mensile. Garantiamo un'esperienza di pagamento senza intoppi."
                },

                more_questions: "Se hai altre domande...",
                ask_btn: "Chiedici qualsiasi cosa",
                ask_save_btn: "Manda la tua domanda",
                ask_title: "La tua domanda",
            },

            comm: "La nostra rete è rinomata per le ottime valutazioni, i bassi tassi di morosità e un'esperienza di pagamento lineare e sicura. Le recensioni degli inquilini sono la base delle nostre valutazioni, garantendo qualità e affidabilità per tutti gli annunci. Supportata da Paypermate SRL, una startup innovativa, i nostri servizi sono attualmente disponibili solo in alcune località.  Scopri di più su ciò che offriamo."
        },


        pm_steps: {
            continue: "Avanti",
            back: "Indietro",
            title_first: "Scala. <br /> Massimizza.<br /> Ripeti.<br />",
            title_1: "Presentati",
            subtitle_1: "Ci piacerebbe sapere di più su di te e su come ci hai trovato.",
            title_2: "Panoramica della Proprietà",
            subtitle_2: "Fornisci dettagli chiave sulla proprietà, come la posizione, le caratteristiche e altro.",
            title_3: "Inizia",
            subtitle_3: "Seleziona la tua modalità di gestione preferita e personalizza le opzioni di pagamento.",

            title_second: "Iniziamo...",
            name: "Nome",
            surname: "Cognome",
            phone: "Telefono",
            job: "Posizione attuale",
            employee: "Dipendente",
            freelancer: "Libero Professionista",
            entrepreneur: "Imprenditore",
            project_based_collaborator: "Collaboratore a Progetto",
            apprentice: "Apprendista",
            eb_choise_title: "Scegli la modalità di gestione del tuo immobile",

            how_about_us: "Come ci hai trovato?",
            word_of_mouth: "Passaparola",
            social_media: "Social Media",
            search_engine: "Motore di ricerca (Google, Bing, ecc.)",
            email: "Email",
            flyer_or_poster: "Volantino o Poster",
            other: "Altro",

            title_third: "Raccontaci delle <br/> tue proprietà?",
            title_prop: "Quante proprietà possiedi?",
            i_have: "Ho",
            property: "proprietà",
            properties: "proprietà",
            location: "Posizione",
            rooms: "Stanze",

            boost: {
                desc: "Gestione della proprietà personalizzata per affitti a breve/medio termine. Dal marketing, foto professionali, onboarding degli inquilini e gestione burocratica, ci occupiamo di tutto per la tua attività immobiliare."
            },
            stable: {
                desc: "Gestione della proprietà a lungo termine per affitti superiori a sei mesi. Offriamo un supporto completo per inquilini e proprietari, garantendo affitti senza problemi per periodi prolungati."
            },
            fixed: {
                desc: "Ottieni subito 6 mesi di affitto in un'unica soluzione per una liquidità immediata."
            },

            finsh_pm_reg_title: "Controlla la tua email!"
        },

        ROI: {
            title: "Massimizzare il ROI Immobiliare",
            subtitle: "Scegli il tipo di gestione immobiliare che preferisci",
            services: "Servizi inclusi",
            requisites: "Requisiti",
            price: "Prezzo",

            tenant_screen: "Validazione degli inquilini",
            onboarding: "Processo di onboarding semplificato",
            burocracy: "Gestione burocratica completa",
            support: "Assistenza clienti attiva 24/7",
            software: "Software dedicato",
            payments: "Automazione pagamenti ricorrenti",
            bookings: "Gestione prenotazioni efficiente",
            tenant_support: "Supporto completo per la gestione degli inquilini",
            marketing: "Strategie di posizionamento sul mercato avanzate",
            insurance: "Copertura assicurativa sull'immobile",
            liquidity: "Accesso immediato alla liquidità richiesta",
        
            req1: "Immobile di proprietà o in contratto di sublocazione",
            req2: "Emissione di un contratto di sublocazione a Paypermate",

            price1: "25€ al mese",
            price2: "3% sul generato",
            price3: "Nessun costo"
        }

    },
}