import React from "react";

const SecondSlide = ({data}) => {
    return(
        <div>
            <p className="title_slide">Chi sono?</p>
            <p className="content_slide">Mi chiamo Zyber Pireci e sì, lo so, il mio nome ricorda "Cyber". Ironia della sorte, sono proprio un ingegnere software. Per finanziare i miei studi, ho lavorato per un periodo nel mondo delle vendite, sviluppando competenze nel settore commerciale.</p>
            <p className="content_slide"> Ho avuto l'opportunità di lavorare in aziende di diverse dimensioni – piccole, medie e grandi – e in ognuna di queste ho sempre cercato di andare oltre i confini della mia mansione, ragionando non solo in termini di "compito", ma con una visione più ampia, orientata al livello "aziendale".</p>
            <div className="img_cont_slide">
                <div className="logo_img_job"></div>
                <div className="logo_img_job"></div>
                <div className="logo_img_job"></div>
            </div>
        </div>
    )
}

export default SecondSlide