import React from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { Dropdown } from "react-bootstrap";

const UsersTable = ({ filteredUsers, handleCompletePayment, handleShowShowDelete, handleShowModalReactivate, highlightedCell, handleCopy, formatAmount, formatDate, loadingUserStatus }) => {

    const { t } = useLanguage()

    return (
        <div className="tenants_table_cont">
            <table className="table_tenants responsive">
                <thead className="thead">
                    <tr>
                        <th className="header_cell_first_col"></th>
                        <th className="header_cell">{t.agency.agency_users_table.user_id}</th>
                        <th className="header_cell">{t.agency.agency_users_table.username}</th>
                        <th className="header_cell">{t.agency.agency_users_table.lcense}</th>
                        <th className="header_cell">{t.agency.agency_users_table.subscription_id}</th>
                        <th className="header_cell">{t.agency.agency_users_table.price}</th>
                        <th className="header_cell">{t.agency.agency_users_table.interval}</th>
                        <th className="header_cell">{t.agency.agency_users_table.created_at}</th>
                        <th className="header_cell">{t.agency.agency_users_table.pay_status}</th>
                        <th className="header_cell">{t.agency.agency_users_table.sub_status}</th>
                        <th className="header_cell">{t.agency.agency_users_table.account_status}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers.map((user) => {
                        const lastPayment = user.payments?.length > 0 ? user.payments[user.payments.length - 1] : {};

                        return (
                            <tr
                                key={user._id.$oid}
                                className={
                                    user.payments?.length > 0 && user.payments[user.payments.length - 1].payment_status === "error" || !user.payments
                                        ? "error_row"
                                        : ""
                                }

                            >
                                <td className="body_cell body_cell_first_col">
                                    <Dropdown>
                                        <Dropdown.Toggle className="custom-dropdown-toggle" as="div">
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="custom-dropdown-menu">
                                            {

                                                // Display "Complete Payment" if the last payment is not paid or uploading
                                                lastPayment?.payment_status !== "paid" && lastPayment?.payment_status !== "uploading" && (
                                                    <Dropdown.Item
                                                        className="drop_down_item suspended_cell"
                                                        onClick={() => handleCompletePayment(user)}
                                                    >
                                                        <i className="fa-solid fa-exclamation"></i> {t.agency.complete_payment}
                                                    </Dropdown.Item>
                                                )

                                            }
                                            {(Object.keys(lastPayment || {}).length > 0 &&
                                                (lastPayment?.status !== "error" && lastPayment?.payment_status !== "error")) && (
                                                    <>
                                                        {lastPayment?.cancel_at_period_end ? (
                                                            <Dropdown.Item
                                                                className="drop_down_item active_cell"
                                                                onClick={() => handleShowModalReactivate(user)}
                                                            >
                                                                <i className="fa-solid fa-lightbulb"></i> {t.agency.activate_subscription}
                                                            </Dropdown.Item>
                                                        ) : (
                                                            <Dropdown.Item
                                                                className="drop_down_item delete"
                                                                onClick={() => handleShowShowDelete(user)}
                                                            >
                                                                <i className="fa-solid fa-ban"></i> {t.agency.cancel_subscription}
                                                            </Dropdown.Item>
                                                        )}
                                                    </>
                                                )}



                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>

                                <td
                                    className={`body_cell copy_clipboard_cell ${highlightedCell === user._id?.$oid ? "highlighted" : ""
                                        }`}
                                >
                                    {user._id?.$oid}
                                    <i
                                        className="fa-solid fa-copy clipboard-icon"
                                        onClick={() => handleCopy(user._id?.$oid)}
                                        title={t.copy_clipboard}
                                    ></i>
                                </td>
                                <td className="body_cell">
                                    {user.personal_info?.email}
                                </td>
                                <td className="body_cell">
                                    {user.license?.type === "business" ? (
                                        <>Business Standard</>
                                    ) : (
                                        <>
                                            {user.license?.type
                                                ?.charAt(0)
                                                .toUpperCase() + user.license?.type.slice(1).toLowerCase()}
                                        </>
                                    )}
                                </td>

                                <td
                                    className={`body_cell copy_clipboard_cell ${highlightedCell === lastPayment?.subscription_id ? "highlighted" : ""
                                        }`}
                                >

                                    {lastPayment?.subscription_id === "error" ? (
                                        <span className="licence_error">{t.agency[lastPayment?.subscription_id]}</span>
                                    ) : !lastPayment?.subscription_id ? (
                                        <>
                                            <span className="licence_error">{t.agency.not_available}</span>
                                        </>
                                    ) : (
                                        <>
                                            {lastPayment?.subscription_id}
                                            <i
                                                className="fa-solid fa-copy clipboard-icon"
                                                onClick={() => handleCopy(lastPayment?.subscription_id)}
                                                title={t.copy_clipboard}
                                            ></i>
                                        </>
                                    )}
                                </td>

                                <td className="body_cell">
                                    {lastPayment?.amount ? (
                                        `${formatAmount(lastPayment.amount)} ${lastPayment.currency}`
                                    ) : (
                                        <>
                                            {user.license?.type && user.license?.interval ? (
                                                (() => {
                                                    let price;
                                                    const type = user.license.type.toLowerCase();
                                                    const interval = user.license.interval.toLowerCase();

                                                    if (type === "basic" && interval === "month") price = 699;
                                                    else if (type === "business" && interval === "month") price = 999;
                                                    else if (type === "pro" && interval === "month") price = 1500;
                                                    else if (type === "basic" && interval === "year") price = 7600;
                                                    else if (type === "business" && interval === "year") price = 10900;
                                                    else if (type === "pro" && interval === "year") price = 16500;

                                                    return price ? `${formatAmount(price)} eur` : t.agency.not_available;
                                                })()
                                            ) : (
                                                t.agency.not_available
                                            )}
                                        </>
                                    )}
                                </td>

                                <td className="body_cell">{lastPayment?.interval ? (t.agency[lastPayment?.interval]) : (t.agency[user?.license?.interval])}</td>
                                <td className="body_cell">
                                    {lastPayment?.created_at ? formatDate(lastPayment.created_at.$date) : <span className="licence_error">{t.agency.not_available}</span>}
                                </td>
                                <td className="body_cell double_body_cell">
                                    {!lastPayment?.payment_status || lastPayment?.payment_status === "unpaid" ? (
                                        <span className="error_cell">
                                            <i className="fa-solid fa-x ko_status_paid"></i>
                                            {t.agency.error}
                                        </span>
                                    ) : lastPayment?.payment_status !== "paid" &&
                                        lastPayment?.payment_status !== "uploading" ? (
                                        <span className="error_cell">
                                            <i className="fa-solid fa-x ko_status_paid"></i>
                                            {t.agency[lastPayment?.payment_status]}
                                        </span>
                                    ) : lastPayment?.payment_status === "uploading" ? (
                                        <span
                                            className="loading_cell"
                                        >
                                            <i className="fa-solid fa-rotate uploading_status"></i>{" "}
                                            {t.agency.payment_loading}
                                        </span>
                                    ) : (
                                        <span className="paid_cell">
                                            <i className="fa-solid fa-check ok_status_paid"></i> {t.agency.paid}
                                        </span>
                                    )}
                                </td>

                                <td className="body_cell">
                                    {lastPayment?.status ? (t.agency[lastPayment?.status]) : (t.agency.not_available)}
                                </td>
                                <td className="body_cell">
                                    {loadingUserStatus ? (
                                        <>
                                            <div className="cell_beam_loading"></div>
                                        </>
                                    ) : (
                                        <>
                                            {lastPayment?.cancel_at_period_end ? (
                                                <span className="suspended_cell">{t.agency.user_account.to_be_suspended}</span>
                                            ) : (
                                                <span className="active_cell">{t.agency.user_account[user?.license?.status]}</span>
                                            )
                                            }
                                        </>
                                    )}
                                </td>
                            </tr>
                        );
                    })}

                </tbody>
            </table>
        </div>
    )
}

export default UsersTable
