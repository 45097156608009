import React from "react";
import { useLanguage } from "../Translations/LenguageContext";

const FJCommPM = () => {
    const {t} = useLanguage()
    return (
        <div className="fj_comm_cont">
            <p className="fj_comm_cont_title">
                {t.flatjourney_pm.comm}
            </p>
        </div>
    )
}

export default FJCommPM