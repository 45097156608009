import React from "react";

const ThirdSlide = () => {
    return (
        <div>
            <p className="title_slide">L'immoobiliare e il tech</p>
            <p className="content_slide">In Italia, e anche all'estero, il settore immobiliare si distingue dagli altri settori per la mancata "rivoluzione" tecnologica.<br/> I processi aziendali sono rimasti uguali.</p>
        </div>
    )
}

export default ThirdSlide