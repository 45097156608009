import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import MainView from "./MainView";
import NavBar from "./NavBar";

function ControlPanel() {
    const navigate = useNavigate();
    const [no_session, SetNoSession] = useState(false);
    const [userData, setUserData] = useState(null);
    const [refreshSide, setRefreshSide] = useState(false);
    const [isSidebarClosed, setIsSidebarClosed] = useState(false); // New state for toggle

    const handleNavigation = (view) => {
        navigate(`/control_panel/${view}`);
    };

    const refreshSidebar = () => {
        setRefreshSide(true);
    };

    const handleToggleSidebar = () => {
        setIsSidebarClosed((prevState) => !prevState); // Toggle the sidebar state
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const userId = sessionStorage.getItem("user_id");
            if (!userId) {
                SetNoSession(true);
                setTimeout(() => {
                    navigate(`/login`);
                }, 3000);
            }

            try {
                const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_specific_data', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: userId }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
                setUserData(data);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [navigate]);

    return (
        <div className="control_panel_cont">
            <NavBar onNavigate={handleNavigation} userData={userData} />
            <div className="control_panel_container">
                {/* Dynamically apply 'closed_sidebar' class */}
                <Sidebar 
                    onNavigate={handleNavigation} 
                    userData={userData} 
                    statusRefresh={refreshSide} 
                    isSidebarClosed={isSidebarClosed}
                />
                <div 
                    className={`toggle_sidebar ${isSidebarClosed ? "toggle_sidebar_closed" : ""}`}
                    onClick={handleToggleSidebar}
                >
                    <i className={`fa-solid ${isSidebarClosed ? "fa-chevron-right" : "fa-chevron-left"}`}></i>
                </div>
                {/* Dynamically apply 'open_main_nav_page' class */}
                <div className={`main_nav_page ${isSidebarClosed ? "open_main_nav_page" : ""}`}>
                    <MainView userData={userData} no_session={no_session} refreshSidebar={refreshSidebar} />
                </div>
            </div>
        </div>
    );
}

export default ControlPanel;
