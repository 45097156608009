import React from "react";
import { useLanguage } from "../Translations/LenguageContext";


const StepsPM = () => {
    const {t} = useLanguage()

    const stepsData = [
        {
            title: t.flatjourney_pm.step_1.title,
            subtitle: t.flatjourney_pm.step_1.subtitle,
            iconClass: "fa-house step_pm_box_icon",
        },
        {
            title: t.flatjourney_pm.step_2.title,
            subtitle: t.flatjourney_pm.step_2.subtitle,
            iconClass: "fa-line-chart step_pm_box_icon",
        },
        {
            title: t.flatjourney_pm.step_3.title,
            subtitle: t.flatjourney_pm.step_3.subtitle,
            iconClass: "fa-handshake step_pm_box_icon",
        },
    ];
    

    return (
        <div className="steps_pm_container">
            <p className="steps_pm_cont_title">{t.flatjourney_pm.how_does_it_work}</p>
            <div className="room_image">

            </div>
            <div className="steps_pm_cont">
                {stepsData.map((step, index) => (
                    <div className="step_pm_box" key={index}>
                        <i className={`fa-solid ${step.iconClass}`}></i>
                        <p className="step_pm_title">{step.title}</p>
                        <p className="step_pm_subtitle">{step.subtitle}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StepsPM;
