import React, { useEffect, useRef } from "react";
import Navbar from "./Navbar";
import MainContent from "./MainContent";
import Footer from "./Footer";
import OnBoardingSection from "./OnBoardingSection";
import PaymentProcess from "./PaymentProcess";
import ManageCalendar from "./ManageCalendar";
import PropertyMarketing from "./PropertiesMarketing";
import WebsiteBuilding from "./WebsiteBuilding";
import HalfPhoneAnimations from "./HalfPhoneAnimations";
import PolicyPopup from "./PolicyPopup";
import HomeGlobe from "./GlobeHome";
import FJCommPM from "../PropertyManagment/FlatjourneyCommunicationPM";
import AskBtn from "../PropertyManagment/AskBtn";

function Homepage() {
    const sectionsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visible");
                    } else {
                        entry.target.classList.remove("visible");
                    }
                });
            },
            { threshold: 0.5 }
        );

        sectionsRef.current.forEach((section) => {
            if (section) {
                observer.observe(section);
            }
        });

        return () => {
            sectionsRef.current.forEach((section) => {
                if (section) {
                    observer.unobserve(section);
                }
            });
        };
    }, []);

    return (
        <div>
            <Navbar />
            <div className="body_content">
                {/* <PolicyPopup/> */}
                <div className="fade-in-section" ref={(el) => (sectionsRef.current[0] = el)}>
                    <MainContent />
                </div>
                {/* <div className="fade-in-section" ref={(el) => (sectionsRef.current[1] = el)}>
                    <OnBoardingSection />
                </div>
                <div className="fade-in-section" ref={(el) => (sectionsRef.current[2] = el)}>
                    <PropertyMarketing />
                </div>
                <div className="fade-in-section" ref={(el) => (sectionsRef.current[3] = el)}>
                    <HomeGlobe/>
                </div>
                <div className="fade-in-section" ref={(el) => (sectionsRef.current[4] = el)}>
                    <HalfPhoneAnimations/>
                </div>
                <div className="fade-in-section" ref={(el) => (sectionsRef.current[5] = el)}>
                    <ManageCalendar />
                </div>

                <div className="fade-in-section" ref={(el) => (sectionsRef.current[6] = el)}>
                    <AskBtn />
                </div>

                <div className="fade-in-section" ref={(el) => (sectionsRef.current[7] = el)}>
                    <FJCommPM />
                    </div> */}
            </div>
            <Footer />
        </div>
    );
}

export default Homepage;