import React from "react";

import { BsShopWindow } from "react-icons/bs";

const LoadingAgency = () => {
    return (
        <div className="loading_agency">
            {[...Array(8)].map((_, rowIndex) => (
                <div className="agency_cube_loading" >
                    <div className="agency_cube_header">
                        <i><BsShopWindow /></i>
                        <p>Loading...</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default LoadingAgency