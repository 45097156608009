import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from '../components/Spinner';
import { useLanguage } from '../Translations/LenguageContext';

const AskInfoModal = ({ show, handleClose }) => {
    const { t } = useLanguage();
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });
    const [fieldErrors, setFieldErrors] = useState({
        name: false,
        email: false,
        message: false,
    });

    const handleModalClose = () => {
        handleClose();
        setFormData({
            name: "",
            email: "",
            message: "",
        });
        setErr({ message: "", display: false });
        setFieldErrors({
            name: false,
            email: false,
            message: false,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const handleSubmit = async () => {
        setErr({ message: "", display: false });
        setFieldErrors({
            name: false,
            email: false,
            message: false,
        });

        if (!formData.name || !formData.email || !formData.message) {
            setErr({ message: t.register_form.red_fields, display: true });

            setFieldErrors({
                name: !formData.name,
                email: !formData.email,
                message: !formData.message,
            });
            return;
        }

        if (!isValidEmail(formData.email)) {
            setErr({ message: t.register_form.email_format, display: true });
            setFieldErrors({ email: true });
            return;
        }

        setLoading(true);

        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/save_request_info', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (response.ok) {
                console.log('Request saved successfully:', data);

                handleModalClose();
            } else {
                setErr({ message: t.register_form.problem_back , display: true });
            }
        } catch (error) {
            setErr({ message: t.register_form.errors_back , display: true });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleModalClose} className="modal_req_info">
            <div className="header_modal_info_req">
                <div className="info_re_title_cont">
                    <p className='info_re_title'>{t.flatjourney_pm.qa.ask_btn}</p>
                </div>
            </div>

            <div className="body_modal_delete">
                <div className='input_label_cont'>
                    <div className='input_label_container'>
                        <label className='label_req_info'>{t.register_form.name} & {t.register_form.surname}</label>
                        <input
                            className={`input_req_info ${fieldErrors.name ? 'error_border' : ''}`}
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='input_label_container'>
                        <label className='label_req_info'>Email</label>
                        <input
                            className={`input_req_info ${fieldErrors.email ? 'error_border' : ''}`}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='input_label_single_cont'>
                    <label className='label_req_info'>{t.flatjourney_pm.qa.ask_title}</label>
                    <textarea
                        className={`input_req_info text_req ${fieldErrors.message ? 'error_border' : ''}`}
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="footer_modal_re_cont">
                <div className="footer_btn_req_cont">
                    {err.display && <div className="error_message">{err.message}</div>}
                    {loading && <Spinner loading={loading} err={err} />}
                    <button
                        className="contact_us_pm_btn"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {t.flatjourney_pm.qa.ask_save_btn}
                    </button>
                </div>

            </div>
        </Modal>
    );
};

export default AskInfoModal;
