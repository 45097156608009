// Footer.js
import React, { useEffect, useState } from "react";
import { SiMinutemailer } from "react-icons/si";
import { useLanguage } from "../Translations/LenguageContext";
import { MdMarkEmailRead, MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function Footer() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const { t, changeLanguage, language } = useLanguage();
    const [email, setEmail] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [buttonColor, setButtonColor] = useState('');

    // useEffect(() => {
    //     // Function to fetch user location and set language
    //     const detectLanguageByLocation = async () => {
    //         try {
    //             // Use an external service or API to detect location
    //             const response = await fetch("https://ipapi.co/json/");
    //             const data = await response.json();

    //             if (data.country_code === "IT") {
    //                 changeLanguage("it");
    //             } else {
    //                 changeLanguage("en");
    //             }
    //         } catch (error) {
    //             // Fallback in case of error or failure
    //             console.error("Error detecting location:", error);
    //             changeLanguage("en");
    //         }
    //     };

    //     detectLanguageByLocation();
    // }, [changeLanguage]);

    const handleLanguageChange = (event) => {
        changeLanguage(event.target.value);
    };

    const navigateSocial = (social) => {
        if (social === "linkedin") {
            window.open("https://linkedin.com/company/paypermate", "_blank");
        }
    }

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/newsletter_subscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            console.log('Subscription successful');
            setEmail('');
            setIsButtonDisabled(true);
            setButtonColor('green');

            setLoading(false)
            setTimeout(() => {
                setIsButtonDisabled(false);
                setButtonColor('');
            }, 3000);
        } else {
            setButtonColor('red');
            setLoading(false)
            console.error('Subscription failed', response.statusText);
        }
    };

    return (
        <div className="footer_homepage">
            <div className="footer_container">
                <div className="sections_footer sf1">
                    <div className="section_footer_text">
                        <p className="brand_logo">
                            <i className="fa-solid fa-layer-group"></i>
                            Paypermate
                        </p>
                        <p className="brand_rights">{t.footer.brand_rights}</p>
                        <p className="brand_rights">{t.footer.address}</p>
                        {/* <p className="brand_rights">{t.footer.vat}</p> */}
                        <select className="language_select" onChange={handleLanguageChange} value={language}>
                            <option value="it">Italiano</option>
                            <option value="en">English</option>
                            {/* <option value="de">Deutsch</option> */}
                            {/* <option value="fr">Français</option> */}
                        </select>
                    </div>

                </div>

                <div className="sections_footer">
                    <div className="section_footer_text">
                        {/* <p className="footer_titles">{t.footer.about_us}</p> */}
                        {/* <p className="footer_titles">{t.footer.prices}</p> */}
                        <div className="link_cont_footer">
                            {/* <p className="link_footer" onClick={() => navigate("/aboutus")}>{t.footer.who}</p> */}
                            {/* <p className="link_footer" onClick={() => navigate("/pricing")}>{t.footer.prices}</p> */}
                            {/* <p className="link_footer" onClick={() => navigate("/open_positions")}>{t.footer.jobs}</p> */}
                            {/* <p className="link_footer" onClick={() => navigate("/blog")}>Blog</p> */}
                        </div>
                    </div>

                </div>
                <div className="sections_footer">
                    <div className="section_footer_text">
                        {/* <p className="footer_titles">Solutions</p> */}
                        <div className="link_cont_footer">
                            {/* <p className="link_footer" onClick={() => navigate("/payments-transactions")}>{t.footer.payments_transactions}</p> */}
                            {/* <p className="link_footer" onClick={() => navigate("/PM")}>Property Managment</p> */}
                            {/* <p className="link_footer" onClick={() => navigate("/marketplace")}>Flatjourney</p> */}
                            {/* <p className="link_footer" onClick={() => navigate("/tenant-validation")}>{t.footer.tenant_validation}</p> */}
                            {/* <p className="link_footer" onClick={() => navigate("/ai-analytics")}>{t.footer.ai_analytics}</p> */}
                        </div>
                    </div>


                </div>
                <div className="sections_footer">
                    <div className="section_footer_text">

                        <p className="footer_titles">{t.footer.get_in_touch}</p>
                        <div className="logo_social_container">
                            <p className="email_contact_footer">hello@paypermate.com</p>
                            {/* <i className="fa-brands fa-twitter"></i> */}
                            {/* <i className="fa-brands fa-instagram"></i> */}
                            {/* <i className="fa-brands fa-facebook"></i> */}
                            {/* <i onClick={() => navigateSocial("linkedin")} className="fa-brands fa-linkedin"></i> */}
                        </div>
                        <p className="newsletter_title">Newsletter</p>
                        <div className="newsletter_cont">
                            <input
                                className="input_newsletter"
                                placeholder="jhondoe@gmail.com"
                                value={email}
                                onChange={handleInputChange}
                                required
                                type="email"
                            />
                            <button
                                className="button_newsletter"
                                onClick={handleSubmit}
                                disabled={isButtonDisabled}
                                style={{ backgroundColor: buttonColor, border: `1px solid ${buttonColor}` }}
                            >
                                {loading ? (
                                    <div id="spinner" className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : buttonColor === 'green' ? (
                                    <MdMarkEmailRead />
                                ) : buttonColor === 'red' ? (
                                    <i className="fa-solid fa-x"></i>
                                ) : (
                                    <SiMinutemailer />
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="section_footer_text">
                        <p className="footer_titles">Legal</p>
                        <div className="link_cont_footer">
                            <p className="link_footer" onClick={() => navigate("/terms-and-conditions")}>{t.footer.terms_conditions}</p>
                            <p className="link_footer" onClick={() => navigate("/privacy-policy")}>Privacy</p>
                            <p className="link_footer" onClick={() => navigate("/cookie-policy")}>Cookies</p>
                            <p className="link_footer" onClick={() => navigate("/legal-notes")}>{t.footer.legal_notes}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;