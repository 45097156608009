import React from "react";

const HeaderOpenAgency = ({ dashboardItems }) => {
    return (
        <div className="header_dashboard">
            {dashboardItems.map((item, index) => (
                <div className="header_cont_agency_dash" key={index}>
                    <div className="header_cont_agency_dash_inner">
                        <i className={`fa-solid ${item.icon}`} style={{ backgroundColor: item.color, borderColor: item.color }}></i>
                        <div className="header_cont_agency_dash_cont">
                            <p className="header_cont_agency_title" style={{ color: item.color }}>
                                {item.title}
                            </p>
                            <p className="header_cont_agency_subtitle">{item.subtitle}</p>
                        </div>
                    </div>
                    {/* <p className="header_cont_agency_number" style={{ color: item.color }}>{item.num}</p> */}
                </div>
            ))}
        </div>
    )
}

export default HeaderOpenAgency