import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../Translations/LenguageContext";

const MarketPlace = () => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [selectedPropertyKey, setSelectedPropertyKey] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showShareModal, setShowSharePropModal] = useState(false);
    const [selectedPropertyName, setSelectedPropertyName] = useState(null);
    const [popupType, setPopupType] = useState("success");
    const [popupActive, setPopupActive] = useState(false);
    const [userId, setUserId] = useState("6751c5ece9bdb38671942e85")
    const [imageIndices, setImageIndices] = useState({});



    const [showModal, setShowModal] = useState(false);
    const [properties, setProperties] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');


    const fetchProperties = async () => {
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_properties', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProperties(data.properties || {});
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, []);


    const filteredProperties = Object.entries(properties).filter(([key, property]) => {
        const searchLowerCase = searchQuery.toLowerCase();
        const getValue = (value) => (value ? value.toLowerCase() : '');

        const rentAndExpensesTotal = (
            (Number(property.rent_price_property) || 0) +
            (Number(property.expenses_property) || 0)
        ).toFixed(2);

        // Search filter
        const matchesSearchQuery = (
            getValue(property.marketType).includes(searchLowerCase) ||
            getValue(property.adults).includes(searchLowerCase) ||
            getValue(property.children).includes(searchLowerCase) ||
            getValue(property.isImmediate).includes(searchLowerCase) ||
            getValue(property.startDate).includes(searchLowerCase) ||
            getValue(property.rentalAmount).includes(searchLowerCase) ||
            getValue(property.condominiumFees).includes(searchLowerCase) ||
            getValue(property.securityDeposit).includes(searchLowerCase) ||
            getValue(property.saleAmount).includes(searchLowerCase) ||
            getValue(property.livingSpace).includes(searchLowerCase) ||
            getValue(property.bedrooms).includes(searchLowerCase) ||
            getValue(property.kitchen).includes(searchLowerCase) ||
            getValue(property.bathrooms).includes(searchLowerCase) ||
            getValue(property.selectedOptions).includes(searchLowerCase) ||
            getValue(property.petPermission).includes(searchLowerCase) ||
            getValue(property.smokePermission).includes(searchLowerCase) ||
            getValue(property.propertyName).includes(searchLowerCase) ||
            getValue(property.description).includes(searchLowerCase) ||
            getValue(property.selectedUnit).includes(searchLowerCase) ||
            getValue(property.country).includes(searchLowerCase) ||
            getValue(property.city).includes(searchLowerCase) ||
            getValue(property.postalCode).includes(searchLowerCase) ||
            getValue(property.street).includes(searchLowerCase) ||
            getValue(property.streetNumber).includes(searchLowerCase) ||
            getValue(property.floor).includes(searchLowerCase) ||
            getValue(property.size).includes(searchLowerCase) ||
            getValue(property.exposure).includes(searchLowerCase) ||
            getValue(property.energyClass).includes(searchLowerCase) ||
            getValue(property.heating).includes(searchLowerCase) ||
            getValue(property.windows).includes(searchLowerCase) ||
            getValue(property.condition).includes(searchLowerCase) ||
            getValue(property.yearBuilt).includes(searchLowerCase)
        );

        return matchesSearchQuery;
    });


    const handleNextImage = (propertyKey, property) => {
        setImageIndices((prevIndices) => {
            const nextIndex = (prevIndices[propertyKey] || 0) === property.images.length - 1 ? 0 : (prevIndices[propertyKey] || 0) + 1;
            return { ...prevIndices, [propertyKey]: nextIndex };
        });
    };

    const handlePrevImage = (propertyKey, property) => {
        setImageIndices((prevIndices) => {
            const prevIndex = (prevIndices[propertyKey] || 0) === 0 ? property.images.length - 1 : (prevIndices[propertyKey] || 0) - 1;
            return { ...prevIndices, [propertyKey]: prevIndex };
        });
    };



    return (
        <div className="market_place">
            {loading ? (
                <div className="loading_market_place">
                    {[...Array(50)].map((_, rowIndex) => (
                        <div className="property_card_marketplace" >
                            <div className="header_img_cont">
                                <div className="property_btn_action_loading">
                                </div>
                                <div className="property_btn_action_loading_second" >
                                </div>
                            </div>
                            <div className="property_img_blotter_loading"></div>
                            <div className="property_img_scroll_cont">
                                <div className="property_btn_action_scroll" >
                                    <i className="fa-solid fa-chevron-left"></i>
                                </div>
                                <div className="property_btn_action_scroll" >
                                    <i className="fa-solid fa-chevron-right"></i>
                                </div>
                            </div>
                            <div className="footer_img_cont">
                            </div>

                            <div className="property_description_cont_loading">
                                <p className="property_title_card_loading"></p>
                                <p className="property_location_card_loading">
                                </p>
                                <p className="property_amount_card_loading"></p>
                            </div>
                        </div>


                    ))}
                </div>
            ) : (
                <>
                    {filteredProperties.map(([key, property], index) => {
                        const currentImageIndex = imageIndices[key] || 0;
                        return (
                            <div className="property_card_marketplace" key={property.key || index}>
                                <div className="header_img_cont">
                                    <div className="property_btn_action">
                                        <p>{t.properties[property.available]}</p>
                                    </div>
                                    <div className="property_btn_action" >
                                        <i className="fa-solid fa-heart"></i>
                                    </div>
                                </div>

                                {property.images && property.images.length > 0 ? (
                                    <img
                                        className="property_img"
                                        src={property.images[currentImageIndex].url}
                                        alt="Property"
                                    />
                                ) : (
                                    <div className="property_img_blotter"></div>
                                )}
                                <div className="property_img_scroll_cont">
                                    <div className="property_btn_action_scroll" onClick={() => handlePrevImage(key, property)}>
                                        <i className="fa-solid fa-chevron-left"></i>
                                    </div>
                                    <div className="property_btn_action_scroll" onClick={() => handleNextImage(key, property)}>
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </div>
                                </div>
                                <div className="footer_img_cont">
                                </div>

                                <div className="property_description_cont">
                                    <p className="property_title_card">{property.property_name}</p>
                                    <p className="property_location_card">
                                        {property.street}, {property.street_number} - {property.city} ({property.country})
                                    </p>
                                    <p className="property_amount_card">{property.rental_amount || property.sale_amount} {property.currency}</p>
                                </div>
                            </div>
                        );
                    })}
                </>
            )}

        </div>
    );
};

export default MarketPlace;
