import React, { useState } from "react";
import JourneyNavbar from "../JourneyNavbar";
import SingleComplete from "./SingleComplete";
import FooterJourney from "../FooterJourney";

const SingleCompleteMain = () => {

    return (
        <div>
            <JourneyNavbar page_to_navigate={"/start-journey"} />
            <SingleComplete />
        </div>
    );
};

export default SingleCompleteMain;
