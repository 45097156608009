import React, { useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { Link, useNavigate } from "react-router-dom";
import { FaInfinity } from "react-icons/fa";
import Spinner from "../../../components/Spinner";

const SingleComplete = () => {
    const { t } = useLanguage();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [propertyCount, setPropertyCount] = useState(1);
    const [selectedService, setSelectedService] = useState("Boost");
    const [openServiceDesc, setOpenServiceDesc] = useState("");
    const [isFormValid, setFormValid] = useState(false);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        surname: "",
        phone: "",
        email: "",
        selectedService: selectedService,
        propertyCount: propertyCount,
    });

    const [errors, setErrors] = useState({
        name: "",
        surname: "",
        phone: "",
        email: "",
        selectedService: "",
        privacyChecked: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name.trim()) newErrors.name = t.register_form.red_fields;
        if (!formData.surname.trim()) newErrors.surname = t.register_form.red_fields;
        if (!formData.phone.trim()) newErrors.phone = t.register_form.red_fields;
        if (!formData.email.trim()) {
            newErrors.email = t.register_form.red_fields;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = t.register_form.email_format;
        }
        if (!formData.selectedService) newErrors.selectedService = t.register_form.select_service;

        if (!privacyChecked) {
            newErrors.privacyChecked = true;
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const ValidateFields = async () => {
        const isValid = validateForm();

        if (isValid) {
            try {
                setLoading(true)
                const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/register_pm", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                });
                console.log(response)
                if (response.ok) {
                    setTimeout(() => {
                        setLoading(false)
                        navigate(`/start-journey/completed/${formData.email}`)
                    }, 1000);
                } else {
                    console.error("Error saving user");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    }

    const increment = () => {
        setPropertyCount((prev) => {
            const newCount = prev + 1;
            setFormData((prevFormData) => ({ ...prevFormData, propertyCount: newCount }));
            return newCount;
        });
    };

    const decrement = () => {
        setPropertyCount((prev) => {
            const newCount = prev > 1 ? prev - 1 : 1;
            setFormData((prevFormData) => ({ ...prevFormData, propertyCount: newCount }));
            return newCount;
        });
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        const numValue = parseInt(value, 10);

        if (!isNaN(numValue) && numValue > 0) {
            setPropertyCount(numValue);
            setFormData((prevFormData) => ({ ...prevFormData, propertyCount: numValue }));
        } else {
            setPropertyCount(1);
            setFormData((prevFormData) => ({ ...prevFormData, propertyCount: 1 }));
        }
    };

    const handleInputBlur = () => {
        if (propertyCount < 1) {
            setPropertyCount(1);
            setFormData((prevFormData) => ({ ...prevFormData, propertyCount: 1 }));
        }
    };

    const handleServiceSelect = (service) => {
        setSelectedService(service);
        setFormData((prevFormData) => ({ ...prevFormData, selectedService: service }));
    };

    const toggleServiceDescCont = (serviceName) => {
        setOpenServiceDesc((prev) => (prev === serviceName ? null : serviceName));
    };

    const handleCheckboxChange = () => {
        setPrivacyChecked((prev) => !prev);
    };

    return (
        <div className="main_container_journey">
            <div className="main_container_journey_cont">
                <p className="main_container_journey_title" dangerouslySetInnerHTML={{ __html: t.pm_steps.title_second }}></p>
            </div>
            <div className="main_container_journey_cont">
                <div className="steps_cont_jr">
                    <div className="steps_cont_jr_block_inputs">
                        <div className={`steps_cont_jr_block_inner ${errors.name ? "error" : ""}`}>
                            <p className="steps_cont_jr_block_title">{t.pm_steps.name}</p>
                            <input
                                className={`steps_cont_jr_block_input ${errors.name ? "input_error_pm" : ""}`}
                                name="name"
                                value={formData.name}
                                onChange={handleChange}

                            />
                        </div>
                        <div className={`steps_cont_jr_block_inner ${errors.surname ? "error" : ""}`}>
                            <p className="steps_cont_jr_block_title">{t.pm_steps.surname}</p>
                            <input
                                className={`steps_cont_jr_block_input ${errors.surname ? "input_error_pm" : ""}`}
                                name="surname"
                                value={formData.surname}
                                onChange={handleChange}

                            />
                        </div>
                    </div>
                    <div className="steps_cont_jr_block_inputs">
                        <div className={`steps_cont_jr_block_inner ${errors.phone ? "error" : ""}`}>
                            <p className="steps_cont_jr_block_title">{t.pm_steps.phone}</p>
                            <input
                                className={`steps_cont_jr_block_input ${errors.phone ? "input_error_pm" : ""}`}
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}

                            />
                        </div>
                        <div className={`steps_cont_jr_block_inner ${errors.email ? "error" : ""}`}>
                            <p className="steps_cont_jr_block_title">Email</p>
                            <input
                                className={`steps_cont_jr_block_input ${errors.email ? "input_error_pm" : ""}`}
                                name="email"
                                value={formData.email}
                                onChange={handleChange}

                            />
                        </div>
                    </div>
                    <div className="how_many_prop_cont">
                        <p className="steps_cont_jr_block_title">{t.pm_steps.title_prop}</p>
                        <div className="how_may_prop_choises_cont">
                            <div className="how_may_prop_choises">
                                <p className="how_may_prop_choises_title">{t.pm_steps.i_have}</p>
                                <div className="prop_amount_btn_cont">
                                    <button className="prop_amount_btn" onClick={decrement}>-</button>
                                    <input
                                        className="how_may_prop_choises_title_num"
                                        type="number"
                                        value={propertyCount}
                                        onChange={handleInputChange}
                                        onBlur={handleInputBlur}
                                    />
                                    <button className="prop_amount_btn" onClick={increment}>+</button>
                                </div>
                                <p className="how_may_prop_choises_title">
                                    {propertyCount === 1 ? t.pm_steps.property : t.pm_steps.properties}
                                </p>
                            </div>
                        </div>
                    </div>
                    <p className="steps_cont_jr_block_title">{t.pm_steps.eb_choise_title}</p>
                    <div className="service_block_cont">
                        {[
                            // { name: "Boost", desc: t.flatjourney_pm.boost.subtitle, rent_type: "STR", serviceDesc: t.pm_steps.boost.desc },
                            { name: "Boost", desc: t.flatjourney_pm.boost.subtitle, rent_type: "STR-MTR", serviceDesc: t.pm_steps.boost.desc },
                            { name: "Stable", desc: t.flatjourney_pm.stable.subtitle, rent_type: "LTR", serviceDesc: t.pm_steps.stable.desc },
                            { name: "Fixed", desc: t.flatjourney_pm.fixed.subtitle, rent_type: <FaInfinity />, serviceDesc: t.pm_steps.fixed.desc },
                        ].map((service) => (
                            <div
                                key={service.name}
                                className={`service_choise_block ${selectedService === service.name ? "selected_service_choise" : ""}`}
                                onClick={() => handleServiceSelect(service.name)}
                            >
                                <div className="service_choise_header">
                                    <p className="service_choise_block_title">{service.name}</p>
                                    <p className="service_choise_block_type">{service.rent_type}</p>
                                </div>
                                <p className="service_choise_block_desc">{service.desc}</p>
                                <div className="how_does_service_work_cont">
                                    <i
                                        className={`fa-solid fa-question ${openServiceDesc === service.name ? "open_desc" : "closed_desc"}`}
                                        onClick={() => toggleServiceDescCont(service.name)}
                                    ></i>
                                    {openServiceDesc === service.name && (
                                        <div className={`service_desc_cont ${openServiceDesc === service.name ? "open_desc" : "closed_desc"}`}>{service.serviceDesc}</div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="priv_mail_cont">

                        <input
                            type="checkbox"
                            checked={privacyChecked}
                            onChange={handleCheckboxChange}

                        />
                        <p
                            id="privacy_policy_desc"
                            style={{ color: errors.privacyChecked ? 'var(--danger)' : '' }}
                        >
                            {t.register_form.privacy_policy_desc} <Link to={"/privacy-policy"}>{t.register_form.p_p}</Link>
                        </p>

                    </div>
                </div>
                <div className="error_message_pm">
                    {errors.name || errors.surname || errors.email || errors.phone || errors.privacyChecked > 0 && (
                        <p>{t.register_form.red_fields}</p>
                    )}
                </div>
            </div>
            <div className="footer_journey_cont">
                <button
                    className="action_btns_pm"
                    onClick={ValidateFields}
                >
                    {loading ? (
                        <Spinner loading={true} err={{message: "", display: false}} />
                    ): (
                        <>
                        {t.pm_steps.continue} <i className="fa-solid fa-chevron-right"></i>
                        </>
                    )}
                </button>
            </div>

        </div>
    );
};

export default SingleComplete;
