import React from "react";
import { useLanguage } from "../../../Translations/LenguageContext";

const MainStartJourney = () => {
    const {t} = useLanguage()

    return (
        <div className="main_container_journey">
            <div className="main_container_journey_cont">
                <p className="main_container_journey_title"  dangerouslySetInnerHTML={{ __html: t.pm_steps.title_first}}></p>
            </div>
            <div className="main_container_journey_cont">
                <div className="steps_cont_jr">
                    <div className="steps_cont_jr_block">
                        <div className="steps_cont_jr_block_inner">
                            <p className="steps_cont_jr_block_title">{t.pm_steps.title_1}</p>
                            <p className="steps_cont_jr_block_subtitle">{t.pm_steps.subtitle_1}</p>
                        </div>
                        <div className="img_cont_jr" style={{backgroundImage: 'url("IMG/man_thinking.jpg")'}}></div>
                    </div>
                    <div className="steps_cont_jr_block">
                        <div className="steps_cont_jr_block_inner">
                            <p className="steps_cont_jr_block_title">{t.pm_steps.title_2}</p>
                            <p className="steps_cont_jr_block_subtitle">{t.pm_steps.subtitle_2}</p>
                        </div>
                        <div className="img_cont_jr" style={{backgroundImage: 'url("IMG/inside_house.jpg")'}}></div>
                    </div>
                    <div className="steps_cont_jr_block">
                        <div className="steps_cont_jr_block_inner">
                            <p className="steps_cont_jr_block_title">{t.pm_steps.title_3}</p>
                            <p className="steps_cont_jr_block_subtitle">{t.pm_steps.subtitle_3}</p>
                        </div>
                        <div className="img_cont_jr" style={{backgroundImage: 'url("IMG/rocket.jpg")'}}></div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default MainStartJourney