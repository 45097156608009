import React from "react";
import { useParams } from "react-router-dom";
import JourneyNavbar from "../JourneyNavbar";
import { useLanguage } from "../../../Translations/LenguageContext";

const CompletedPM = () => {
    const { t } = useLanguage()
    const { email } = useParams()
    return (
        <div>
            <JourneyNavbar page_to_navigate={"/PM"} />
            <div className="completed_pm_request_cont">
                <p className="main_container_journey_title" dangerouslySetInnerHTML={{ __html: t.pm_steps.finsh_pm_reg_title }}></p>
                <div className="completed_pm_request_block_cont">
                    <div className="completed_pm_request_block">
                        <div className="img_completed_pm_block"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompletedPM