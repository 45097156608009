import React, { useState } from "react";
import AddOwnerMembersModal from "./AddOwnerMembersModal";
import { useLanguage } from "../../../../Translations/LenguageContext";

const UsersOpenAgency = ({ owners, members }) => {
    const { t } = useLanguage();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalOpen = () => setIsModalOpen(true);
    const handleModalClose = () => setIsModalOpen(false);

    const uniqueUsers = [...owners, ...members].reduce((acc, user) => {
        if (!acc.some((existingUser) => existingUser.email === user.email)) {
            acc.push(user);
        }
        return acc;
    }, []);

    return (
        <div className="middle_first_open_agency">
            <p className="middle_second_open_agency_title">
                {t.agency.members_owners.title}
                <span className="disancore_chat" onClick={handleModalOpen}>
                    <i className="fa-solid fa-plus"></i>
                </span>
            </p>
            <p className="middle_second_open_agency_subtitle">
                {t.agency.members_owners.subtitle}
            </p>

            {uniqueUsers.length === 0 ? (
                <div className="no_data_open_agency">
                    <p>{t.agency.members_owners.add_title}</p>
                    <button className="add_property_btn" onClick={handleModalOpen}>
                        <i className="fa-solid fa-plus"></i> {t.agency.members_owners.add_btn}
                    </button>
                </div>
            ) : (
                <div className="movements_container">
                    {owners.map((owner) => (
                        <div
                            className="users_rows_open_agency owner_agency"
                            key={owner._id}
                        >
                            <p className="user_mov">{owner.email}</p>
                        </div>
                    ))}

                    {uniqueUsers
                        .filter(
                            (user) =>
                                !owners.some((owner) => owner.email === user.email)
                        )
                        .map((member) => (
                            <div
                                className="users_rows_open_agency"
                                key={member._id}
                            >
                                <p className="user_mov">{member.email}</p>
                            </div>
                        ))}
                </div>
            )}
            <AddOwnerMembersModal show={isModalOpen} handleClose={handleModalClose} />
        </div>
    );
};

export default UsersOpenAgency;
