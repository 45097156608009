import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { useNavigate } from "react-router-dom";
import { MdAdminPanelSettings } from "react-icons/md";
import { FaUserShield } from "react-icons/fa";
import Spinner from "../../../components/Spinner";

const NewAgency = ({ userData }) => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [loading, setLoading] = useState()
    const [err, setErr] = useState({ message: "", display: false });
    const [agencyName, setAgencyName] = useState("");
    const [description, setDescription] = useState("");
    const [selectedType, setSelectedType] = useState(null);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({ agencyName: false, description: false, type: false });

    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        if (userData && userData._id) {
            fetchAgencyUsers(userData._id);
        }
    }, [userData]);

    const BackToPreviousPage = () => {
        navigate("/control_panel/agencies");
    };

    const handleCheckboxChange = (type) => {
        setSelectedType(type);
        setErrors((prev) => ({ ...prev, type: false }));
    };

    const handleNext = () => {
        const newErrors = {
            agencyName: !agencyName.trim(),
            description: !description.trim(),
            type: !selectedType,
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    };

    const handlePrev = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    const fetchAgencyUsers = (user_id) => {
        fetch(`https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_agency_users?user_id=${user_id}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to load data');
                }
            })
            .then(data => {
                setUsers(data);
            })
            .catch(err => {
                setError(err.message || 'Error fetching users');
            });
    };

    const [selectedOwners, setSelectedOwners] = useState([]);

    const handleUserSelect = (event) => {
        const userId = event.target.value;

        const selectedOwner = users.find(user => user._id.$oid === userId);

        if (selectedOwner && !selectedOwners.some(user => user._id === selectedOwner._id.$oid)) {
            setSelectedOwners(prevSelected => [
                ...prevSelected,
                {
                    _id: selectedOwner._id.$oid,
                    email: selectedOwner.personal_info?.email || 'No Email',
                }
            ]);
        }
    };

    const handleRemoveUser = (userId) => {
        setSelectedOwners(prevSelected =>
            prevSelected.filter(user => user._id !== userId)
        );
    };

    const [selectedMembers, setSelectedMembers] = useState([]);

    const handleMemberSelect = (event) => {
        const memberId = event.target.value;

        const selectedMember = users.find(user => user._id.$oid === memberId);

        if (selectedMember && !selectedMembers.some(member => member._id === selectedMember._id.$oid)) {
            setSelectedMembers(prevSelected => [
                ...prevSelected,
                {
                    _id: selectedMember._id.$oid,
                    email: selectedMember.personal_info?.email || 'No Email',
                }
            ]);
        }
    };

    const handleRemoveMember = (memberId) => {
        setSelectedMembers(prevSelected =>
            prevSelected.filter(member => member._id !== memberId)
        );
    };

    const SaveAgency = async () => {
        const dataToSave = {
            user_id: userData._id,
            agencyName: agencyName,
            description: description,
            agency_type: selectedType,
            owners: selectedOwners,
            members: selectedMembers
        };
        console.log(dataToSave)

        try {
            setLoading(true)
            const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/save_agency", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataToSave),
            });

            const result = await response.json();
            console.log(result)
            if (response.ok) {
                setLoading(false)
                setErr({ message: "", display: false })
                BackToPreviousPage()
            } else {
                setLoading(false)

            }
        } catch (error) {
            setLoading(false)
            console.error("Request failed:", error);
        }
    }


    const steps = [
        (
            <div className="main_new_element">
                <p className="main_new_element_title">{t.agency.main_new_element_title}</p>
                <p className="main_new_element_desc">{t.agency.main_new_element_desc}</p>
                <div className="main_new_element_input_desc_cont">
                    <div className="new_element_input_desc_cont">
                        <label>{t.agency.agency_name}</label>
                        <input
                            className="new_element_input_desc_cont_input"
                            style={{
                                borderBottom: errors.agencyName ? "1px solid var(--danger)" : "",
                            }}
                            value={agencyName}
                            onChange={(e) => {
                                setAgencyName(e.target.value);
                                setErrors((prev) => ({ ...prev, agencyName: false }));
                            }}
                        />
                    </div>
                </div>
                <div className="main_new_element_input_desc_cont">
                    <div className="new_element_input_desc_cont">
                        <label>{t.agency.description}</label>
                        <textarea
                            className="new_element_input_desc_cont_textarea"
                            style={{
                                border: errors.description ? "1px solid red" : "1px solid #ccc",
                            }}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                setErrors((prev) => ({ ...prev, description: false }));
                            }}
                        ></textarea>
                    </div>
                </div>
                <div className="main_new_element_input_desc_cont">
                    <div className="new_element_input_desc_cont_checkbox">
                        <label
                            style={{
                                color: errors.type && selectedType !== "physical" ? "red" : "#000",
                            }}
                        >
                            {t.agency.physical_agency}
                        </label>
                        <input
                            type="checkbox"
                            checked={selectedType === "physical"}
                            onChange={() => handleCheckboxChange("physical")}
                        />
                    </div>
                    <div className="new_element_input_desc_cont_checkbox">
                        <label
                            style={{
                                color: errors.type && selectedType !== "remote" ? "red" : "#000",
                            }}
                        >
                            {t.agency.remote_agency}
                        </label>
                        <input
                            type="checkbox"
                            checked={selectedType === "remote"}
                            onChange={() => handleCheckboxChange("remote")}
                        />
                    </div>
                </div>
            </div>
        ),
        (
            <div className="main_new_element">
                <p className="main_new_element_title">{t.agency.new_owner_title}</p>
                <p className="main_new_element_desc">{t.agency.new_owner_subtitle}</p>
                <div className="main_new_element_input_desc_cont">
                    <div className="new_element_input_desc_cont">
                        <label>{t.agency.select_owner}</label>
                        <select
                            className="new_element_input_desc_cont_input"
                            onChange={handleUserSelect}
                        >
                            <option value="">-</option>
                            {users.map(user => (
                                <option key={user._id.$oid} value={user._id.$oid}>
                                    {user.personal_info?.email}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="choosen_element_container">
                        <div className="choosen_element_container_single">
                            <p className="choosen_element_container_single_parag">
                                <i>
                                    <MdAdminPanelSettings />
                                </i>
                                {t.agency.you}
                            </p>
                        </div>
                        {selectedOwners.map((user) => (
                            <div key={user._id} className="choosen_element_container_single">
                                <p className="choosen_element_container_single_parag">
                                    {user.email}
                                    <i
                                        className="fa-solid fa-x remove_item"
                                        onClick={() => handleRemoveUser(user._id)}
                                    ></i>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ),
        (
            <div className="main_new_element">
                <p className="main_new_element_title">{t.agency.new_members_title}</p>
                <p className="main_new_element_desc">{t.agency.new_members_subtitle}</p>
                <div className="main_new_element_input_desc_cont">
                    <div className="new_element_input_desc_cont">
                        <label>{t.agency.select_members}</label>
                        <select
                            className="new_element_input_desc_cont_input"
                            onChange={handleMemberSelect}
                        >
                            <option value="">-</option>
                            {users.map(user => (
                                <option key={user._id.$oid} value={user._id.$oid}>
                                    {user.personal_info?.email}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="choosen_element_container">
                        <div className="choosen_element_container_single">
                            <p className="choosen_element_container_single_parag">
                                <i>
                                    <MdAdminPanelSettings />
                                </i>
                                {t.agency.you}
                            </p>
                        </div>
                        {selectedMembers.map((member) => (
                            <div key={member._id} className="choosen_element_container_single">
                                <p className="choosen_element_container_single_parag">
                                    {member.email}
                                    <i
                                        className="fa-solid fa-x remove_item"
                                        onClick={() => handleRemoveMember(member._id)}
                                    ></i>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ),
        (
            <div className="main_new_element">
                <p className="main_new_element_title">{t.agency.finalize_agency_title}</p>
                <p className="main_new_element_desc">{t.agency.finalize_agency_subtitle}</p>
                <div className="main_new_element_input_desc_cont">

                    <div className="element_agency_final">
                        <label>{t.agency.agency_name}</label>
                        <p>{agencyName}</p>
                    </div>
                    <div className="element_agency_final">
                        <label>{t.agency.description}</label>
                        <p>{description}</p>
                    </div>
                    <div className="element_agency_final">
                        <label>{t.agency.agency_type}</label>
                        <p>{selectedType}</p>
                    </div>

                    {selectedOwners.length > 0 && (
                        <>
                            <label>{t.agency.owners}</label>
                            <div className="selected_elements_agency_cont">
                                {selectedOwners.map((owner, index) => (
                                    <p key={owner._id}>{owner.email}</p>
                                ))}
                            </div>
                        </>
                    )}

                    {selectedMembers.length > 0 && (
                        <>
                            <label>{t.agency.members}</label>
                            <div className="selected_elements_agency_cont">
                                {selectedMembers.map((member, index) => (
                                    <p key={member._id}>{member.email}</p>
                                ))}
                            </div>
                        </>
                    )}

                    <button
                        className="finalize_now_btn"
                        onClick={SaveAgency}
                    >
                        {loading ? (
                            <Spinner loading={true} err={{ message: "", display: false }} />
                        ) : (
                            <>
                                {t.tenants.save_btn}
                            </>
                        )}
                    </button>
                </div>
            </div>
        ),
    ];

    return (
        <div className="main_view_section">
            <p className="title_main_view">
                <i
                    onClick={BackToPreviousPage}
                    className="fa-solid fa-chevron-left back-btn_chevron"
                ></i>
                {t.agency.new_agency_title}
            </p>
            <div className="new_agency_main_cont">
                <div className="new_agency_main_container">
                    <div className="steps_new_element">
                        {steps.map((_, index) => (
                            <React.Fragment key={index}>
                                <div className="step_cont_element">
                                    <i
                                        className={
                                            currentStep === index
                                                ? "fa-regular fa-circle-check active-step"
                                                : "fa-regular fa-circle"
                                        }
                                    ></i>
                                    <p>{[t.agency.base_info, t.agency.owner, t.agency.members, t.agency.end][index]}</p>
                                </div>
                                {index < steps.length - 1 && <div className="separator_steps_cont"></div>}
                            </React.Fragment>
                        ))}
                    </div>
                    {steps[currentStep]}
                </div>
                <div className="next_element_cont">
                    <button className={`next_element_cont_btn ${currentStep === 0 ? "disabled_btn_naviga" : ""}`} onClick={handlePrev} disabled={currentStep === 0}>
                        <i className="fa-solid fa-chevron-left"></i> {t.agency.prev}
                    </button>
                    <Spinner loading={loading} err={err}></Spinner>
                    <button
                        className={`next_element_cont_btn ${currentStep === steps.length - 1 ? "disabled_btn_naviga" : ""}`}
                        onClick={handleNext}
                        disabled={currentStep === steps.length - 1}
                    >
                        {t.agency.next} <i className="fa-solid fa-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NewAgency;
