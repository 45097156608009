import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useLanguage } from "../../../Translations/LenguageContext";
import PaymentFormStripe from "../PaymentForm/PaymentFormStripe";

const CompletePaymentModal = ({ show, handleClose, selectedUser, amount, sub_type, plan, userName, userSurname, email, phone }) => {
    const { t } = useLanguage()
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <PaymentFormStripe amount={amount} sub_type={sub_type} plan={plan} user_id={selectedUser} userName={userName} userSurname={userSurname} email={email} phone={phone} new_update={"update"}/>
            </Modal.Body>
        </Modal>
    )
}

export default CompletePaymentModal