import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from "../../../components/Spinner";
import { useLanguage } from '../../../Translations/LenguageContext';

const ReactivateSubscriptionModal = ({ show, handleClose, selectedUser, amount, sub_type, plan, userName, userSurname, email, phone }) => {
    const { t } = useLanguage();
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const ActivateSelectedAccount = async () => {
        setLoading(true);
        setErr({ message: "", display: false });
        try {
            const response = await fetch(
                "https://boiling-mountain-85041-33258735eb3b.herokuapp.com/reactivate_user_agency",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id: selectedUser }),
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.message === "OK") {
                setInputValue("");
                handleClose();
            } else {
                throw new Error("No tenants found");
            }
        } catch (error) {
            setErr({ message: error.message, display: true });
        } finally {
            setLoading(false);
        }
    };

    const handleModalClose = () => {
        setErr({ message: "", display: false });
        setLoading(false)
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} className="modal_contacts_delete">
            <div className='header_modal'>
                <div className='modal_title_cont_prop'>
                    <p>{t.agency.modal_reactivate.title_reactivate}</p>
                </div>
                <button className='close_x_modale' onClick={handleModalClose}>
                    <i className='fa-solid fa-x'></i>
                </button>
            </div>
            <div className='body_modal_delete'>
                <p className='body_delete_modal'>{userName} {userSurname}</p>
                <p className='modal_delete_mex' dangerouslySetInnerHTML={{ __html: t.agency.modal_reactivate.action_title }}></p>
                {err.display && <p className="error_message">{err.message}</p>}
            </div>

            <div className='footer_modal'>
                <div className='footer_btn_cont'>
                    <button
                        className='save_modal_btn'
                        onClick={ActivateSelectedAccount}
                    >
                        {t.agency.modal_reactivate.restore_btn}
                    </button>
                    <button
                        className='close_modal_btn'
                        onClick={handleModalClose}
                        disabled={loading}
                    >
                        {t.agency.modal_reactivate.back_btn}
                    </button>
                </div>
                {loading && <Spinner loading={loading} err={err}></Spinner>}
            </div>
        </Modal>
    );
};

export default ReactivateSubscriptionModal;
