import React from "react";

const FirstSlide = ({ data }) => {
    return (
        <div>
            <p className="slide_title">Buongiorno {data.personal_info.name} <div className="hello_icon">👋</div></p>
            <p className="slide_content">Benvenuto nel Pitch Deck di Paypermate.</p>
            <div className="pitch_message_cont">
                <p>L'immobiliare come dovrebbe essere.</p>
            </div>
        </div>
    )
}

export default FirstSlide