import * as React from 'react';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import {
  LinePlot,
  MarkPlot,
  lineElementClasses,
  markElementClasses,
} from '@mui/x-charts/LineChart';



export default function TinyLineChart({ type }) {

  let pData1 = [];
  let pData2 = [];
  const xLabels = [
    'Page A',
    'Page B',
    'Page C',
    'Page D',
    'Page E',
    'Page F',
    'Page G',
  ];

  if (type === "s") {
    pData1 = [2400, 1398, 1800, 3508, 2800, 3800, 4300];
    pData2 = [1200, 3280, 1000, 3050, 3500, 3400, 4100];
  } else if (type === "m") {
    pData1 = [2400, 2398, 2800, 3908, 3800, 4250, 4300]; 
    pData2 = [2350, 2280, 2500, 3600, 3500, 4200, 4400]; 
  } else if (type === "l") {
    pData1 = [1200, 1100, 1100, 1100, 1100, 1100, 1200];
    pData2 = [1000, 1100, 1050, 1050, 1050, 1100, 1000];
  } else if (type === "f") {
    pData1 = [1000, 1100, 1050, 1100, 1050, 1100, 1200];
    pData2 = [1010, 1110, 1060, 1110, 1060, 1110, 1210];
  }


  return (
    <ChartContainer
      width={430}
      height={250}
      series={[
        { type: 'line', data: pData1 },
        { type: 'line', data: pData2 },
      ]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      sx={{
        [`& .${lineElementClasses.root}`]: {
          strokeWidth: 3,
        },
        [`& .${lineElementClasses.line}`]: {
          '&:nth-of-type(1)': { stroke: 'var(--mainColor)' },
          '&:nth-of-type(2)': { stroke: 'var(--azure)' },
        },
        [`& .${markElementClasses.root}`]: {
          scale: '0.6',
          fill: 'var(mainColor)',
          strokeWidth: 2,
        },
        [`& .${markElementClasses.mark}`]: {
          '&:nth-of-type(1)': { stroke: 'var(--mainColor)' },
          '&:nth-of-type(2)': { stroke: 'var(--azure)' },
        },
      }}
      disableAxisListener
    >
      <LinePlot />
      <MarkPlot />
    </ChartContainer>
  );
}
