import React from "react";
import MainPM from "./MainPM";
import StepsPM from "./StepsPM";
import ROIPM from "./ROIPM";
import Footer from "../Website/Footer";
import QAPM from "./QAPM";
import FJCommPM from "./FlatjourneyCommunicationPM";
import NavbarPM from "./NavbarPM";

const HomePM = () => {
    return(
        <div className="home_pm_cont">
            <NavbarPM/>
            <MainPM/>
            <StepsPM/>
            <ROIPM/>
            <QAPM/>
            <FJCommPM/>
            <Footer/>
        </div>
    )
}

export default HomePM