import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../Translations/LenguageContext";
import { BsShopWindow } from "react-icons/bs";
import LoadingAgency from "../../../components/LoadingAgency";

const Agencies = () => {
    const { t } = useLanguage(); // Translation 
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // State for loading spinner
    const [error, setError] = useState(null); // State for error handling
    const [agencies, setAgencies] = useState([]); // State for fetched agencies
    const [searchQuery, setSearchQuery] = useState(''); // State for search query

    const userId = sessionStorage.getItem("user_id"); // Retrieve user ID from session storage

    // Function to fetch agencies from the backend
    const fetchAgencies = async () => {
        try {
            setLoading(true); // Start loading spinner
            const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_agencies", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ user_id: userId }),
            });

            const result = await response.json();
            console.log(result)
            if (response.ok) {
                setAgencies(result.agencies); // Update agency list
                setError(null); // Clear any previous errors
            } else {
                setError(result.error || t.agency.fetch_error); // Show API error message
            }
        } catch (err) {
            setError(t.agency.request_failed + ": " + err.message); // Show request failure message
        } finally {
            setLoading(false); // Stop loading spinner
        }
    };

    // Fetch agencies on component mount
    useEffect(() => {
        fetchAgencies();
    }, []);

    // Filter agencies based on search query
    const filteredAgencies = agencies.filter((agency) =>
        agency.agencyName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Navigate to the "new agency" creation page
    const navigateNewProperty = () => {
        navigate("new-agency");
    };

    // Navigate to the selected agency's details page
    const handleOpenAgency = (id) => {
        navigate(`view/${id}`);
    };

    // Show error message if there is an error
    if (error) {
        return (
            <div className="loading_mex_cont_err">
                <p>{error}</p>
                <i className="fa-solid fa-box-open"></i>
            </div>
        );
    }

    return (
        <div className="main_view_section">
            <p className="title_main_view">{t.agency.title}</p>
            <div className="property_bar_table_cont">
                <div className="property_func_bar">
                    <button className="add_property_btn" onClick={navigateNewProperty}>
                        <i className="fa-solid fa-plus"></i> {t.agency.add_agency}
                    </button>
                    <div className="filter_input_cont">
                        <i className="fa-solid fa-magnifying-glass"></i>
                        <input
                            className="search_bar_input"
                            placeholder={`${t.agency.search_agency}...`}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>

                {loading ? (
                    <LoadingAgency />
                ) : (
                    <div className="agency_content">
                        {filteredAgencies.length === 0 ? (
                            <p>{t.agency.no_agencies_found}</p>
                        ) : (
                            filteredAgencies.map((agency) => (
                                <div
                                    className="agency_cube"
                                    key={agency.id}
                                    onClick={() => handleOpenAgency(agency.id)}
                                >
                                    <div className="agency_cube_header">
                                        <i>
                                            <BsShopWindow />
                                        </i>
                                        <p>{agency.agencyName}</p>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Agencies;
