import React from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../Translations/LenguageContext";

const FooterJourney = ({ page_to_navigate }) => {
    const { t } = useLanguage();
    const navigate = useNavigate();

    const handleNextPage = () => {
        navigate(`/start-journey/${page_to_navigate}`);
    };

    return (
        <div className="footer_journey_cont">
            <button
                className="action_btns_pm"
                onClick={handleNextPage}
            >
                {t.pm_steps.continue} <i className="fa-solid fa-chevron-right"></i>
            </button>
        </div>
    );
};

export default FooterJourney;
