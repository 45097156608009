import React from "react";
import { Modal } from "react-bootstrap";
import { useLanguage } from "../../../../Translations/LenguageContext";

const AddOwnerMembersModal = ({ show, handleClose }) => {
    const { t } = useLanguage();

    const handleModalClose = () => {
        handleClose();
    };


    return (
        <Modal show={show} onHide={handleClose} className="modal_contacts_add_users_agency">
             <div className='header_modal'>
            <div className='modal_title_cont_prop_delete'>
                    
                </div>
                <button className='close_x_modale' onClick={handleModalClose}>
                    <i className='fa-solid fa-x'></i>
                </button>
            </div>
            <div className="modal_body">

            </div>
        </Modal>
    );
};

export default AddOwnerMembersModal;
