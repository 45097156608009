import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { useNavigate, useParams } from "react-router-dom";
import OpenAgencyChart from "./OpenAgencyDashboard/OpenAgencyChart";
import HeaderOpenAgency from "./OpenAgencyDashboard/HeaderOpenAgency";
import MessagesOpenAgency from "./OpenAgencyDashboard/MessagesOpenAgency";
import FileResourceOpenAgency from "./OpenAgencyDashboard/FileResourceOpenAgency";
import UsersOpenAgency from "./OpenAgencyDashboard/UsersOpenAgency";

const OpenAgency = ({ userData }) => {
    const { t } = useLanguage();
    const { agency_id } = useParams();
    const navigate = useNavigate();

    const [agencyData, setAgencyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(true);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');
    const userId = sessionStorage.getItem("user_id");
    const [selectedFiles, setSelectedFiles] = useState([])
    const [files, setFiles] = useState([]);
    const [owners, setOwners] = useState([]);
    const [members, setMembers] = useState([]);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [noFile, setNoFile] = useState(false);
    const [isLoadingfile, setIsLoadingFile] = useState(false)

    const  applyTemporaryDanger = (element) => {
        setNoFile(true)
    
        setTimeout(() => {
            setNoFile(false)
        }, 3000);
    }

    const BackToPreviousPage = () => {
        navigate("/control_panel/agencies");
    };

    const GetSpecAgency = () => {
        if (agency_id && userId) {
            setLoading(true);

            const dataToSend = {
                user_id: userId,
                agency_id: agency_id,
            };

            fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_spcific_agency", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataToSend),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    setAgencyData(data.agency);
                    setOwners(data.agency.owners)
                    setMembers(data.agency.members)
                    setLoading(false);
                })
                .catch((err) => {
                    setError(err.message);
                    setLoading(false);
                });
        }
    }

    const fetchMessages = async () => {
        setIsRefreshing(true)
        try {
            const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_agency_messages", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: userId,
                    agency_id: agency_id,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setMessages(data.messages || []);
            } else {
                console.error("Failed to fetch messages");
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        } finally {
            setTimeout(() => {
                setIsRefreshing(false)
            }, 500);
            setIsLoading(false);
        }
    };

    const dashboardItems = [
        { icon: "fa-tag", title: "Vendite", subtitle: "Negli ultimi 30 giorni", color: "var(--mainColor)", num: "+10%" },
        { icon: "fa-house", title: "Acquisizioni", subtitle: "Negli ultimi 30 giorni", color: "purple", num: "+4" },
        { icon: "fa-handshake", title: "Affitti", subtitle: "Negli ultimi 30 giorni", color: "var(--azure)", num: "-2%" },
        { icon: "fa-chart-line", title: "Team KPI", subtitle: "Negli ultimi 7 giorni", color: "var(--secondColor)", num: "+25%" },
    ];

    const handleSendMessage = async () => {
        if (!message.trim()) {

            return;
        }

        const dataToSend = {
            username: userData.name,
            usersurname: userData.surname,
            user_id: userId,
            agency_id: agency_id,
            message: message,
        };

        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/send_agency_message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });

            if (response.ok) {
                setMessage('');
                fetchMessages()
            } else {
                console.error('Failed to send message');
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const handleUploadFile = async () => {
        if (!selectedFiles || selectedFiles.length === 0) {
            applyTemporaryDanger()
            return;
        }

        const formData = new FormData();
        const userId = sessionStorage.getItem("user_id");
        const agencyId = agency_id;

        Array.from(selectedFiles).forEach((file) => {
            formData.append("file", file);
        });
        
        formData.append("user_id", userId);
        formData.append("agency_id", agencyId);
        setUploadingFile(true)
        try {
            const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/save_files_agency", {
                method: "POST",
                body: formData,
            });

            const result = await response.json();
            setUploadingFile(false)
            if (response.ok) {
                setSelectedFiles(null);
                fetchFiles()
            } else {
                alert(`Error uploading files: ${result.error}`);
            }
        } catch (err) {
            console.error("File upload error:", err);
            alert("An error occurred while uploading the files.");
            setUploadingFile(false)
        }
    };


    const fetchFiles = async () => {
        setIsLoadingFile(true)
        try {
            const response = await fetch(`https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_files_agency?agency_id=${agency_id}`);
            const data = await response.json();
            if (response.ok) {
                setFiles(data.files);
            } else {
                console.error("Failed to fetch files:", data.error);
            }
        } catch (err) {
            console.error("Error fetching files:", err);
        } finally{
            setTimeout(() => {
                setIsLoadingFile(false)
            }, 1000);
        }
    };


    const handleDownload = (fileUrl) => {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = fileUrl.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    useEffect(() => {
        GetSpecAgency()
        fetchMessages();
        fetchFiles();
    }, [userId, agency_id]);

    if (error) {
        return <div>{'error'}: {error}</div>;
    }

    return (
        <div className="main_view_section">

            {loading ? (
                <div className="loading_open_agency">
                    <div className="first_block_loading_open_agency"></div>
                    <div className="second_block_loading_open_agency">
                        <div className="sec_one_block_loading"></div>
                        <div className="sec_two_block_loading"></div>
                    </div>
                    <div className="third_block_loading_open_agency">
                        <div className="sec_two_block_loading"></div>
                        <div className="sec_one_block_loading"></div>
                    </div>
                </div>
            ) : (

                <div className="main_content_agency">
                    <p className="title_main_view">
                        <i
                            onClick={BackToPreviousPage}
                            className="fa-solid fa-chevron-left back-btn_chevron"
                        ></i>
                        {agencyData?.agencyName}
                    </p >
                    <div className="agency_dashboard">
                        <HeaderOpenAgency dashboardItems={dashboardItems} />
                        <div className="middle_dashboard">
                            <OpenAgencyChart />
                            <UsersOpenAgency owners={owners} members={members} />
                        </div>
                        <div className="end_dashboard">
                            <MessagesOpenAgency isRefreshing={isRefreshing} isLoading={isLoading} message={message} messages={messages} setMessage={setMessage} handleSendMessage={handleSendMessage} user_id={userId} fetchMessages={fetchMessages} agency_id={agency_id}/>
                            <FileResourceOpenAgency setSelectedFiles={setSelectedFiles} handleUploadFile={handleUploadFile} files={files} handleDownload={handleDownload} agency_id={agency_id} user_id={userId} fetchFiles={fetchFiles} uploadingFile={uploadingFile} noFile={noFile} isLoadingfile={isLoadingfile}/>
                        </div>
                    </div>
                    <p className="settings_agency"><i className="fa-solid fa-gear"></i> Impostazioni agenzia</p>
                </div >
            )}
        </div >
    );
};

export default OpenAgency;
