import React from "react";
import { useLanguage } from "../Translations/LenguageContext";

const ROIPM = () => {
    const { t } = useLanguage()

    return (
        <div className="testimony_pm_cont">
            <p className="steps_pm_cont_title">{t.ROI.title}</p>
            <p className="steps_pm_cont_subtitle">{t.ROI.subtitle}</p>

            <div className="roi_cont_pm">

                <div className="roi_blocks">
                    <p className="roi_block_title">Stable <span className="specifics">LTR</span></p>
                    <p className="roi_block_subtitle">{t.flatjourney_pm.stable.subtitle}</p>
                    <p className="costs_container_tite">{t.ROI.services}</p>
                    <div className="earnings_calculation_n">
                        <p><i className="fa-solid fa-check"></i> {t.ROI.tenant_screen}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.onboarding}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.burocracy}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.support}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.software}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.payments}</p>
                    </div>


                    <p className="costs_container_tite">{t.ROI.requisites}</p>
                    <div className="earnings_calculation_n">
                        <p>{t.ROI.req1}</p>
                    </div>
                    <p className="costs_container_tite">{t.ROI.price}</p>
                    <div className="earnings_calculation_n">
                        <p>{t.ROI.price1}</p>
                    </div>
                </div>
                <div className="roi_blocks">
                    <p className="roi_block_title">Boost <span className="specifics">STR-MTR</span></p>
                    <p className="roi_block_subtitle">{t.flatjourney_pm.boost.subtitle}</p>
                    <p className="costs_container_tite">{t.ROI.services}</p>
                    <div className="earnings_calculation_n">
                        <p><i className="fa-solid fa-check"></i> {t.ROI.tenant_screen}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.onboarding}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.burocracy}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.support}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.software}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.payments}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.bookings}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.tenant_support}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.marketing}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.insurance}</p>
                    </div>
                    <p className="costs_container_tite">{t.ROI.requisites}</p>
                    <div className="earnings_calculation_n">
                        <p>{t.ROI.req1}</p>
                    </div>
                    <p className="costs_container_tite">{t.ROI.price}</p>
                    <div className="earnings_calculation_n">
                        <p>{t.ROI.price2}</p>
                    </div>
                    {/* <div className="btn_cont_action_btns_pm">
                        <button className="action_btns_pm">{t.start_now}</button>
                    </div> */}
                </div>
                <div className="roi_blocks">
                    <p className="roi_block_title">Fixed <span className="specifics"><i className="fa-solid fa-infinity"></i></span></p>
                    <p className="roi_block_subtitle">{t.flatjourney_pm.fixed.subtitle}</p>
                    <p className="costs_container_tite">{t.ROI.services}</p>
                    <div className="earnings_calculation_n">
                        <p><i className="fa-solid fa-check"></i> {t.ROI.liquidity}</p>
                        <p><i className="fa-solid fa-check"></i> {t.ROI.software}</p>
                    </div>
                    <p className="costs_container_tite">{t.ROI.requisites}</p>
                    <div className="earnings_calculation_n">
                        <p>{t.ROI.req2}</p>
                    </div>
                    <p className="costs_container_tite">{t.ROI.price}</p>
                    <div className="earnings_calculation_n">
                        <p>{t.ROI.price3}</p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default ROIPM