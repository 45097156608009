import React from "react";

const FourthSlide = () => {
    return (
        <div>
            <p className="content_italic">"Paypermate semplifica il settore immobiliare, permettendoti di concentrarti sulla crescita del tuo business.</p>
            <p className="content_slide">Paypermate si prenderà cura di tutto il resto.</p>
            <p className="slide_points">Vuoi mettere in afitto casa e avere ritorni stabili? ci pensiamo noi.</p>
            <p className="slide_points">Vuoi affittare accedere a un'immobile più velocemente? Ci pensiamo noi.</p>
            <p className="slide_points">Vuoi validare la serietà degli inquilini? Ci pensiamo noi.</p>
            <p className="slide_points">Vuoi gestire i pagamenti e le transazioni e tenere traccia di tutto? Ci pensiamo noi.</p>
            <p className="slide_points">Vuoi gestire la tua agenzia immobiliare? Ci pensiamo noi.</p>
            <p className="slide_points">Vuoi migliorare le campagne marketing? Ci pensiamo noi.</p>
            <p className="slide_points">Vuoi avere dati concreti sul mercato immobiliare? Ci pensiamo noi.</p>
            <p className="slide_points">Vuoi utilizzare strumenti LLM e di ML per le operazioni immobiliari? Ci pensiamo noi.</p>
            <p className="slide_points">Hai una casa e non sai come utilizzarla al meglio? Ci pensiamo noi.</p>
        </div>
    )
}

export default FourthSlide