import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CompleteRegistration from "../CompleteRegistration/CompleteRegistration";
import { Gauge, gaugeClasses } from "@mui/x-charts";
import { FaBuildingUser } from "react-icons/fa6";
import { BsBuildingFillCheck } from "react-icons/bs";
import TenantsTable from "../Tenants/TenantsTable";
import { useLanguage } from "../../../Translations/LenguageContext";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import SpinningGlobe from "./SpinningGlobe";
import LeafletMap from "./LeafletMap";
import { HiMiniPaintBrush } from "react-icons/hi2";
import SideHome from "./SideHome";

function LLHome({ userData, no_session }) {
    const navigate = useNavigate();
    const { t } = useLanguage();
    const [no_home_session, SetNoHomeSession] = useState(false);
    const [registrationStatus, setRegistrationStatus] = useState(3);
    const [userId, setUserId] = useState(sessionStorage.getItem("user_id"));
    const [tenants, setTenants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [showSideMenu, setShowSideMenu] = useState(false); // New state for toggling SideHome

    const position = [45.464664, 9.188540];

    useEffect(() => {
        let isMounted = true;

        const fetchProgress = async () => {
            const user_id = sessionStorage.getItem("user_id");
            if (!user_id) {
                SetNoHomeSession(true);
                setTimeout(() => {
                    navigate(`/login`);
                }, 3000);
                return;
            }

            try {
                const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/check_registration", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (data.completed_steps !== undefined && isMounted) {
                    setRegistrationStatus(data.completed_steps);
                    console.log(data.completed_steps)
                }
            } catch (error) {
                console.error("Error fetching registration progress:", error);
            }
        };

        fetchProgress();

        return () => {
            isMounted = false;
        };
    }, [navigate]);

    useEffect(() => {
        fetchTenantData();
    }, [userId]);

    const fetchTenantData = async () => {
        try {
            const response = await fetch(
                "https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_tenant_data",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id: userId }),
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.tenants) {
                setTenants(data.tenants);
                CheckMissingInfo(data.tenants);
            } else {
                throw new Error("No tenants found");
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const CheckMissingInfo = (tenantData) => {
        const hasMissingInfo = Object.entries(tenantData || tenants).some(
            ([tenantId, tenant]) => {
                return (
                    !tenant.personal_information ||
                    !tenant.tenant_property_information ||
                    !tenant.tenant_document_information
                );
            }
        );

        setIsAlertVisible(hasMissingInfo);
        setShowAlert(hasMissingInfo);
    };

    const handleDropdownSelect = (action, tenantId) => {
        switch (action) {
            case "update":
                navigate(`/control_panel/tenants/update-tenant/${tenantId}`);
                break;
            case "delete":
                DeleteSelectedTenant(tenantId);
                break;
            case "complete":
                navigate(`/control_panel/tenants/update-tenant/${tenantId}`);
                break;
            default:
                break;
        }
    };

    const DeleteSelectedTenant = async (tenant_id) => {
        try {
            const response = await fetch(
                "https://boiling-mountain-85041-33258735eb3b.herokuapp.com/delete_tenant",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id: userId, tenant_id: tenant_id }),
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.message === "OK") {
                fetchTenantData(); // Refetch tenants after deletion
            } else {
                throw new Error("No tenants found");
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getPaymentStatus = (tenant) => {
        if (!tenant.personal_information ||
            !tenant.tenant_property_information ||
            !tenant.tenant_document_information) {
            return (
                <div className="warning_table_cont">
                    <i className="fa-solid fa-warning"></i>
                    <p>Dati non presenti</p>
                </div>
            );
        }

        const { paymentType, personalizedDetail } = tenant.tenant_property_information;

        if (paymentType === "monthly") {
            return "Mensile lungo periodo";
        } else if (paymentType === "personalized") {
            if (personalizedDetail && personalizedDetail.selectedStartDate && personalizedDetail.selectedEndDate) {
                const currentDate = new Date();
                const startDate = new Date(personalizedDetail.selectedStartDate);
                const endDate = new Date(personalizedDetail.selectedEndDate);

                const oneDayAfterEndDate = new Date(endDate);
                oneDayAfterEndDate.setDate(endDate.getDate() + 1);

                if (currentDate >= startDate && currentDate < oneDayAfterEndDate) {
                    const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
                    return `${remainingDays} giorn${remainingDays > 1 ? "i" : "o"} rimast${remainingDays > 1 ? "i" : "o"}`;
                } else if (endDate < currentDate) {
                    return "Done";
                } else if (startDate > currentDate) {
                    const remainingDays = Math.ceil((currentDate - startDate) / (1000 * 60 * 60 * 24));
                    return `${remainingDays} giorni al check-in`;
                }
            }
        }

        return "Dati non presenti";
    };

    if (no_session || no_home_session) {
        return (
            <div className="main_view_section">
                <div className="no_session_cont">
                    <p className="no_session_mex">La tua sessione è scaduta... stai per essere reindirizzato nella login</p>
                    <div className='no_data_img'></div>
                </div>
            </div>
        );
    }

    const settings = {
        width: 200,
        height: 200,
        value: 60,
    };

    return (
        <div className="home_main_view">

            <div className="home_main_cont">
                {registrationStatus !== 3 && <CompleteRegistration />}
                <div className="greetings_home_container">
                    <p className="dash_date">Giovedì, 12 dicembre 2024</p>
                    {
                        userData && (
                            <p className="title_main_view">Bentornato, {userData.name}!</p>
                        )
                    }
                    <div className="activity_bar">
                            <div className="activity_bar_dash">
                            </div>
                            <div className="activity_bar_dash">
                            </div>
                            <div className="activity_bar_dash">
                            </div>
                    </div>
                    {/* <button
                        className="change_dashboard_widget_btn"
                        onClick={() => setShowSideMenu(!showSideMenu)} // Toggle SideHome visibility
                    >
                        <HiMiniPaintBrush />
                    </button> */}
                </div>
                <div className="home_container">
                    <div className="double_cont">
                        <div className="card_dash">

                        </div>
                        <div className="card_dash">

                        </div>
                    </div>
                    <div className="double_cont">
                        <div className="card_dash">

                        </div>
                        <div className="card_dash">

                        </div>
                    </div>
                </div>
            </div>
            {showSideMenu && <SideHome showSideMenu={showSideMenu} toggleSideMenu={() => setShowSideMenu(!showSideMenu)} />}
        </div>
    );
}

export default LLHome;
