import React from "react";

const AgencyTeams = () => {
    return(
        <div className="main_view_section">
            <p className="title_main_view">Teams</p>

        </div>
    )
}

export default AgencyTeams