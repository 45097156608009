import React, { useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { useNavigate } from "react-router-dom";
import { PiSparkleFill } from "react-icons/pi";
import { useLocation } from "react-router-dom";

import { BiCalendar } from "react-icons/bi";
import { TbLayoutDashboard } from "react-icons/tb";
import { BsHouses, BsPeople, BsFolder, BsShopWindow } from "react-icons/bs";
import { TbUserScan } from "react-icons/tb";
import { LiaToolsSolid } from "react-icons/lia";
import { LuBarChart3 } from "react-icons/lu";
import { IoIosCloudOutline } from "react-icons/io";
import { HiOutlineChartPie } from "react-icons/hi2";
import { CgWebsite } from "react-icons/cg";
import { MdMarkEmailRead, MdBackup } from "react-icons/md";
import PaymentFormStripe from "../PaymentForm/PaymentFormStripe";
import { resolveMotionValue } from "framer-motion";

const RequestAdminUsers = ({ userData }) => {
    const { t } = useLanguage();
    const navigate = useNavigate();

    const [agencyName, setAgencyName] = useState("");
    const [description, setDescription] = useState("");
    const [userName, setUserName] = useState("");
    const [userSurname, setUserSurname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [prefix, setPrefix] = useState("+39");
    const [password, setPassword] = useState("");
    const [selectedType, setSelectedType] = useState(null);
    const [selectedPriceType, setSelectedPriceType] = useState(null);
    const [price, setPrice] = useState("")
    const [titlePlan, setTitlePlan] = useState("")
    const [admimCreator, setAdminCreator] = useState(userData._id)
    const [createdUser, setCreatedUser] = useState("")
    const [errors, setErrors] = useState({
        userName: false,
        userSurname: false,
        email: false,
        password: false,
    });
    const [generatePassword, setGeneratePassword] = useState(true);

    const [currentStep, setCurrentStep] = useState(0);

    const BackToPreviousPage = () => {
        navigate("/control_panel/users");
    };

    const handleCheckboxChange = () => {
        setGeneratePassword(!generatePassword);
    };

    const validatePassword = (password) => {
        const minLength = 8;
        const maxLength = 256;

        if (password.length < minLength || password.length > maxLength) {
            return false;
        }

        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        const charTypes = [hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar].filter(Boolean).length;

        return charTypes >= 3;
    };

    const handleNext = async () => {
        if (currentStep === 0) {
            const newErrors = {
                admimCreator: !admimCreator.trim(),
                userName: !userName.trim(),
                userSurname: !userSurname.trim(),
                email: !email.trim(),
                phone: !phone.trim(),
                prefix: !prefix.trim(),
                password: !generatePassword && !validatePassword(password),
            };

            setErrors(newErrors);

            if (Object.values(newErrors).some((error) => error)) {
                return;
            }

            const userData = {
                admimCreator,
                userName,
                userSurname,
                email,
                phone,
                prefix,
                password: generatePassword ? "" : password,
                passChange: document.querySelector('input[type="checkbox"]').checked,
            };

            try {
                const response = await fetch("http://127.0.0.1:5000/save_user_agency", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(userData),
                });

                const result = await response.json();

                if (response.ok) {
                    setCreatedUser(result.user_id)
                    setTimeout(() => {
                        setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
                    }, 1000);
                } else {
                    console.error("Error saving user:", result.message);
                }
            } catch (error) {
                console.error("Request failed:", error);
            }
        }

        if (currentStep === 1) {
            const licenseData = {
                createdUser: createdUser,
                type: selectedPriceType.split("_")[0],
            };

            try {
                const response = await fetch("http://127.0.0.1:5000/save_user_license", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(licenseData),
                });

                const result = await response.json();

                if (response.ok) {
                    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
                } else {
                    console.error("Error saving license:", result.message);
                }
            } catch (error) {
                console.error("Request failed:", error);
            }
        }
    };



    const handlePrev = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    };


    const handlePriceSelect = (priceType) => {
        setSelectedPriceType(priceType);
    };

    const formatAmount = (amount) => {
        const amountStr = amount.toString();
        const paddedAmount = amountStr.padStart(3, '0');
        return `${paddedAmount.slice(0, -2)},${paddedAmount.slice(-2)}`;
    };

    const steps = [
        (
            <div className="main_new_element">
                <p className="main_new_element_title">{t.agency.new_user}</p>
                <p className="main_new_element_desc">{t.agency.description}</p>
                <div className="main_new_element_input_desc_cont">
                   
                </div>
            </div>
        )
    ];

    return (
        <div className="main_view_section">
            <p className="title_main_view">
                <i
                    onClick={BackToPreviousPage}
                    className="fa-solid fa-chevron-left back-btn_chevron"
                ></i>
                {t.agency.new_user}
            </p>
            <div className="new_agency_main_cont">
                <div className="new_agency_main_container">
                    <div className="steps_new_element">
                        {steps.map((_, index) => (
                            <React.Fragment key={index}>
                                <div className="step_cont_element">
                                    <i
                                        className={currentStep === index
                                            ? "fa-regular fa-circle-check active-step"
                                            : "fa-regular fa-circle"}
                                    ></i>
                                    <p>{[t.agency.base_info][index]}</p>
                                </div>
                                {index < steps.length - 1 && <div className="separator_steps_cont"></div>}
                            </React.Fragment>
                        ))}
                    </div>
                    {steps[currentStep]}
                </div>
                <div className="next_element_cont">
                    <button className="next_element_cont_btn" onClick={BackToPreviousPage} >
                        <i className="fa-solid fa-chevron-left"></i> {t.agency.prev}
                    </button>
                    <button
                        className="next_element_cont_btn"
                        onClick={handleNext}
                        disabled={currentStep === steps.length - 1}
                    >
                        {t.agency.next} <i className="fa-solid fa-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RequestAdminUsers;
