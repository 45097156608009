import React from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../Translations/LenguageContext";

const JourneyNavbar = ({page_to_navigate}) => {
    const {t} = useLanguage()
    const navigate = useNavigate()

    const navigatePage = () => {
        navigate(page_to_navigate)
    }

    return (
        <div className="jour_navbar">
            <i className="fa-solid fa-layer-group" onClick={()=> navigatePage("/PM")}></i>
            <button className="login_btn" onClick={navigatePage}>
                {page_to_navigate == "/PM" ?(
                    <p>Home</p>
                ): (
                    <p>
                        {t.pm_steps.back}
                    </p>
                )}
            </button>
        </div>
    )
}

export default JourneyNavbar